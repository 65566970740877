import {observable} from "mobx";
import {TerritoryBattleDefinition, ConflictZoneDefinition} from "./TerritoryBattleGameData";
import {IGenericTemplate} from "../components/Cards/Cards";

export enum TBLocationEnum {
	TOP,
	MIDDLE,
	BOTTOM
}

export class TBPlatoonUnitStatus {
	unitBaseId: string;
	filledPlayerId: string | null;

	constructor(unitBaseId: string, filledPlayerId: string | null) {
		this.unitBaseId = unitBaseId;
		this.filledPlayerId = filledPlayerId;
	}
}

export class TBPlatoonZoneStats {
	platoonUnitStatus: TBPlatoonUnitStatus[] = [];
}

export class TerritoryBattlePlatoonStatus {
	phase: number;
	location: TBLocationEnum;
	zoneStatus: Map<number, TBPlatoonZoneStats> = new Map();

	constructor(phase: number, location: TBLocationEnum) {
		this.phase = phase;
		this.location = location;
	}
}

export interface ITBPlatoonTemplateZoneUnit {
	id: number;
	characterId: string;
	characterName: string;
	player?: string;
	allyCode?: number;
	discordId?: string;
	isFilled?: boolean;
	requiredRelicLevel?: number;
	requiredStars?: number;
}

export interface ITBPlatoonFilterPlayerUnits {
	maxGP: number;
	minStars: number;
	maxGearLevel: number;
	maxRelicLevel: number;
}

export interface ITBPlatoonTemplateZone {
	id: number;
	units: ITBPlatoonTemplateZoneUnit[];
}

export interface ITBPlatoonTemplate extends IGenericTemplate {
	createdBy: string;
	editedBy: string;
	finishPlatoonsMaxHour: number;
	showSpecialCharacters: boolean;
	isTopSquadPlatoon: boolean;
	isTopPlatoons: boolean;
	isMiddlePlatoons: boolean;
	isBottomPlatoons: boolean;
	isSortingByCharacterName: boolean;
	isActive: boolean;
	filters: ITBPlatoonFilterPlayerUnits;
	isTopCollapsed: boolean;
	isMiddleCollapsed: boolean;
	isBottomCollapsed: boolean;
	zones: {
		top: ITBPlatoonTemplateZone[];
		middle: ITBPlatoonTemplateZone[];
		bottom: ITBPlatoonTemplateZone[];
	};
}


export class TerritoryBattleHistoryPhaseInstance {
	phase: number;
	updated: Date | null;

	constructor(json: any) {
		this.phase = json.phase;
		this.updated = json.updated === null ? null : new Date(json.updated);
	}
}

export class TerritoryBattleHistoryInstance {
	instanceId: string;
	dataCollected: boolean;
	date: Date;
	phase: TerritoryBattleHistoryPhaseInstance[] = [];

	constructor(json: any) {
		this.instanceId = json.instanceId;
		this.dataCollected = json.dataCollected;
		this.date = new Date(json.date);
		if (json.phase) {
			this.phase = json.phase.map((p: any) => new TerritoryBattleHistoryPhaseInstance(p));
		}
	}
}

export interface TBZoneCommands {
	zoneId: string;
	message: string;
	commandState: number;
	specialText: string;
	phase?: number;
	location?: number;
}

export type TZoneType = 'zone' | 'platoon' | 'special_mission' | 'combat_mission';

export interface TBSavedMessages {
	id: string;
	zoneId: string;
	message: string;
	phase?: number;
}

export class TBAllZones {
	zoneType: TZoneType;
	data: TBReconZoneStatus[];

	constructor(zoneType: TZoneType, data: TBReconZoneStatus[]) {
		this.zoneType = zoneType;
		this.data = data;
	}
}

export class TBReconZoneStatus {
	phase: number;
	location: TBLocationEnum;
	commandMessage: string;
	commandState: string;
	zoneId: string;
	specialText: string;
	zoneStatus: Map<number, TBPlatoonZoneStats> = new Map();

	constructor(zoneId: string, phase: number, location: TBLocationEnum, specialText: string, commandMessage: string, commandState: string) {
		this.zoneId = zoneId;
		this.phase = phase;
		this.location = location;
		this.specialText = specialText;
		this.commandMessage = commandMessage;
		this.commandState = commandState;
	}
}

export class TerritoryBattleZoneStats {
	@observable channelId: string;
	@observable commandMessage: string;
	@observable commandState: string;
	@observable score: number;
	@observable phase: number;
	@observable specialText: string;
	@observable location: number;
	@observable zoneId: string;
	@observable zoneState: string;

	isComplete(): boolean {
		// const openZone
		return this.zoneState === "Zonecompleted"
	}

	constructor(json: any) {
		this.channelId = json.channelId;
		this.commandMessage = json.commandMessage;
		this.commandState = json.commandState;
		this.score = json.score;
		this.phase = json.phase;
		this.specialText = json.specialText;
		this.location = json.location;
		this.zoneId = json.zoneId;
		this.zoneState = json.zoneState;
	}
}

export class TerritoryBattleZoneStatus {
	@observable status: TerritoryBattleZoneStats;
	@observable playersParticipated: number | null = null;

	constructor(json: any) {
		if (json.playersParticipated !== undefined) {
			this.playersParticipated = json.playersParticipated;
		}
		this.status = new TerritoryBattleZoneStats(json.status);
	}

	getStarCompletedCount(tbBattleDefintion: TerritoryBattleDefinition): number {
		let retVal: number = 0;
		let zoneDefinition: ConflictZoneDefinition = tbBattleDefintion.getZoneGameDefinition(this.status.zoneId);
		for (let x = 0; x < zoneDefinition.victoryPointRewards.length; x++) {
			if (this.status.score >= zoneDefinition.victoryPointRewards[x].galacticScoreRequirement) {
				retVal = x + 1;
			}
		}
		return retVal;
	}

	public getCommandMessage(): string {
		const message = this.status.commandMessage;
		return message;
	}

	public getCommandState(): string {
		const message = this.status.commandState;
		return message;
	}

	public getSpecialText(): string {
		const message = this.status.specialText;
		return message;
	}

	public getPhase(): number {
		const phase = this.status.phase;
		return phase;
	}

	public getZoneId(): string {
		const zoneId = this.status.zoneId;
		return zoneId;
	}

	public getLocation(): number {
		const location = this.status.location;
		return location;
	}

	getZoneCompleted(tbBattleDefintion: TerritoryBattleDefinition): boolean {
		let retVal: boolean = true;
		let zoneDefinition: ConflictZoneDefinition = tbBattleDefintion.getZoneGameDefinition(this.status.zoneId);
		for (let x = 0; x < zoneDefinition.victoryPointRewards.length; x++) {
			if (this.status.score < zoneDefinition.victoryPointRewards[x].galacticScoreRequirement) {
				retVal = false;
			}
		}
		return retVal;
	}
}


export class PlatoonSquadUnitStatus {
	allyCode: number | null;
	playerName: string | null;
	level: number | null;
	tier: number | null;
	unitIdentifier: string;
	relicLevel: number | null;
	unitPower: number | null;
	unitCombatType: number;

	constructor(json: any) {

		this.allyCode = json.allyCode;
		this.playerName = json.playerName;
		this.level = json.level;
		this.tier = json.tier;
		this.unitIdentifier = json.unitIdentifier;
		this.relicLevel = json.relicLevel;
		this.unitPower = json.unitPower;
		this.unitCombatType = json.unitCombatType;
	}

	public getUnitBaseId() {
		let unitParts = this.unitIdentifier.split(":");
		return unitParts[0];
	}

	public isPlatoonFilled(): boolean {
		return this.allyCode !== null && this.allyCode !== 0;
	}
}

export class PlatoonSquadStatus {
	@observable id: string;
	@observable unit: PlatoonSquadUnitStatus[] = [];

	constructor(json: any) {

		this.id = json.id;
		if (json.unit != null) {
			json.unit.forEach((unit: any) => this.unit.push(new PlatoonSquadUnitStatus(unit)));
		}
	}

	isSquadFilled(): boolean {
		return this.unit.find(u => u.isPlatoonFilled() === false) === undefined;
	}
}

export class PlatoonStatus {

	@observable id: string;
	@observable squad: PlatoonSquadStatus[] = [];

	constructor(json: any) {

		this.id = json.id;
		if (json.squad != null) {
			json.squad.forEach((squad: any) => this.squad.push(new PlatoonSquadStatus(squad)));
		}
	}

	isPlatoonFilled(): boolean {
		return this.squad.find(s => s.isSquadFilled() === false) === undefined;
	}

	public getPlatoonIndex() {
		let indexParts = this.id.split("-");
		let retVal = Number(indexParts[2]);
		if (isNaN(retVal)) {
			throw Error("Unable to part platoon index from: " + this.id);
		}
		return retVal;
	}
}

export class ReconZoneStatus {

	@observable status: TerritoryBattleZoneStats;
	@observable platoon: PlatoonStatus[] = [];
	@observable isTbHoth: boolean = false;

	constructor(json: any) {

		this.status = new TerritoryBattleZoneStats(json.status);
		this.isTbHoth = this.status.channelId.includes("HOTH");
		if (json.platoon != null) {
			json.platoon.forEach((platoon: any) => this.platoon.push(new PlatoonStatus(platoon)));
		}
	}

	public getPlatoonStatus(platoonId: string): PlatoonStatus {
		let platoonStatus = this.platoon.find(p => p.id === platoonId);
		if (platoonStatus !== undefined) {
			return platoonStatus;
		}
		throw new Error("Unable to find platoon status: " + platoonId);
	}

	public getPhase(): number {
		let m = this.status.zoneId.match(/phase0(\d)/);
		if (m) {
			return parseInt(m[1]);
		} else {
			throw Error("Unable to parse phase from recon zone status: " + this.status.zoneId);
		}
	}

	public getZoneId(): string {
		const zoneId = this.status.zoneId;
		return zoneId;
	}

	public getPhaseNo(): number {
		const phase = this.status.phase;
		return phase;
	}

	public getLocationNo(): number {
		const location = this.status.location;
		return location;
	}

	public getSpecialText(): string {
		const message = this.status.specialText;
		return message;
	}

	public getCommandMessage(): string {
		const message = this.status.commandMessage;
		return message;
	}

	public getCommandState(): string {
		const message = this.status.commandState;
		return message;
	}

	public getConflict() {
		let m = this.status.zoneId.match(/conflict0(\d)/);
		if (m) {
			return parseInt(m[1]);
		} else {
			throw Error("Unable to parse conflict number from recon zone status: " + this.status.zoneId);
		}
	}

	public getLocation(tbData: TerritoryBattleData): TBLocationEnum {

		let conflictNumber = this.getConflict();
		let phase = this.getPhase();
		let phaseNameParts = this.status.zoneId.split("_");
		let phaseZoneCount = tbData.reconZoneStatus.filter(rzs => {
			let rzsParts = rzs.status.zoneId.split("_");
			return rzsParts[0] === phaseNameParts[0] && rzsParts[1] === phaseNameParts[1] && rzsParts[2] === phaseNameParts[2];
		}).length;
		if (conflictNumber === 1) {
			if (this.isTbHoth) {
				if (phase === 1) {
					return TBLocationEnum.MIDDLE;
				}
				if (phase === 2) {
					return TBLocationEnum.TOP;
				}
			}
			if (phaseZoneCount === 2) {
				return TBLocationEnum.MIDDLE;
			} else {
				return TBLocationEnum.TOP;
			}
		} else if (conflictNumber === 2) {
			if (this.isTbHoth && phase === 2) {
				return TBLocationEnum.BOTTOM;
			}
			if (phaseZoneCount === 2 && phase === 1) {
				return TBLocationEnum.BOTTOM;
			} else {
				return TBLocationEnum.MIDDLE;
			}
		} else {
			return TBLocationEnum.BOTTOM;
		}
	}
}

export class TerritoryBattlePlayer {
	name: string;
	allyCode: number;
	characterGP: number;
	shipGP: number;

	constructor(json: any) {
		this.name = json.name;
		this.allyCode = json.allyCode;
		this.characterGP = json.characterGP;
		this.shipGP = json.shipGP;
	}
}

export class LeaderBoardPlayer {

	allyCode: number;
	name: string;
	score: number;

	constructor(json: any) {
		this.allyCode = json.allyCode;
		this.name = json.name;
		this.score = json.score;
	}
}

export class LeaderBoard {
	static TERRITORY_POINTS_LB = "summary";
	static PLATOONS_UNITS_LB = "unit_donated";
	static COMBAT_WAVES_COMPLETED_LB = "strike_encounter";
	static ROUGE_ACTIONS_LB = "disobey";

	static TERRITORY_POINTS_ROUND_LB = "summary_round_";
	static GP_DEPLOYED_ROUND_LB = "power_round_";
	static COMBAT_MISSIONS_ROUND_LB = "strike_attempt_round_";
	static SPECIAL_MISSIONS_ROUND_LB = "covert_attempt_round_";

	statId: string;
	players: LeaderBoardPlayer[] = [];

	constructor(json: any) {
		this.statId = json.statId;
		if (json.players) {
			json.players.forEach((player: any) => this.players.push(new LeaderBoardPlayer(player)));
		}
	}

	public getPlayerLeaderBoardScore(allyCode: number): number {
		let playerLb = this.players.find(player => player.allyCode === allyCode);
		if (playerLb !== undefined) {
			return playerLb.score;
		}
		return 0;
	}
}

export class TBMissionTracker {
	public static SETTING_KEY = "tb_mission_tracking";

	maxPointsRemaining: number;
	currentMissionPointValue: number;

	constructor(currentMissionPointValue: number, maxPointsRemaining: number) {
		this.maxPointsRemaining = maxPointsRemaining;
		this.currentMissionPointValue = currentMissionPointValue;
	}
}

class TerritoryBattleData {
	static ZONE_OPEN: string = "Zoneopen";

	@observable channelId: string = "";
	@observable currentRound: number | null = null;
	@observable currentRoundEndTime: number | null = null; // TODO - see if this is a date or something
	@observable definitionId: string = "";
	@observable instanceId: string = "";
	@observable updateTime: string = "";

	@observable dataCollected: boolean = false;

	@observable conflictZoneStatus: TerritoryBattleZoneStatus[] = [];
	@observable convertZoneStatus: TerritoryBattleZoneStatus[] = [];
	@observable reconZoneStatus: ReconZoneStatus[] = [];
	@observable strikeZoneStatus: TerritoryBattleZoneStatus[] = [];
	@observable leaderboard: LeaderBoard[] = [];

	@observable players: TerritoryBattlePlayer[] = [];

	public static fromJSON(json: any): TerritoryBattleData {
		let ret = new TerritoryBattleData();

		ret.channelId = json.data.channelId;
		ret.currentRound = json.data.currentRound;
		ret.currentRoundEndTime = json.data.currentRoundEndTime;
		ret.definitionId = json.data.definitionId;
		ret.instanceId = json.data.instanceId;
		ret.updateTime = json.data.ageUtc;
		ret.dataCollected = json.data.dataCollected;

		if (json.data.conflictZoneStatus != null) {
			json.data.conflictZoneStatus.forEach((czs: any) => ret.conflictZoneStatus.push(new TerritoryBattleZoneStatus(czs)))
		}

		if (json.data.convertZoneStatus != null) {
			json.data.convertZoneStatus.forEach((czs: any) => ret.convertZoneStatus.push(new TerritoryBattleZoneStatus(czs)))
		}

		if (json.data.reconZoneStatus != null) {
			json.data.reconZoneStatus.forEach((czs: any) => ret.reconZoneStatus.push(new ReconZoneStatus(czs)))
		}

		if (json.data.strikeZoneStatus != null) {
			json.data.strikeZoneStatus.forEach((czs: any) => ret.strikeZoneStatus.push(new TerritoryBattleZoneStatus(czs)))
		}

		if (json.data.players != null) {

			json.data.players.forEach((czs: any) => {
				// fix, server sometimes returning multiple players
				let existingPlayer = ret.players.find(p => p.allyCode === czs.allyCode);
				if (existingPlayer === undefined) {
					ret.players.push(new TerritoryBattlePlayer(czs));
				} else {
					console.error("Found multiple instances of player");
					console.log(existingPlayer);
					console.log(czs);
				}
			})
		}
		if (json.data.leaderboard) {
			json.data.leaderboard.forEach((leaderboard: any) => ret.leaderboard.push(new LeaderBoard(leaderboard)))
		}

		return ret;
	}

	getOpenZones(): TerritoryBattleZoneStatus[] {
		return this.conflictZoneStatus.filter(conflictZone => conflictZone.status.zoneState === TerritoryBattleData.ZONE_OPEN);
	}

	getOpenShipZone(tbBattleDefintion: TerritoryBattleDefinition): TerritoryBattleZoneStatus | undefined {
		let openZones: TerritoryBattleZoneStatus[] = this.getOpenZones();
		return openZones.find(tbz => {
			let zoneDefinition: ConflictZoneDefinition = tbBattleDefintion.getZoneGameDefinition(tbz.status.zoneId);
			return zoneDefinition.combatType === TerritoryBattleDefinition.SHIP_COMBAT_TYPE;
		});
	}

	getOpenMissionZones(tbBattleDefintion: TerritoryBattleDefinition): TerritoryBattleZoneStatus[] {
		let openZones: TerritoryBattleZoneStatus[] = this.getOpenZones();
		return openZones.filter(tbz => {
			let zoneDefinition: ConflictZoneDefinition = tbBattleDefintion.getZoneGameDefinition(tbz.status.zoneId);
			return zoneDefinition.combatType !== TerritoryBattleDefinition.SHIP_COMBAT_TYPE;
		})!;
	}

	public getReconZoneStatus(reconZoneId: string): ReconZoneStatus {

		let tbzs = this.reconZoneStatus.find(rz => rz.status.zoneId === reconZoneId);
		if (tbzs !== undefined) {
			return tbzs;
		}
		throw new Error("Unable to find territory battle zone status: " + reconZoneId);
	}

	public getLeaderBoardValue(boardName: string, allyCode: number): number {
		let retVal: number = 0;
		let leaderBoard = this.leaderboard.find(leaderBoard => leaderBoard.statId === boardName);
		retVal = leaderBoard === undefined ? 0 : leaderBoard.getPlayerLeaderBoardScore(allyCode);
		return retVal;
	}

	public getLeaderBoardTotalValue(boardName: string): number {
		let retVal: number = 0;
		let leaderBoard = this.leaderboard.find(leaderBoard => leaderBoard.statId === boardName);
		if (leaderBoard !== undefined) {
			leaderBoard.players.forEach(p => {
				retVal = retVal + p.score;
			});
		}
		return retVal;
	}


	public getStrikeZoneStatus(strikeZoneId: string): TerritoryBattleZoneStatus {
		let tbzs = this.strikeZoneStatus.find(szs => szs.status.zoneId === strikeZoneId);
		if (tbzs !== undefined) {
			return tbzs;
		}
		throw new Error("Unable to find territory battle zone status: " + strikeZoneId);
	}

	getPlatoonScore(zoneId: string, tbDef: TerritoryBattleDefinition): number {
		let retVal = 0;
		const zoneDef = tbDef.getReconZone(zoneId);
		if (zoneDef) {
			const reconStatus = this.getReconZoneStatus(zoneDef.zoneDefinition.zoneId);
			zoneDef.platoonDefinition.filter(pd =>
				reconStatus.getPlatoonStatus(pd.id).isPlatoonFilled()).forEach(pd => {
				retVal = retVal + pd.reward.value;
			});
		}
		return retVal;
	}

}


export default TerritoryBattleData;
