import { observable, IObservableArray, action, runInAction } from "mobx";
import PlayerData from "./PlayerData";
import PatreonLevel from "./PatreonLevel";
import ModFilterGroup from "./ModFilterGroup";
import moment, { Moment } from "moment";
import BaseAPI from "../service/BaseAPI";

class UserData
{
    @observable players: PlayerData[] | null = null;
    @observable patreonLevel: PatreonLevel = PatreonLevel.None;
    @observable guildPatreonLevel: PatreonLevel = PatreonLevel.None;
    @observable apiRights: string[] | null = null;
    @observable grandIvoryEmbeddedURL: string | null = null;
    @observable grandIvoryExternalURL: string | null = null;
    @observable sessionId: string | null = null;
    @observable allyCodes: string[] | null = null;
    @observable currentPlayer: PlayerData | null = null;
    @observable modFilterGroups: ModFilterGroup[] | null = null;
    @observable likedPublicModFilters: IObservableArray<string> = observable([]);
    @observable isSupportStaff: boolean = false;
    @observable darkModeEnabled: boolean = false;
    @observable otherPlayers: PlayerData[] = []; // players not owned by character (GAC opponents, etc)
    @observable queueBusy: boolean = false;
    @observable queueCheckTime: Moment | null = null;


    public async checkQueue(): Promise<boolean>
    {
        if (this.queueCheckTime === null || this.queueCheckTime.diff(moment(), "minutes") < -30)
        {
            let queueStats = await BaseAPI.getCompareQueueStats();
            runInAction(() => this.queueBusy = queueStats.queueUnClaimed > 5);
        }
        return this.queueBusy;
    }

    public static fromJSON(json: any): UserData
    {
        let ret = new UserData();
        ret.players = json.players.map((p: any) =>
        {
            let ret = new PlayerData();
            ret.unitsFromJSON(p);
            ret.modsFromJSON(p);
            return ret;
        });
        return ret;
    }

    @action
    public fromSubscriptionJSON(json: any): void
    {
        if (json.subscription && json.subscription.playerLevel)
        {
            this.patreonLevel = json.subscription.playerLevel.level;
        }
        if (json.subscription && json.subscription.guildLevel)
        {
            this.guildPatreonLevel = json.subscription.guildLevel.level;
        }
        if (json.subscription && json.subscription.apiRights)
        {
            this.apiRights = json.subscription.apiRights
        }
        if (json.subscription && json.subscription.integrations)
        {
            this.grandIvoryEmbeddedURL = json.subscription.integrations.grandIvoryInternal;
            this.grandIvoryExternalURL = json.subscription.integrations.grandIvoryExternal;
        }
    }

    public get hasGameConnection(): boolean
    {
        return this.currentPlayer != null && this.currentPlayer.hasConnection;
    }


    public get isContentCreator(): boolean
    {
        return this.currentPlayer === null || this.currentPlayer.contentCreator;
    }

    public get readOnly(): boolean
    {
        return this.currentPlayer === null || this.currentPlayer.readOnly;
    }

    public get shadow(): boolean
    {
        return this.currentPlayer === null || this.currentPlayer.shadow;
    }

    @action
    logout(): void
    {
        this.players = null;
        this.patreonLevel = PatreonLevel.None;
        this.sessionId = null;
        this.allyCodes = null;
        this.currentPlayer = null;
        this.modFilterGroups = null;
        this.likedPublicModFilters.clear();
    }

    public get isPatronOrInWarToolsGuild(): boolean
    {
        return this.patreonLevel !== PatreonLevel.None || this.guildPatreonLevel !== PatreonLevel.WarTools;
    }

    public get patreonLevelString(): string
    {
        switch (this.patreonLevel)
        {
            case PatreonLevel.None:
                return "Not a Patreon Subscriber";
            case PatreonLevel.Cherry:
                return "Cherry";
            case PatreonLevel.Chile:
                return "Chile";
            case PatreonLevel.Jalapeno:
                return "Jalapeno";
            case PatreonLevel.Habanero:
                return "Habanero";
            default:
                return "Unknown";
        }
    }

    public get isGuildLeader()
    {
        return this.apiRights?.includes("GUILD_LEADER") === true;
    }
    public get isGuildOfficer()
    {
        return this.apiRights?.includes("GUILD_OFFICER") === true || this.apiRights?.includes("GUILD_LEADER") === true;
    }
    public get isAdmin()
    {
        return this.apiRights?.includes("ADMIN") === true;
    }
    public get isAdminTW()
    {
        return this.apiRights?.includes("ADMIN") === true || this.apiRights?.includes("ADMIN_TW") === true;
    }
    public get isAdminTB()
    {
        return this.apiRights?.includes("ADMIN") === true || this.apiRights?.includes("ADMIN_TB") === true;
    }

    public checkApiRights(requiredApiRights: string[] | undefined): boolean
    {
        if (!requiredApiRights)
            return true;
        if (!this.apiRights)
            return false;

        for (let r of requiredApiRights)
        {
            if (!this.apiRights.includes(r))
                return false;
        }
        return true;
    }
}

export default UserData;
