export function getAPIkey() {
    if (window.location.host === "development.hotutils.com" || window.location.host === "staging.hotutils.com")
    {
        return "5e8cc074-6145-4e9a-99b3-7c355fb636a3";
    }
    if (window.location.host === "hotutils.com")
    {
        return "898a36a3-948a-4a8a-9798-7a1552b042a8";
    }
    if (process.env.NODE_ENV === "development") {
        return process.env.REACT_APP_API_KEY
    }
    if (process.env.PULL_REQUEST === "true") {
        return process.env.REACT_APP_API_KEY_DEV
    }
    if (process.env.BRANCH === "development") {
        return process.env.REACT_APP_API_KEY_DEV
    }
    if (process.env.BRANCH === "staging") {
        return process.env.REACT_APP_API_KEY_STAGE
    }
    if (process.env.BRANCH === "master") {
        return process.env.REACT_APP_API_KEY_PROD
    }
}

export function getAPIenv(){
    if (window.location.host === "hotutils.com") {
        return 'https://api.hotutils.com/Production/'
    } else if (window.location.host === "development.hotutils.com" || window.location.host.startsWith("localhost")) {
        return 'https://api.hotutils.com/Development/'
    } else {
        return 'https://api.hotutils.com/Development/'
    }
}