import React from "react";
import { Button, Checkbox, Popconfirm } from "antd";
import BlankLayout from "../../layouts/BlankLayout";
import { ButtonColumn, ButtonRow } from '../../ui/buttonRow';
import i18next from "i18next";
import { GoogleButton } from '../../ui/blockButton';
import { Cookies } from "react-cookie";
import UserData from "../../model/UserData";
import styles from "./styles/Login.module.scss";

function generateLink()
{
    return "https://discord.com/api/oauth2/authorize?client_id=470635832462540800&redirect_uri=" +
        encodeURIComponent(window.location.origin + "/callback") + "&response_type=code&scope=identify&prompt=none&state=12345";
}

export function generateGoogleLink(): string
{
    return "https://accounts.google.com/o/oauth2/auth?scope=https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.profile&client_id=147895889697-2f2h8hld8srvc09k690tdg1jv1c44ek2.apps.googleusercontent.com&response_type=code&access_type=offline&redirect_uri=" +
        encodeURIComponent(window.location.origin + "/googlecallback");
}


interface LoginPageProps
{
    cookies: Cookies;
    user: UserData;
}

const getRememberMeStatus = (localStorage: string | null) =>
{
    return localStorage === "1";
}

function iOS(): boolean
{
    return [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

function isBadIOS()
{
    return iOS() && window.matchMedia("(orientation: portrait)").matches && false;
}

const LoginPage = (props: LoginPageProps) =>
{
    if (props.cookies.get("hotUtilsSession"))
        props.cookies.remove("hotUtilsSession");
    if (props.cookies.get("hotUtilsAllyCode"))
        props.cookies.remove("hotUtilsAllyCode");
    if (props.user.sessionId)
    {
        props.user.logout();
    }
    return (
        <BlankLayout>
            <div className={styles.container}>

                <div className={styles.top}>
                    <img className={styles.logo} src={"hotsauce-logo.png"} alt={""} />
                    <div className={styles.buttons}>
                        <ButtonColumn>
                            <div className={styles.headerWarning}>Web site features for Patreon subscribers only</div>
                            {isBadIOS() && <Popconfirm title={<div>
                                <div>Warning: Discord login broken on iOS</div>
                                <div>Either rotate your phone to landscape mode or use the $login bot command</div>
                                <div>Note that this is a Discord issue they refuse to fix</div>
                                <div>Please feel free to contact Discord support to complain</div>
                            </div>} onConfirm={() => { window.location.href = generateLink(); }}>
                                <Button type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_discord")}</Button>
                            </Popconfirm>}
                            {!isBadIOS() && <a href={String(generateLink())}>
                                <Button type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_discord")}</Button>
                            </a>}
                            <a href={generateGoogleLink()}>
                                <GoogleButton type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_google")}</GoogleButton>
                            </a>
                            <a href={"/eaconnect"}>
                                <GoogleButton type="primary" size="large"
                                    block>{i18next.t("common:login.login_with_eaconnect")}</GoogleButton>
                            </a>
                            <a href="https://www.patreon.com/hotutils/membership">
                                <Button type="link" size="large" className={styles.white} block>Become a Patron</Button>
                            </a>
                        </ButtonColumn>
                        <Checkbox
                            className={styles.rememberMe}
                            defaultChecked={getRememberMeStatus(localStorage.getItem("rememberMe"))}
                            onChange={(event) =>
                            {
                                localStorage.setItem("rememberMe", event.target.checked ? "1" : "0");
                            }}
                        >
                            Remember me
                        </Checkbox>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <ButtonRow>
                        <a href="https://discord.me/hotutils"> Discord Links</a> | <a
                            href="mailto:hotsauce@hotutils.com"> Site Admin</a> | <a href="/"> Privacy Policy</a>
                    </ButtonRow>
                    <ButtonRow>
                        <div>This website is not affiliated with or sponsored by Electronic Arts Inc. or its licensors.</div>
                    </ButtonRow>
                </div>
            </div>
        </BlankLayout>
    );
};

export default LoginPage;
