import { observable } from "mobx";
import StatIds from "./StatIds";
import ModSets, { getSetBonusCount, modSetToString } from './ModSets';
import ModSlots, { commonSlotIdToString } from "./ModSlots";
import { UnitData } from './UnitData';
import { UnitStats } from "../utils/mod-calculator";
import { statIdToString } from "./StatIds";

export enum LoadoutDefinitionFullSetEnum
{
    UseGlobalSetting = 0,
    NoBrokenSets = 1,
    AllowBrokenSets = 2
}

export enum LoadoutDefinitionStatIds
{
    Health = 1,
    Speed = 5,

    PhysicalDamage = 6,
    SpecialDamage = 7,

    UnitDefense = 8,
    UnitResistance = 9,

    UnitPhysicalCriticalChance = 14,
    UnitSpecialCriticalChance = 15,

    CriticalDamage = 16,
    Potency = 17,
    Tenacity = 18,

    Protection = 28,
    UnitPhysicalAccuracy = 37,
    CriticalAvoidance = 54,

    TotalHealth = -1,
    EffectiveHealth = -2,
    EffectiveTotalHealth = -3,
    ModRarity = -4
}

export function loadoutDefinitionStatIdToString(stat: LoadoutDefinitionStatIds, showPcts?: boolean): string 
{
    switch (stat)
    {
        case LoadoutDefinitionStatIds.Health:
            return "Health";
        case LoadoutDefinitionStatIds.UnitDefense:
            return "Armor";
        case LoadoutDefinitionStatIds.UnitResistance:
            return "Resistance";
        case LoadoutDefinitionStatIds.UnitPhysicalCriticalChance:
            return "Physical Critical Chance";
        case LoadoutDefinitionStatIds.UnitSpecialCriticalChance:
            return "Special Critical Chance";
        case LoadoutDefinitionStatIds.CriticalAvoidance:
            return "Critical Avoidance";
        case LoadoutDefinitionStatIds.UnitPhysicalAccuracy:
            return "Physical Accuracy";
        case LoadoutDefinitionStatIds.Speed:
            return "Speed";
        case LoadoutDefinitionStatIds.PhysicalDamage:
            return "Physical Damage";
        case LoadoutDefinitionStatIds.SpecialDamage:
            return "Special Damage";
        case LoadoutDefinitionStatIds.CriticalDamage:
            return "Crit Dmg";
        case LoadoutDefinitionStatIds.Potency:
            return "Potency";
        case LoadoutDefinitionStatIds.Tenacity:
            return "Tenacity";
        case LoadoutDefinitionStatIds.Protection:
            return "Protection";
        case LoadoutDefinitionStatIds.TotalHealth:
            return "Total Health";
        case LoadoutDefinitionStatIds.EffectiveHealth:
            return "Effective Health";
        case LoadoutDefinitionStatIds.EffectiveTotalHealth:
            return "Effective Total Health";
        case LoadoutDefinitionStatIds.ModRarity:
            return "Total Mod Rarity (5 or 6 per mod)";
    }
}

export enum LoadoutDefinitionMathOperator
{
    Add = 1,
    Multiple = 2,
    Subtract = 3,
    Divide = 4
}

function mathOperatorToString(mo: LoadoutDefinitionMathOperator): string
{

    switch (mo)
    {
        case LoadoutDefinitionMathOperator.Add:
            return "+";
        case LoadoutDefinitionMathOperator.Subtract:
            return "-";
        case LoadoutDefinitionMathOperator.Multiple:
            return "*";
        case LoadoutDefinitionMathOperator.Divide:
            return "/";
    }
}

export class LoadoutDefinitionMathExpression
{
    @observable operator: LoadoutDefinitionMathOperator;
    @observable operand: number;

    constructor(json: any)
    {
        this.operator = json.operator;
        this.operand = json.operand;
    }

    same(me: LoadoutDefinitionMathExpression): boolean
    {
        return this.operand === me.operand;
    }
}

export class OptimizationPlanStatWeight
{
    static HEALTH = 0.05;
    static PROTECTION = 0.025;
    static SPEED = 5;
    static CRIT_DAMAGE = 3.3333333333333335;
    static POTENCY = 6.666666666666667;
    static TENACITY = 6.666666666666667;
    static PHYSCIAL_DAMAGE = 0.3333333333333333;
    static SPECIAL_DAMAGE = 0.16666666666666666;
    static OFFENSE = 300;
    static CRIT_CHANCE = 10;
    static ARMOR = 1.5151515151515151;
    static RESISTANCE = 1.5151515151515151;
    static ACCURACY = 10;
    static CRIT_AVOID = 10;
    static EFFECTIVE_HEALTH = 0.025;
    static EFFECTIVE_HEALTH_PROTECTION = 0.0125;
};

export enum LoadoutDefinitionRoundTypeEnum
{
    RoundDown = 1,
    RoundUp = 2
}

export class LoadoutDefinitionStatTarget
{
    static TARGET_LIST = [StatIds.Health, StatIds.Protection, StatIds.Speed, StatIds.CriticalChance, StatIds.CriticalDamage, StatIds.Potency,
    StatIds.Tenacity, StatIds.PhysicalDamage, StatIds.SpecialDamage, StatIds.Defense, StatIds.Accuracy, StatIds.CriticalAvoidance];

    @observable uid: string;
    @observable stat: LoadoutDefinitionStatIds;
    @observable compareStat?: LoadoutDefinitionStatIds;
    @observable compareUnitBaseId?: string;
    @observable statModifier?: LoadoutDefinitionMathExpression[];

    @observable minValue?: number;
    @observable maxValue?: number;
    @observable compareModifier?: LoadoutDefinitionMathExpression[];
    @observable minRoundType?: LoadoutDefinitionRoundTypeEnum;
    @observable maxRoundType?: LoadoutDefinitionRoundTypeEnum;

    @observable notRequired: boolean = false;

    constructor(json: any)
    {
        this.uid = "id" + Math.random().toString(16).slice(2);
        this.stat = json.stat;
        this.compareStat = json.compareStat === null ? undefined : json.compareStat;
        this.compareUnitBaseId = json.compareUnitBaseId === null ? undefined : json.compareUnitBaseId;
        if (json.statModifier) this.statModifier = json.statModifier.map((sm: any) => new LoadoutDefinitionMathExpression(sm));
        if (json.compareModifier) this.compareModifier = json.compareModifier.map((cm: any) => new LoadoutDefinitionMathExpression(cm));
        this.minValue = json.minValue === null ? undefined : json.minValue;
        this.maxValue = json.maxValue === null ? undefined : json.maxValue;
        if (json.minRoundType) this.minRoundType = json.minRoundType;
        if (json.maxRoundType) this.maxRoundType = json.maxRoundType;
        if (json.boolean) this.notRequired = json.notRequired;
    }

    getOperator(val: number)
    {
        return val >= 0 ? "+ " : "";
    }


    isHardTarget(): boolean
    {
        return this.compareUnitBaseId === undefined && this.statModifier === undefined && this.compareModifier === undefined && this.minValue !== undefined;;
    }

    description(unitBaseId: string, units: UnitData[]): string
    {
        let parts: string[] = [];

        let sourceUnit = units.find(u => u.baseId === unitBaseId)!;

        parts.push(sourceUnit.name);
        parts.push(loadoutDefinitionStatIdToString(this.stat));
        if (this.statModifier !== undefined && this.statModifier.length > 0)
        {
            this.statModifier.forEach(sm =>
            {
                parts.push(mathOperatorToString(sm.operator));
                parts.push(sm.operand.toLocaleString());
            });

        }

        if (this.compareUnitBaseId === undefined)
        {
            if (this.minValue !== undefined && this.maxValue !== undefined)
            {
                parts.push(" between [");
                parts.push(this.minValue.toLocaleString());
                parts.push(",");
                parts.push(this.maxValue.toLocaleString());
                parts.push("]");
            } else if (this.minValue !== undefined)
            {
                parts.push(">=");
                parts.push(this.minValue.toLocaleString());
            } else if (this.maxValue !== undefined)
            {
                parts.push("<=");
                parts.push(this.maxValue.toLocaleString());
            }
        } else
        {

            let targetUnit = units.find(u => u.baseId === this.compareUnitBaseId)!;

            if (this.minValue !== undefined && this.maxValue !== undefined)
            {
                parts.push("between");

                parts.push("[");
                parts.push(targetUnit.name);
                parts.push(loadoutDefinitionStatIdToString(this.compareStat === undefined ? this.stat : this.compareStat));

                if (this.compareModifier !== undefined && this.compareModifier.length !== 0)
                {
                    this.compareModifier.forEach(sm =>
                    {
                        parts.push(mathOperatorToString(sm.operator));
                        parts.push(sm.operand.toLocaleString());
                    });
                }


                if (this.minValue !== 0) parts.push(this.getOperator(this.minValue) + this.minValue.toLocaleString());
                parts.push(",");
                parts.push(targetUnit.name);
                parts.push(loadoutDefinitionStatIdToString(this.compareStat === undefined ? this.stat : this.compareStat));

                if (this.compareModifier !== undefined && this.compareModifier.length !== 0)
                {
                    this.compareModifier.forEach(sm =>
                    {
                        parts.push(mathOperatorToString(sm.operator));
                        parts.push(sm.operand.toLocaleString());
                    });
                }

                if (this.maxValue !== 0) parts.push(this.getOperator(this.maxValue) + this.maxValue.toLocaleString());
                parts.push("]");
            } else if (this.minValue !== undefined)
            {
                parts.push(">=");
                parts.push(targetUnit.name);
                parts.push(loadoutDefinitionStatIdToString(this.compareStat === undefined ? this.stat : this.compareStat));

                if (this.compareModifier !== undefined && this.compareModifier.length !== 0)
                {
                    this.compareModifier.forEach(sm =>
                    {
                        parts.push(mathOperatorToString(sm.operator));
                        parts.push(sm.operand.toLocaleString());
                    });
                }

                if (this.minValue !== 0) parts.push(this.getOperator(this.minValue) + this.minValue.toLocaleString());
            } else if (this.maxValue !== undefined)
            {
                parts.push("<=");
                parts.push(targetUnit.name);
                parts.push(loadoutDefinitionStatIdToString(this.compareStat === undefined ? this.stat : this.compareStat));

                if (this.compareModifier !== undefined && this.compareModifier.length !== 0)
                {
                    this.compareModifier.forEach(sm =>
                    {
                        parts.push(mathOperatorToString(sm.operator));
                        parts.push(sm.operand.toLocaleString());
                    });
                }

                if (this.maxValue !== 0) parts.push(this.getOperator(this.maxValue) + this.maxValue.toLocaleString());
            }
        }

        return parts.join(" ");
    }

    mathExpressionsSame(statModifier?: LoadoutDefinitionMathExpression[], targetStatModifier?: LoadoutDefinitionMathExpression[]): boolean
    {
        return (statModifier === undefined && targetStatModifier === undefined) ||
            (statModifier !== undefined && targetStatModifier !== undefined &&
                statModifier.length === targetStatModifier.length &&
                statModifier.find(sm => targetStatModifier.find(tsm => tsm.same(sm)) === undefined) === undefined);
    }

    same(compare: LoadoutDefinitionStatTarget): boolean
    {
        return this.stat === compare.stat &&
            this.compareStat === compare.compareStat &&
            this.compareUnitBaseId === compare.compareUnitBaseId &&
            this.mathExpressionsSame(this.statModifier, compare.statModifier) &&
            this.minValue === compare.minValue &&
            this.maxValue === compare.maxValue &&
            this.mathExpressionsSame(this.compareModifier, compare.compareModifier) &&
            this.minRoundType === compare.minRoundType &&
            this.maxRoundType === compare.maxRoundType &&
            this.notRequired === compare.notRequired;
    }

}

export class LoadoutDefinitionStatWeights
{
    @observable health: number;
    @observable protection: number;
    @observable speed: number;
    @observable criticalDamage: number;
    @observable potency: number;
    @observable tenacity: number;
    @observable physicalDamage: number;
    @observable specialDamage: number;
    @observable criticalChance: number;
    @observable armor: number;
    @observable resistance: number;
    @observable accuracy: number;
    @observable criticalAvoidance: number;
    @observable effectiveHealth: number;
    @observable effectiveHealthProtection: number;


    constructor(json: any)
    {
        this.health = json.health;
        this.protection = json.protection;
        this.speed = json.speed;
        this.criticalDamage = json.criticalDamage;
        this.potency = json.potency;
        this.tenacity = json.tenacity;
        this.physicalDamage = json.physicalDamage;
        this.specialDamage = json.specialDamage;
        this.criticalChance = json.criticalChance;
        this.armor = json.armor;
        this.resistance = json.resistance;
        this.accuracy = json.accuracy;
        this.criticalAvoidance = json.criticalAvoidance;
        this.effectiveHealth = json.effectiveHealth === undefined ? 0 : json.effectiveHealth;
        this.effectiveHealthProtection = json.effectiveHealthProtection === undefined ? 0 : json.effectiveHealthProtection;
    }

    static oneHundredToStat(value: number, statId: LoadoutDefinitionStatIds): number
    {
        // val / 100 * max

        switch (statId)
        {
            case LoadoutDefinitionStatIds.Health:
                return value / 100 * OptimizationPlanStatWeight.HEALTH;
            case LoadoutDefinitionStatIds.Protection:
                return value / 100 * OptimizationPlanStatWeight.PROTECTION;
            case LoadoutDefinitionStatIds.Speed:
                return value / 100 * OptimizationPlanStatWeight.SPEED;
            case LoadoutDefinitionStatIds.CriticalDamage:
                return value / 100 * OptimizationPlanStatWeight.CRIT_DAMAGE;
            case LoadoutDefinitionStatIds.Potency:
                return value / 100 * OptimizationPlanStatWeight.POTENCY;
            case LoadoutDefinitionStatIds.Tenacity:
                return value / 100 * OptimizationPlanStatWeight.TENACITY;
            case LoadoutDefinitionStatIds.PhysicalDamage:
                return value / 100 * OptimizationPlanStatWeight.PHYSCIAL_DAMAGE;
            case LoadoutDefinitionStatIds.SpecialDamage:
                return value / 100 * OptimizationPlanStatWeight.SPECIAL_DAMAGE;
            case LoadoutDefinitionStatIds.UnitPhysicalCriticalChance:
                return value / 100 * OptimizationPlanStatWeight.CRIT_CHANCE;
            case LoadoutDefinitionStatIds.UnitDefense:
                return value / 100 * OptimizationPlanStatWeight.ARMOR;
            case LoadoutDefinitionStatIds.UnitResistance:
                return value / 100 * OptimizationPlanStatWeight.RESISTANCE;
            case LoadoutDefinitionStatIds.UnitPhysicalAccuracy:
                return value / 100 * OptimizationPlanStatWeight.ACCURACY;
            case LoadoutDefinitionStatIds.CriticalAvoidance:
                return value / 100 * OptimizationPlanStatWeight.CRIT_AVOID;
            case LoadoutDefinitionStatIds.EffectiveHealth:
                return value / 100 * OptimizationPlanStatWeight.EFFECTIVE_HEALTH;
            case LoadoutDefinitionStatIds.EffectiveTotalHealth:
                return value / 100 * OptimizationPlanStatWeight.EFFECTIVE_HEALTH_PROTECTION;
        }
        throw new Error("Unknow stat id:" + statId);

    }

    statTo100(statId: LoadoutDefinitionStatIds): number
    {
        switch (statId)
        {
            case LoadoutDefinitionStatIds.Health:
                return this.health / OptimizationPlanStatWeight.HEALTH * 100;
            case LoadoutDefinitionStatIds.Protection:
                return this.protection / OptimizationPlanStatWeight.PROTECTION * 100;
            case LoadoutDefinitionStatIds.Speed:
                return this.speed / OptimizationPlanStatWeight.SPEED * 100;
            case LoadoutDefinitionStatIds.CriticalDamage:
                return this.criticalDamage / OptimizationPlanStatWeight.CRIT_DAMAGE * 100;
            case LoadoutDefinitionStatIds.Potency:
                return this.potency / OptimizationPlanStatWeight.POTENCY * 100;
            case LoadoutDefinitionStatIds.Tenacity:
                return this.tenacity / OptimizationPlanStatWeight.TENACITY * 100;
            case LoadoutDefinitionStatIds.PhysicalDamage:
                return this.physicalDamage / OptimizationPlanStatWeight.PHYSCIAL_DAMAGE * 100;
            case LoadoutDefinitionStatIds.SpecialDamage:
                return this.specialDamage / OptimizationPlanStatWeight.SPECIAL_DAMAGE * 100;
            case LoadoutDefinitionStatIds.UnitPhysicalCriticalChance:
                return this.criticalChance / OptimizationPlanStatWeight.CRIT_CHANCE * 100;
            case LoadoutDefinitionStatIds.UnitDefense:
                return this.armor / OptimizationPlanStatWeight.ARMOR * 100;
            case LoadoutDefinitionStatIds.UnitResistance:
                return this.resistance / OptimizationPlanStatWeight.RESISTANCE * 100;
            case LoadoutDefinitionStatIds.UnitPhysicalAccuracy:
                return this.accuracy / OptimizationPlanStatWeight.ACCURACY * 100;
            case LoadoutDefinitionStatIds.CriticalAvoidance:
                return this.criticalAvoidance / OptimizationPlanStatWeight.CRIT_AVOID * 100;
            case LoadoutDefinitionStatIds.EffectiveHealth:
                return this.effectiveHealth / OptimizationPlanStatWeight.EFFECTIVE_HEALTH * 100;
            case LoadoutDefinitionStatIds.EffectiveTotalHealth:
                return this.effectiveHealthProtection / OptimizationPlanStatWeight.EFFECTIVE_HEALTH_PROTECTION * 100;
        }
        throw new Error("Unknow stat id:" + statId);

    }
    static max(): LoadoutDefinitionStatWeights
    {
        let retVal = new LoadoutDefinitionStatWeights({
            health: OptimizationPlanStatWeight.HEALTH,
            protection: OptimizationPlanStatWeight.PROTECTION,
            speed: OptimizationPlanStatWeight.SPEED,
            criticalDamage: OptimizationPlanStatWeight.CRIT_DAMAGE,
            potency: OptimizationPlanStatWeight.POTENCY,
            tenacity: OptimizationPlanStatWeight.TENACITY,
            physicalDamage: OptimizationPlanStatWeight.PHYSCIAL_DAMAGE,
            specialDamage: OptimizationPlanStatWeight.SPECIAL_DAMAGE,
            criticalChance: OptimizationPlanStatWeight.CRIT_CHANCE,
            armor: OptimizationPlanStatWeight.ARMOR,
            resistance: OptimizationPlanStatWeight.ARMOR,
            accuracy: OptimizationPlanStatWeight.ACCURACY,
            criticalAvoidance: OptimizationPlanStatWeight.CRIT_AVOID,
            effectiveHealth: OptimizationPlanStatWeight.EFFECTIVE_HEALTH,
            effectiveHealthProtection: OptimizationPlanStatWeight.EFFECTIVE_HEALTH_PROTECTION
        });

        return retVal;
    }

    static zero(): LoadoutDefinitionStatWeights
    {
        let retVal = new LoadoutDefinitionStatWeights({
            health: 0,
            protection: 0,
            speed: 0,
            criticalDamage: 0,
            potency: 0,
            tenacity: 0,
            physicalDamage: 0,
            specialDamage: 0,
            criticalChance: 0,
            armor: 0,
            resistance: 0,
            accuracy: 0,
            criticalAvoidance: 0,
            effectiveHealth: 0,
            effectiveHealthProtection: 0
        });

        return retVal;
    }

    getScore(stats: UnitStats, baseStats: UnitStats, level: number): number
    {

        let retVal: UnitStats = new UnitStats();
        retVal.health = this.health * (stats.health - baseStats.health);
        retVal.protection = this.protection * (stats.protection - baseStats.protection);
        retVal.speed = this.speed * (stats.speed - baseStats.speed);
        retVal.damage = this.physicalDamage * (stats.damage - baseStats.damage) * (1 + 1 / 3);
        retVal.specialDamage = this.specialDamage * (stats.specialDamage - baseStats.specialDamage) * (1 + 1 / 3);
        retVal.critDamage = this.criticalDamage * (stats.critDamage - baseStats.critDamage);
        retVal.potency = this.potency * (stats.potency - baseStats.potency);
        retVal.tenacity = this.tenacity * (stats.tenacity - baseStats.tenacity);

        retVal.critChance = (stats.critChance - baseStats.critChance) * this.criticalChance;
        retVal.specialCritChance = (stats.specialCritChance - baseStats.specialCritChance) * this.criticalChance;
        retVal.accuracy = (stats.accuracy - baseStats.accuracy) * this.accuracy;
        retVal.critAvoidance = (stats.critAvoidance - baseStats.critAvoidance) * this.criticalAvoidance;


        let armorDelta = stats.armor - baseStats.armor;
        let resistanceDelta = stats.armor - baseStats.armor;

        let armor = (armorDelta * level * 7.5) / (100 - armorDelta);
        let resistance = (resistanceDelta * level * 7.5) / (100 - resistanceDelta);

        retVal.armor = armor * this.armor * 2;
        retVal.resistance = resistance * this.resistance * 2;

        let effectiveHealthProtection = (retVal.health + retVal.protection) / (1 - retVal.armor / 100);
        let effectiveHealth = (retVal.health) / (1 - retVal.armor / 100);

        let effectiveHealthScore = effectiveHealth * this.effectiveHealth;
        let effectiveHealthProtectionScore = effectiveHealthProtection * this.effectiveHealthProtection;

        return retVal.health + retVal.protection + retVal.speed + retVal.damage + retVal.specialDamage + retVal.critDamage +
            retVal.potency + retVal.tenacity + retVal.critChance + retVal.specialCritChance + retVal.accuracy + retVal.critAvoidance +
            retVal.armor + retVal.resistance + effectiveHealthScore + effectiveHealthProtectionScore;
    }

    same(weights: LoadoutDefinitionStatWeights): boolean
    {
        return this.health === weights.health &&
            this.protection === weights.protection &&
            this.speed === weights.speed &&
            this.criticalDamage === weights.criticalDamage &&
            this.potency === weights.potency &&
            this.tenacity === weights.tenacity &&
            this.physicalDamage === weights.physicalDamage &&
            this.specialDamage === weights.specialDamage &&
            this.criticalChance === weights.criticalChance &&
            this.armor === weights.armor &&
            this.resistance === weights.resistance &&
            this.accuracy === weights.accuracy &&
            this.criticalAvoidance === weights.criticalAvoidance &&
            this.effectiveHealth === weights.effectiveHealth &&
            this.effectiveHealthProtection === weights.effectiveHealthProtection;
    }
}


const SUPPORT_DEFAULT = new LoadoutDefinitionStatWeights({
    health: 0.0025,
    protection: 0.0025,
    speed: 5,
    criticalDamage: 0,
    potency: 0.3333333333333333,
    tenacity: 0,
    physicalDamage: 0,
    specialDamage: 0,
    criticalChance: 0,
    armor: 0,
    resistance: 0,
    accuracy: 0,
    criticalAvoidance: 0,
    effectiveHealth: 0,
    effectiveHealthProtection: 0
});

const ATTACKER_DEFAULT = new LoadoutDefinitionStatWeights({
    health: 0.025,
    protection: 0.00075,
    speed: 5,
    criticalDamage: 0.3,
    potency: 0.2,
    tenacity: 0.6666666666666666,
    physicalDamage: 0.08333333333333333,
    specialDamage: 0.016666666666666666,
    criticalChance: 0.005,
    armor: 0.045454545454545456,
    resistance: 0.045454545454545456,
    accuracy: 0.3,
    criticalAvoidance: 0.3,
    effectiveHealth: 0,
    effectiveHealthProtection: 0
});


const TANK_DEFAULT = new LoadoutDefinitionStatWeights({
    health: 0.005,
    protection: 0.005,
    speed: 5,
    criticalDamage: 0,
    potency: 0,
    tenacity: 0,
    physicalDamage: 0,
    specialDamage: 0,
    criticalChance: 0,
    armor: 0,
    resistance: 0,
    accuracy: 0,
    criticalAvoidance: 0,
    effectiveHealth: 0,
    effectiveHealthProtection: 0
});

const UNKNOWN_DEFAULT = new LoadoutDefinitionStatWeights({
    health: 0,
    protection: 0,
    speed: 5,
    criticalDamage: 0,
    potency: 0,
    tenacity: 0,
    physicalDamage: 0,
    specialDamage: 0,
    criticalChance: 0,
    armor: 0,
    resistance: 0,
    accuracy: 0,
    criticalAvoidance: 0,
    effectiveHealth: 0,
    effectiveHealthProtection: 0
});

export enum LoadoutDefinitionSetBonusRestrictionType
{
    MustUseAll = 1,
    MustUseAny = 2,
    NoOutsideSets = 3
}


export function loadoutDefinitionSetBonusRestrictionTypeToString(rt: LoadoutDefinitionSetBonusRestrictionType): string
{
    switch (rt)
    {
        case LoadoutDefinitionSetBonusRestrictionType.MustUseAll:
            return "Must Use All";
        case LoadoutDefinitionSetBonusRestrictionType.MustUseAny:
            return "Must Use Any";
        case LoadoutDefinitionSetBonusRestrictionType.NoOutsideSets:
            return "No Outside Sets";
    }
}

export class SetRestrictionPossibility
{

    tooManyMustUseAll = false;
    mustUseAllNotInNoOutside = false;
    noMustUseAnyInNoOutside = false;

    notEnoughSpaceMustUseAny = false;

    isPossible(): boolean
    {
        return this.tooManyMustUseAll === false && this.mustUseAllNotInNoOutside === false &&
            this.noMustUseAnyInNoOutside === false &&
            this.notEnoughSpaceMustUseAny === false;
    }

    getNotPossibleDescription(): string[]
    {
        const retVal: string[] = [];

        if (this.tooManyMustUseAll)
        {
            retVal.push("Too many mods list in must use all.");
        }
        if (this.mustUseAllNotInNoOutside)
        {
            retVal.push("Must use all not included in no outside sets.");
        }
        if (this.noMustUseAnyInNoOutside)
        {
            retVal.push("No must use any listed in no outside sets.");
        }
        if (this.notEnoughSpaceMustUseAny)
        {
            retVal.push("No enough space for must use any and must use all.");
        }
        return retVal;
    }
}

export class LoadoutDefinitionSetBonusRestrictions
{
    @observable setBonuses: ModSets[] = [];
    @observable restrictionType: LoadoutDefinitionSetBonusRestrictionType;

    constructor(json: any)
    {
        this.setBonuses = json.setBonuses;
        this.restrictionType = json.restrictionType;
    }

    static isSetPossible(restrictions: LoadoutDefinitionSetBonusRestrictions[]): SetRestrictionPossibility
    {
        const retVal = new SetRestrictionPossibility();

        const mustUseAllRestrictions = restrictions.filter(r => r.restrictionType === LoadoutDefinitionSetBonusRestrictionType.MustUseAll);
        const notOutsideSetsRestrictions = restrictions.filter(r => r.restrictionType === LoadoutDefinitionSetBonusRestrictionType.NoOutsideSets);
        const mustUseAnyRestrictions = restrictions.filter(r => r.restrictionType === LoadoutDefinitionSetBonusRestrictionType.MustUseAny);

        const mustUseAllSets: ModSets[] = [];
        mustUseAllRestrictions.forEach(restriction => restriction.setBonuses.forEach(sb =>
        {
            mustUseAllSets.push(sb);
        }));

        const noOutSideSets: ModSets[] = [];
        notOutsideSetsRestrictions.forEach(restriction => restriction.setBonuses.forEach(sb =>
        {
            if (noOutSideSets.indexOf(sb) === -1)
            {
                noOutSideSets.push(sb);
            }
        }));

        const validMustUseAny: ModSets[] = [];
        mustUseAnyRestrictions.forEach(restriction => restriction.setBonuses.forEach(sb =>
        {
            if (validMustUseAny.indexOf(sb) === -1)
            {
                validMustUseAny.push(sb);
            }
        }));

        const actualMustUseAny = validMustUseAny.filter(sb => (noOutSideSets.length === 0 || noOutSideSets.indexOf(sb) !== -1));

        // specifying more then six mods must be used
        let mustUseAllCount = 0;
        mustUseAllRestrictions.forEach(restriction =>
        {
            restriction.setBonuses.forEach(sb =>
            {
                mustUseAllCount = mustUseAllCount + getSetBonusCount(sb);
            });
        });
        if (mustUseAllCount > 6)
        {
            retVal.tooManyMustUseAll = true;
        }

        if (noOutSideSets.length > 0 && mustUseAllSets.length > 0 &&
            mustUseAllSets.find(set =>
            {
                return noOutSideSets.indexOf(set) === -1;
            }) !== undefined)
        {
            retVal.mustUseAllNotInNoOutside = true;
        }

        if (validMustUseAny.length > 0 && actualMustUseAny.length === 0)
        {

            retVal.noMustUseAnyInNoOutside = true;
        }

        if (validMustUseAny.length > 0 && validMustUseAny.find(s => mustUseAllSets.includes(s)) === undefined)
        {
            // no must use any in must use all
            // need to make sure there are enough free slots to fufill
            let minRequired = actualMustUseAny.find(sb => getSetBonusCount(sb) === 2) === undefined ? 4 : 2;
            retVal.notEnoughSpaceMustUseAny = (6 - mustUseAllCount) < minRequired;
        }

        return retVal;
    }

    getDescription(): string
    {
        let retVal = "";

        switch (this.restrictionType)
        {
            case LoadoutDefinitionSetBonusRestrictionType.MustUseAll: {
                retVal = "Must Use All: ";
                break;
            }
            case LoadoutDefinitionSetBonusRestrictionType.MustUseAny: {
                retVal = "Must Use Any: ";
                break;
            }
            case LoadoutDefinitionSetBonusRestrictionType.NoOutsideSets: {
                retVal = "No Outside Sets: ";
                break;
            }
        }
        retVal += this.setBonuses.slice().sort((a, b) => a - b).map(sb => modSetToString(sb)).join(', ');
        return retVal;
    }

    same(compare: LoadoutDefinitionSetBonusRestrictions): boolean
    {
        return this.restrictionType === compare.restrictionType && this.setBonuses.length === compare.setBonuses.length &&
            this.setBonuses.find(s => compare.setBonuses.includes(s) === false) === undefined;
    }
}

export class LoadoutDefinitionPrimaryBonusRestriction
{
    @observable slot: ModSlots;
    @observable possibleStats: StatIds[];

    constructor(json: any)
    {
        this.slot = json.slot;
        this.possibleStats = json.possibleStats;
    }

    getDescription(): string
    {
        return commonSlotIdToString(this.slot) + ": " + this.possibleStats.slice().sort((a, b) => a - b).map(stat => statIdToString(stat)).join(',');
    }

    same(compare: LoadoutDefinitionPrimaryBonusRestriction): boolean
    {
        return this.slot === compare.slot && this.possibleStats.length === compare.possibleStats.length &&
            this.possibleStats.find(s => compare.possibleStats.includes(s) === false) === undefined;
    }
}

export class LoadoutDefinitionUnitRequirements
{
    @observable minRarity?: number;
    @observable minRelicLevel?: number;
    @observable minGalacticPower?: number;
    @observable minTier?: number;
    @observable ultimate?: boolean;
    @observable zetas?: string[];

    constructor(json: any)
    {
        this.minRarity = json.minRarity;
        this.minRelicLevel = json.minRelicLevel;
        this.minGalacticPower = json.minGalacticPower;
        this.minTier = json.minTier;
        this.ultimate = json.ultimate;
        this.zetas = json.zetas;
    }

    isEmpty(): boolean
    {
        return this.minRarity === undefined && this.minRelicLevel === undefined && this.minGalacticPower === undefined &&
            this.minTier === undefined && this.ultimate === undefined && (this.zetas === undefined || this.zetas.length === 0);
    }
}

export function createDefaultTargets(units: UnitData[]): Map<string, LoadoutDefinitionTarget>
{
    let retVal: Map<string, LoadoutDefinitionTarget> = new Map();

    units.filter(u => u.combatType === 1).forEach(u =>
    {
        retVal.set(u.baseId, createDefaultStatTarget(u));
    });
    return retVal;
}

export function createDefaultStatTarget(unit: UnitData): LoadoutDefinitionTarget
{
    let tank: boolean = unit.role !== null && unit.role.find(r => r.key === "role_tank") !== undefined;
    let attacker: boolean = unit.role !== null && unit.role.find(r => r.key === "role_attacker") !== undefined;
    let support: boolean = unit.role !== null && unit.role.find(r => r.key === "role_support") !== undefined;
    let statWeights = UNKNOWN_DEFAULT;

    if (tank)
    {
        statWeights = TANK_DEFAULT;
    } else if (attacker)
    {
        statWeights = ATTACKER_DEFAULT;
    } else if (support)
    {
        statWeights = SUPPORT_DEFAULT;
    }

    return new LoadoutDefinitionTarget({
        unitBaseId: unit.baseId,
        statWeights: statWeights
    });
}

export interface ITargetStatRangeResult
{
    statId: number;
    minValue?: number;
    maxValue?: number;
    actualValue: number;
    passed: boolean;
}

export interface RequirementsResults
{
    passed: boolean;
    setRequirementsIndexFailed: number[] | null;
    brokenSetsPassed: boolean;
    primarySlotRequirementsFailed: ModSlots[] | null;
    targetStatRanges: ITargetStatRangeResult[] | null;
    passedRequirements: string[];
    failedRequirements: string[];
}

export class ModStrength
{
    health: number = 0;
    protection: number = 0;
    speed: number = 0;
    physicalDamage: number = 0;
    specialDamage: number = 0;
    criticalDamage: number = 0;
    potency: number = 0;
    tenacity: number = 0;
    criticalChance: number = 0;
    specialCriticalChance: number = 0;
    accuracy: number = 0;
    criticalAvoidance: number = 0;
    armor: number = 0;
    resistance: number = 0;
    effectiveHealth: number = 0;
    effectiveHealthProtection: number = 0;

    totalStrength(): number
    {
        return this.health + this.protection + this.speed + this.physicalDamage + this.specialDamage + this.criticalDamage
            + this.potency + this.tenacity + this.criticalChance + this.specialCriticalChance + this.specialCriticalChance +
            this.accuracy + this.criticalAvoidance + this.armor + this.resistance + this.effectiveHealth + this.effectiveHealthProtection;
    }
}


export class StatMinimum
{
    statId: LoadoutDefinitionStatIds;
    minimum: number | undefined;

    constructor(statId: LoadoutDefinitionStatIds, minimum: number | undefined)
    {
        this.statId = statId;
        this.minimum = minimum;
    }
}

export enum TargetTierType
{
    None = 0,
    Optimize = 1,
    Report = 2
}

export class LoadoutDefinitionTargetTier
{
    @observable tierKey: string;
    @observable tierDescription: string;
    @observable targetType: TargetTierType;

    @observable targets?: LoadoutDefinitionStatTarget[];

    constructor(json: any)
    {
        this.tierKey = json.tierKey === undefined ? "" : json.tierKey;
        this.tierDescription = json.tierDescription === undefined ? "" : json.tierDescription;
        this.targetType = json.targetType === undefined ? TargetTierType.None : json.targetType;
        if (json.targets) this.targets = json.targets.map((t: any) => new LoadoutDefinitionStatTarget(t));
    }

    getFullDescription()
    {
        let retVal = this.tierKey;
        if (this.tierDescription !== undefined && this.tierDescription.trim().length > 0)
        {
            retVal = retVal + ": " + this.tierDescription;
        }
        return retVal;
    }

    same(tt: LoadoutDefinitionTargetTier): boolean
    {
        // cont compare target type, thats supposed to be conf
        return this.targetsSame(this.targets, tt.targets);
    }

    targetsSame(source: LoadoutDefinitionStatTarget[] | undefined, compare: LoadoutDefinitionStatTarget[] | undefined): boolean
    {
        return (source === undefined && compare === undefined) ||
            (source !== undefined && compare !== undefined &&
                source.length === compare.length &&
                source.find(pbr => compare!.find(tpbr => tpbr.same(pbr)) === undefined) === undefined
            );
    }
}

export class LoadoutDefinitionTarget
{

    @observable unitBaseId: string;
    @observable statWeights: LoadoutDefinitionStatWeights;
    @observable squadName: string | undefined;

    @observable optimizeTargets?: LoadoutDefinitionStatTarget[];
    @observable reportTargets?: LoadoutDefinitionStatTarget[];
    @observable setBonusRestrictions?: LoadoutDefinitionSetBonusRestrictions[];

    @observable targetTiers?: LoadoutDefinitionTargetTier[];

    @observable primaryBonusRestrictions?: LoadoutDefinitionPrimaryBonusRestriction[];
    @observable requirements?: LoadoutDefinitionUnitRequirements;

    @observable only6E: boolean = false;
    @observable brokenSets: LoadoutDefinitionFullSetEnum = LoadoutDefinitionFullSetEnum.UseGlobalSetting;
    @observable notRequired: boolean = false;

    @observable definitionTitle?: string = undefined;
    @observable definitionVersion?: number = undefined;
    @observable definitionDiscordId?: string = undefined;
    @observable definitionModified: boolean = false;

    public effectiveSame(target: LoadoutDefinitionTarget): boolean
    {
        return this.only6E === target.only6E &&
            this.brokenSets === target.brokenSets &&
            this.statWeights.same(target.statWeights) &&
            this.primaryRestrictionsSame(target) &&
            this.setRestrictionsSame(target) &&
            this.targetsSame(this.optimizeTargets, target.optimizeTargets) &&
            this.targetsSame(this.reportTargets, target.reportTargets) &&
            this.targetsTiersSame(this.targetTiers, target.targetTiers);

    }

    targetsTiersSame(source: LoadoutDefinitionTargetTier[] | undefined, compare: LoadoutDefinitionTargetTier[] | undefined): boolean
    {
        return (source === undefined && compare === undefined) ||
            (source !== undefined && compare !== undefined &&
                source.length === compare.length &&
                source.find(pbr => compare!.find(tpbr => tpbr.same(pbr)) === undefined) === undefined
            );
    }

    targetsSame(source: LoadoutDefinitionStatTarget[] | undefined, compare: LoadoutDefinitionStatTarget[] | undefined): boolean
    {
        return (source === undefined && compare === undefined) ||
            (source !== undefined && compare !== undefined &&
                source.length === compare.length &&
                source.find(pbr => compare!.find(tpbr => tpbr.same(pbr)) === undefined) === undefined
            );
    }

    public primaryRestrictionsSame(target: LoadoutDefinitionTarget): boolean
    {
        return (this.primaryBonusRestrictions === undefined && target.primaryBonusRestrictions === undefined) ||
            (this.primaryBonusRestrictions !== undefined && target.primaryBonusRestrictions !== undefined &&
                this.primaryBonusRestrictions.length === target.primaryBonusRestrictions.length &&
                this.primaryBonusRestrictions.find(pbr => target.primaryBonusRestrictions!.find(tpbr => tpbr.same(pbr)) === undefined) === undefined
            );
    }

    public setRestrictionsSame(target: LoadoutDefinitionTarget): boolean
    {
        return (this.setBonusRestrictions === undefined && target.setBonusRestrictions === undefined) ||
            (this.setBonusRestrictions !== undefined && target.setBonusRestrictions !== undefined &&
                this.setBonusRestrictions.length === target.setBonusRestrictions.length &&
                this.setBonusRestrictions.find(r => target.setBonusRestrictions!.find(tr => tr.same(r)) === undefined) === undefined
            );
    }

    private retainTiers(target: LoadoutDefinitionTarget)
    {
        if (this.targetTiers !== undefined && target.targetTiers !== undefined)
        {
            this.targetTiers.forEach(tt =>
            {
                const matchingTier = target.targetTiers!.find(ttt => ttt.tierKey === tt.tierKey);
                tt.targetType = matchingTier === undefined ? tt.targetType : matchingTier.targetType;
            });
        }
    }

    public getTargetShortDescription()
    {
        let retVal = "";
        if (this.definitionTitle !== undefined)
        {
            retVal = this.definitionTitle;
        } else
        {
            retVal = "";
        }
        if (this.definitionDiscordId !== undefined)
        {
            retVal = retVal + "(" + this.definitionDiscordId + ")";
        }

        return retVal;
    }

    public getTargetFullDescription()
    {
        let retVal = this.getTargetShortDescription();

        if (this.definitionVersion !== undefined)
        {
            retVal = retVal + " v" + this.definitionVersion;
        }

        if (this.definitionModified)
        {
            retVal = retVal + "*";
        }
        return retVal;
    }

    public getKey()
    {
        return this.definitionTitle === undefined || this.definitionDiscordId === undefined ? "" : this.definitionTitle + this.definitionDiscordId;
    }

    forDefinition(ld: LoadoutDefinition)
    {
        return this.definitionTitle === ld.title && this.definitionDiscordId === ld.discordTag;
    }

    forDefinitionSummary(ld: LoadoutDefintionSummary)
    {
        return this.definitionTitle === ld.title && this.definitionDiscordId === ld.discordTag && this.definitionModified === false;
    }

    matchesSummary(ld: LoadoutDefintionSummary)
    {
        return this.forDefinitionSummary(ld) && this.definitionVersion !== undefined && this.definitionVersion === ld.version;
    }


    preserveUpdate(oldLoadoutDefinition: LoadoutDefinitionTarget)
    {
        this.squadName = oldLoadoutDefinition.squadName === undefined ? this.squadName : oldLoadoutDefinition.squadName;
        this.retainTiers(oldLoadoutDefinition);

    }

    hasStatTarget(): boolean  
    {
        return (this.getEffectiveReportTargets() !== undefined && this.getEffectiveReportTargets()!.length > 0) ||
            (this.getEffectiveOptimizeTargets() !== undefined && this.getEffectiveOptimizeTargets()!.length > 0);
    }

    isUpToDate(lds: LoadoutDefintionSummary | undefined): boolean
    {
        return lds !== undefined && lds.version === this.definitionVersion;
    }

    getEffectiveOptimizeTargets(): LoadoutDefinitionStatTarget[] | undefined
    {
        let retVal = this.optimizeTargets === undefined ? undefined : this.optimizeTargets.slice(0);
        if (this.targetTiers !== undefined)
        {
            this.targetTiers.filter(tt => tt.targetType === TargetTierType.Optimize && tt.targets !== undefined && tt.targets.length > 0).forEach(tt =>
            {
                retVal = retVal === undefined ? tt.targets!.slice(0) : retVal.concat(tt.targets!);

            })
        }
        return retVal;
    }
    getEffectiveReportTargets(): LoadoutDefinitionStatTarget[] | undefined
    {
        let retVal = this.reportTargets === undefined ? undefined : this.reportTargets.slice(0);
        if (this.targetTiers !== undefined)
        {
            this.targetTiers.filter(tt => tt.targetType === TargetTierType.Report && tt.targets !== undefined && tt.targets.length > 0).forEach(tt =>
            {
                retVal = retVal === undefined ? tt.targets!.slice(0) : retVal.concat(tt.targets!);

            })
        }
        return retVal;
    }

    hasSquad(): boolean
    {
        return (this.squadName !== null && this.squadName !== undefined && this.squadName.trim().length > 0)
    }

    canUseSpeedArrow(): boolean
    {
        let arrowRestrictions = this.primaryBonusRestrictions === undefined ? undefined : this.primaryBonusRestrictions.find(pbr => pbr.slot === ModSlots.Arrow);
        return arrowRestrictions === undefined || arrowRestrictions.possibleStats.forEach(s => s === StatIds.Speed) !== undefined;
    }
    canUseSpeedSet(): boolean
    {
        if (this.setBonusRestrictions !== undefined)
        {
            let nos = this.setBonusRestrictions.find(sbr => sbr.restrictionType === LoadoutDefinitionSetBonusRestrictionType.NoOutsideSets);
            if (nos !== undefined && nos.setBonuses.find(set => set === ModSets.Speed) === undefined)
            {
                return false;
            }
            let muAll = this.setBonusRestrictions.find(sbr => sbr.restrictionType === LoadoutDefinitionSetBonusRestrictionType.MustUseAll);
            if (muAll !== undefined)
            {
                let slotUsed = 0;
                muAll.setBonuses.forEach(set =>
                {
                    if (set === ModSets.Offense || set === ModSets.CriticalDamage || set === ModSets.Speed)
                    {
                        slotUsed = slotUsed + 4;
                    } else
                    {
                        slotUsed = slotUsed + 2;
                    }
                });
                if (slotUsed > 2 && muAll.setBonuses.find(set => set === ModSets.Speed) === undefined)
                {
                    return false;
                }
            }
            let muAny = this.setBonusRestrictions.find(sbr => sbr.restrictionType === LoadoutDefinitionSetBonusRestrictionType.MustUseAll);
            if (muAny !== undefined)
            {
                let haveTwoSlot = muAny.setBonuses.find(sb => sb !== ModSets.Offense && sb !== ModSets.Speed && sb === ModSets.CriticalDamage) !== undefined;
                let haveSpeed = muAny.setBonuses.find(sb => ModSets.Speed) !== undefined;
                let haveOtherFourSet = muAny.setBonuses.find(sb => ModSets.Offense || sb === ModSets.CriticalDamage) !== undefined;

                if (haveTwoSlot === false && haveSpeed === false && haveOtherFourSet === true)
                {
                    return false;
                }
            }
        }
        return true;
    }

    getAllRelatedUnits()
    {
        let retVal: string[] = [];

        if (this.getEffectiveOptimizeTargets() !== undefined)
        {
            this.getEffectiveOptimizeTargets()!.forEach(st =>
            {
                if (st.compareUnitBaseId !== undefined && retVal.indexOf(st.compareUnitBaseId) === -1)
                {
                    retVal.push(st.compareUnitBaseId);
                }
            });

        }
        if (this.getEffectiveReportTargets() !== undefined)
        {
            this.getEffectiveReportTargets()!.forEach(st =>
            {
                if (st.compareUnitBaseId !== undefined && retVal.indexOf(st.compareUnitBaseId) === -1)
                {
                    retVal.push(st.compareUnitBaseId);
                }
            });

        }

        return retVal;
    }

    static maxScore(baseStats: UnitStats, appliedStats: UnitStats): ModStrength
    {
        return LoadoutDefinitionTarget.scoreStrength(baseStats, appliedStats, LoadoutDefinitionStatWeights.max());
    }

    static scoreStrength(baseStats: UnitStats, appliedStats: UnitStats, ldsw: LoadoutDefinitionStatWeights): ModStrength
    {
        let retVal = new ModStrength();

        let deltaStats = new UnitStats();
        deltaStats.health = appliedStats.health - baseStats.health;
        deltaStats.protection = appliedStats.protection - baseStats.protection;
        deltaStats.speed = appliedStats.speed - baseStats.speed;
        deltaStats.damage = appliedStats.damage - baseStats.damage;
        deltaStats.specialDamage = appliedStats.specialDamage - baseStats.specialDamage;
        deltaStats.critDamage = appliedStats.critDamage - baseStats.critDamage;
        deltaStats.potency = appliedStats.potency - baseStats.potency;
        deltaStats.tenacity = appliedStats.tenacity - baseStats.tenacity;
        deltaStats.critChance = appliedStats.critChance - baseStats.critChance;
        deltaStats.specialCritChance = appliedStats.specialCritChance - baseStats.specialCritChance;
        deltaStats.accuracy = appliedStats.accuracy - baseStats.accuracy;
        deltaStats.critAvoidance = appliedStats.critAvoidance - baseStats.critAvoidance;
        deltaStats.armor = appliedStats.armor - baseStats.armor;
        deltaStats.resistance = appliedStats.resistance - baseStats.resistance;

        retVal.health = ldsw.health * deltaStats.health;
        retVal.protection = ldsw.protection * deltaStats.protection;
        retVal.speed = ldsw.speed * deltaStats.speed;
        retVal.physicalDamage = ldsw.physicalDamage * deltaStats.damage * (1 + 1 / 3);
        retVal.specialDamage = ldsw.specialDamage * deltaStats.specialDamage * (1 + 1 / 3);
        retVal.criticalDamage = deltaStats.critDamage * ldsw.criticalDamage;
        retVal.potency = deltaStats.potency * ldsw.potency;
        retVal.tenacity = deltaStats.tenacity * ldsw.tenacity;
        retVal.criticalChance = (deltaStats.critChance + baseStats.critChance -
            baseStats.critChance) * ldsw.criticalChance;
        retVal.specialCriticalChance = (deltaStats.specialCritChance + baseStats.specialCritChance -
            baseStats.specialCritChance) * ldsw.criticalChance;
        retVal.accuracy = (deltaStats.accuracy + baseStats.accuracy -
            baseStats.accuracy) * ldsw.accuracy;
        retVal.criticalAvoidance = (deltaStats.critAvoidance + baseStats.critAvoidance -
            baseStats.critAvoidance) * ldsw.criticalAvoidance;

        let level = 85;

        let appliedArmorRaw = (appliedStats.armor * level * 7.5) / (100 - appliedStats.armor);
        let baseArmorRaw = (baseStats.armor * level * 7.5) / (100 - baseStats.armor);

        let appliedResistanceRaw = (appliedStats.resistance * level * 7.5) / (100 - appliedStats.resistance);
        let baseResistanceRaw = (baseStats.resistance * level * 7.5) / (100 - baseStats.resistance);

        retVal.armor = (appliedArmorRaw - baseArmorRaw) * ldsw.armor * 2;
        retVal.resistance = (appliedResistanceRaw - baseResistanceRaw) * ldsw.resistance * 2;

        let baseEffectiveHealth = (baseStats.health) / (1 - baseStats.armor / 100);
        let baseEffectiveHealthProtection = (baseStats.health + baseStats.protection) / (1 - baseStats.armor / 100);

        let effectiveHealthProtection = (appliedStats.health + appliedStats.protection) / (1 - appliedStats.armor / 100);
        let effectiveHealth = (appliedStats.health) / (1 - appliedStats.armor / 100);

        retVal.effectiveHealth = (effectiveHealth - baseEffectiveHealth) * ldsw.effectiveHealth;
        retVal.effectiveHealthProtection = (effectiveHealthProtection - baseEffectiveHealthProtection) * ldsw.effectiveHealthProtection;

        return retVal;
    }

    score(baseStats: UnitStats, appliedStats: UnitStats): ModStrength
    {
        return LoadoutDefinitionTarget.scoreStrength(baseStats, appliedStats, this.statWeights);
    }

    getMinimums(): StatMinimum[] 
    {
        let retVal: StatMinimum[] = [];

        if (this.getEffectiveOptimizeTargets() !== undefined)
        {
            this.getEffectiveOptimizeTargets()!.filter(t => t.isHardTarget()).forEach(t =>
            {
                retVal.push(new StatMinimum(t.stat, t.minValue!));
            });
        }

        return retVal;
    }

    constructor(json: any)
    {
        this.unitBaseId = json.unitBaseId;
        this.statWeights = new LoadoutDefinitionStatWeights(json.statWeights);
        this.squadName = json.squadName;
        if (json.optimizeTargets) this.optimizeTargets = json.optimizeTargets.map((t: any) => new LoadoutDefinitionStatTarget(t));
        if (json.targetTiers) this.targetTiers = json.targetTiers.map((t: any) => new LoadoutDefinitionTargetTier(t));
        if (json.reportTargets) this.reportTargets = json.reportTargets.map((t: any) => new LoadoutDefinitionStatTarget(t));
        if (json.setBonusRestrictions) this.setBonusRestrictions = json.setBonusRestrictions.map((t: any) => new LoadoutDefinitionSetBonusRestrictions(t));
        if (json.primaryBonusRestrictions) this.primaryBonusRestrictions = json.primaryBonusRestrictions.map((t: any) => new LoadoutDefinitionPrimaryBonusRestriction(t));
        if (json.requirements) this.requirements = new LoadoutDefinitionUnitRequirements(json.requirements);
        if (json.only6E) this.only6E = json.only6E;
        if (json.brokenSets) this.brokenSets = json.brokenSets;
        if (json.notRequired) this.notRequired = json.notRequired;

        if (json.definitionTitle) this.definitionTitle = json.definitionTitle;
        if (json.definitionVersion) this.definitionVersion = json.definitionVersion;
        if (json.definitionDiscordId) this.definitionDiscordId = json.definitionDiscordId;
        if (json.definitionModified) this.definitionModified = json.definitionModified;
    }

    static newInstance(baseId: string): LoadoutDefinitionTarget
    {
        return new LoadoutDefinitionTarget({
            unitBaseId: baseId,
            statWeights: LoadoutDefinitionStatWeights.zero()
        });
    }
}

export const MAX_LD_SQUAD_SIZE = 10;

export class LoadoutDefinition
{
    @observable discordId: string;
    @observable discordTag: string;
    @observable playerDBId: number;
    @observable playerName: string;
    @observable createdUTC: Date;
    @observable updatedUTC: Date;

    @observable title: string;
    @observable description: string;
    @observable version: number;
    @observable youTubeVideoId: string | null = null;
    @observable squadUnits: string[] | null = null;
    @observable categories: string[] = [];

    @observable targets: LoadoutDefinitionTarget[] = [];

    constructor(json: any)
    {
        this.discordId = json.discordId;
        this.discordTag = json.discordTag;
        this.playerDBId = json.playerDBId;
        this.playerName = json.playerName;
        this.createdUTC = json.createdUTC;
        this.updatedUTC = json.updatedUTC;
        this.youTubeVideoId = json.youTubeVideoId;

        this.title = json.title;
        this.description = json.description;
        this.version = json.version;
        if (json.targets) this.targets = json.targets.filter((t: any) => t !== null).map((t: any) => new LoadoutDefinitionTarget(t));
        if (json.squadUnits) this.squadUnits = json.squadUnits;
        if (json.categories) this.categories = json.categories;
    }

    isSquadLoadoutDefinitionSize()
    {
        return this.targets.length <= 10;
    }

    isSquadDefinition()
    {
        return this.squadUnits !== null;
    }

    formatDescription()
    {
        return this.description === null ? "" : this.description.replaceAll("<br>", "\n");
    }

    hasTarget(unitId: string)
    {
        return this.targets.find(t => t.unitBaseId === unitId) !== undefined
    }

    public getKey()
    {
        return this.title + this.discordId;
    }
}

export const LD_LSTB_CAT = "LSTB";
export const LD_DSTB_CAT = "DSTB";
export const LD_ROTE_CAT = "ROTE";
export const LD_FVF_CAT = "5v5";
export const LD_TVT_CAT = "3v3";
export const LD_HSTR_CAT = "HSTR";
export const LD_CRANCOR_CAT = "CRancor";
export const LD_KRAYT_CAT = "Krayt";
export const LD_JOURNEY_CAT = "Journey Guide";
export const LD_ASSAULT_CAT = "Assault Battle";
export const LD_GC_CAT = "Galactic Challenge";

export const LD_TOP_CAT = "Top Tier";
export const LD_HIGH_CAT = "High Tier";
export const LD_MID_CAT = "Mid Tier";
export const LD_BEG_CAT = "Beginner Tier";

export const LD_CATEGORIES = [LD_FVF_CAT, LD_TVT_CAT, LD_ROTE_CAT, LD_LSTB_CAT, LD_DSTB_CAT, LD_HSTR_CAT, LD_CRANCOR_CAT, LD_KRAYT_CAT, LD_JOURNEY_CAT, LD_ASSAULT_CAT, LD_GC_CAT];
export const LD_CATEGORY_TIERS = [LD_TOP_CAT, LD_HIGH_CAT, LD_MID_CAT, LD_BEG_CAT];

export class LoadoutDefintionSummary
{
    title: string;
    description?: string;
    version: number;
    hasBeenShared: boolean;
    targetCount: number;
    updatedUTC: Date;
    discordId: string;
    discordTag: string;
    youTubeVideoId: string | null = null;
    squadUnits: string[] | null = null;
    categories: string[] = [];

    formatDescription()
    {
        return this.description === null || this.description === undefined ? "" : this.description.replaceAll("<br>", "\n");
    }

    public appliesLoadoutDefintion(unitIds: string[]): boolean
    {
        let retVal: boolean = true;

        if (this.squadUnits !== null && this.squadUnits.length > 0)
        {

            let requiredUnit = this.squadUnits.filter(suid => LoadoutDefintionSummary.isSquadUnitOptions(suid) === false).map(suid => LoadoutDefintionSummary.squadIdToUnitId(suid));
            let optionalUnits = this.squadUnits.filter(suid => LoadoutDefintionSummary.isSquadUnitOptions(suid)).map(suid => LoadoutDefintionSummary.squadIdToUnitId(suid));

            let missingRequiredUnit = requiredUnit.find(ru => unitIds.indexOf(ru) === -1) !== undefined;
            let notDefined = unitIds.find(uid => requiredUnit.indexOf(uid) === -1 && optionalUnits.indexOf(uid) === -1) !== undefined;

            retVal = missingRequiredUnit === false && notDefined === false;
        } else
        {
            retVal = false;
        }

        return retVal;
    }

    isSummaryForTarget(ldt: LoadoutDefinitionTarget)
    {
        return ldt.definitionTitle !== undefined && ldt.definitionTitle.toUpperCase() === this.title.toUpperCase() &&
            ldt.definitionDiscordId === this.discordTag;
    }

    public isSame(lds: LoadoutDefintionSummary)
    {
        return lds.title === this.title && lds.discordId === this.discordId;
    }


    public getLoadoutDefinition(loadoutDefinitions: LoadoutDefinition[]): LoadoutDefinition | undefined
    {
        return loadoutDefinitions.find(ld => this.isLoadoutDefinition(ld));
    }

    public isLoadoutDefinition(ld: LoadoutDefinition)
    {
        return ld.title === this.title && ld.discordId === this.discordId;
    }

    public getTargetShortDescription()
    {
        let retVal = "";
        if (this.title !== undefined)
        {
            retVal = this.title;
        } else
        {
            retVal = "";
        }
        if (this.discordTag !== undefined)
        {
            retVal = retVal + "(" + this.discordTag + ")";
        }

        return retVal;
    }

    public getTargetFullDescription()
    {
        let retVal = this.getTargetShortDescription();

        if (this.version !== undefined)
        {
            retVal = retVal + " v" + this.version;
        }
        return retVal;
    }

    public getKey()
    {
        return this.title + this.discordId;
    }

    public static isSquadUnitOptions(squadId: string): boolean
    {
        return squadId.indexOf("?") !== -1;
    }

    public static squadIdToUnitId(squadId: string): string
    {
        return squadId.replace("?", "");
    }

    public static unitIdToSquadId(squadId: string, notRequired: boolean): string
    {
        return notRequired ? "?" + squadId : squadId;
    }

    constructor(json: any)
    {
        this.title = json.title;
        this.description = json.description;
        this.version = json.version;
        this.hasBeenShared = json.hasBeenShared;
        this.targetCount = json.targetCount;
        this.discordId = json.discordId;
        this.discordTag = json.discordTag;
        this.youTubeVideoId = json.youTubeVideoId;
        this.updatedUTC = new Date(json.updatedUTC);
        if (json.squadUnits) this.squadUnits = json.squadUnits;
        if (json.categories) this.categories = json.categories;
    }
}

export class LoadoutDefintionGroup
{
    groupName: string;
    shareType: number;
    definitions: LoadoutDefintionSummary[] = [];

    constructor(json: any)
    {
        this.groupName = json.groupName;
        this.shareType = json.shareType;
        if (json.definitions) this.definitions = json.definitions.filter((d: any) => d.discordTag).map((d: any) => new LoadoutDefintionSummary(d));
    }

    static getLoadoutDefinitionSumary(groups: LoadoutDefintionGroup[] | null, key: string): LoadoutDefintionSummary | undefined
    {
        let retVal: LoadoutDefintionSummary | undefined;
        if (groups !== null)
        {
            groups.forEach(g =>
            {
                g.definitions.forEach(d =>
                {
                    if (d.getKey() === key)
                    {
                        retVal = d;
                    }

                })
            })
        }
        return retVal;
    }

    static getLoadoutDefinitions(groups: LoadoutDefintionGroup[] | null): LoadoutDefintionSummary[]
    {
        let retVal: LoadoutDefintionSummary[] = [];
        if (groups !== null)
        {
            groups.forEach(g =>
            {
                g.definitions.forEach(d =>
                {
                    if (retVal.find(di => di.title === d.title && di.discordTag === d.discordTag) === undefined)
                    {
                        retVal.push(d);
                    }

                })
            })
        }
        return retVal;
    }
}


function getAllUnits(ld: LoadoutDefinition): string[]
{
    let retVal: string[] = [];
    ld.targets.forEach(t =>
    {
        if (retVal.indexOf(t.unitBaseId) === -1)
        {
            retVal.push(t.unitBaseId);
        }
        if (t.getEffectiveOptimizeTargets() !== undefined)
        {
            t.getEffectiveOptimizeTargets()!.forEach(ot =>
            {
                if (ot.compareUnitBaseId !== undefined && retVal.indexOf(ot.compareUnitBaseId) === -1)
                {
                    retVal.push(ot.compareUnitBaseId);
                }
            });
        }
        if (t.getEffectiveReportTargets()! !== undefined)
        {
            t.getEffectiveReportTargets()!.forEach(rt =>
            {
                if (rt.compareUnitBaseId !== undefined && retVal.indexOf(rt.compareUnitBaseId) === -1)
                {
                    retVal.push(rt.compareUnitBaseId);
                }
            });
        }
    });
    return retVal;
}

function getInvertedTargetMinimum(st: LoadoutDefinitionStatTarget, value: number): number | undefined
{
    let retVal: number | undefined = undefined;

    if (st.maxValue !== undefined)
    {
        retVal = value + st.maxValue;

        if (st.statModifier !== undefined && st.statModifier.length !== 0)
        {
            switch (st.statModifier[0].operator)
            {
                case LoadoutDefinitionMathOperator.Add:
                    retVal = retVal + st.statModifier[0].operand;
                    break;
                case LoadoutDefinitionMathOperator.Subtract:
                    retVal = retVal - st.statModifier[0].operand;
                    break;
                case LoadoutDefinitionMathOperator.Multiple:
                    retVal = retVal * st.statModifier[0].operand;
                    break;
                case LoadoutDefinitionMathOperator.Divide:
                    retVal = retVal / st.statModifier[0].operand;
                    break;
            }
        }

        if (st.compareUnitBaseId !== undefined)
        {
            let rightValue = 0;
            if (st.compareModifier !== undefined && st.compareModifier.length !== 0)
            {
                switch (st.compareModifier[0].operator)
                {
                    case LoadoutDefinitionMathOperator.Add:
                        rightValue = rightValue + st.compareModifier[0].operand;
                        break;
                    case LoadoutDefinitionMathOperator.Subtract:
                        rightValue = rightValue - st.compareModifier[0].operand;
                        break;
                    case LoadoutDefinitionMathOperator.Multiple:
                        rightValue = rightValue / st.compareModifier[0].operand;
                        break;
                    case LoadoutDefinitionMathOperator.Divide:
                        rightValue = rightValue * st.compareModifier[0].operand;
                        break;
                }
            }
            retVal = retVal + rightValue;
        }
    }

    return retVal;
}

function getTargetMinimum(st: LoadoutDefinitionStatTarget, otherUnitsSpeeds: Map<string, number>): number | undefined
{
    let retVal: number | undefined = undefined;

    if (st.minValue !== undefined && (st.compareUnitBaseId === undefined || otherUnitsSpeeds.has(st.compareUnitBaseId)))
    {
        retVal = st.minValue;

        if (st.statModifier !== undefined && st.statModifier.length !== 0)
        {
            switch (st.statModifier[0].operator)
            {
                case LoadoutDefinitionMathOperator.Add:
                    retVal = retVal - st.statModifier[0].operand;
                    break;
                case LoadoutDefinitionMathOperator.Subtract:
                    retVal = retVal + st.statModifier[0].operand;
                    break;
                case LoadoutDefinitionMathOperator.Multiple:
                    retVal = retVal / st.statModifier[0].operand;
                    break;
                case LoadoutDefinitionMathOperator.Divide:
                    retVal = retVal * st.statModifier[0].operand;
                    break;
            }
        }

        if (st.compareUnitBaseId !== undefined && st.compareUnitBaseId.length !== 0)
        {
            let rightValue = otherUnitsSpeeds.get(st.compareUnitBaseId)!;
            if (st.compareModifier !== undefined)
            {
                switch (st.compareModifier[0].operator)
                {
                    case LoadoutDefinitionMathOperator.Add:
                        rightValue = rightValue + st.compareModifier[0].operand;
                        break;
                    case LoadoutDefinitionMathOperator.Subtract:
                        rightValue = rightValue - st.compareModifier[0].operand;
                        break;
                    case LoadoutDefinitionMathOperator.Multiple:
                        rightValue = rightValue * st.compareModifier[0].operand;
                        break;
                    case LoadoutDefinitionMathOperator.Divide:
                        rightValue = rightValue / st.compareModifier[0].operand;
                        break;
                }
            }
            retVal = retVal + rightValue;
        }
    }

    return retVal;
}


function deriveMinimumSpeed(unitId: string, searchedUnits: string[], otherUnitsSpeeds: Map<string, number>, targets: LoadoutDefinitionTarget[])
{
    if (searchedUnits.indexOf(unitId) === -1)
    {
        searchedUnits.push(unitId);
        targets.forEach(target =>
        {
            if (target.getEffectiveOptimizeTargets() !== undefined)
            {
                // only compare speed to speed.  dont care if compare stat is speed cause thats unrealistic
                target.getEffectiveOptimizeTargets()!.filter(st => st.stat === LoadoutDefinitionStatIds.Speed).forEach(st =>
                {
                    if (target.unitBaseId === unitId)
                    {
                        if (st.compareUnitBaseId !== undefined)
                        {
                            deriveMinimumSpeed(st.compareUnitBaseId, searchedUnits, otherUnitsSpeeds, targets);
                        }

                        let minimum = getTargetMinimum(st, otherUnitsSpeeds);
                        if (minimum !== undefined && (otherUnitsSpeeds.has(unitId) === false || otherUnitsSpeeds.get(unitId)! > minimum))
                        {
                            otherUnitsSpeeds.set(unitId, minimum);
                        }
                    } else if (st.compareUnitBaseId !== undefined && st.compareUnitBaseId === unitId)
                    {
                        deriveMinimumSpeed(target.unitBaseId, searchedUnits, otherUnitsSpeeds, targets);

                        if (otherUnitsSpeeds.has(target.unitBaseId))
                        {
                            let minimum = getInvertedTargetMinimum(st, otherUnitsSpeeds.get(target.unitBaseId)!);
                            if (minimum !== undefined && (otherUnitsSpeeds.has(unitId) === false || otherUnitsSpeeds.get(unitId)! > minimum))
                            {
                                otherUnitsSpeeds.set(unitId, minimum);
                            }
                        }
                    }
                });
            }
        });
    }
}

export function deriveBasicRequiredSpeed(ld: LoadoutDefinition): Map<string, number>
{
    let retVal: Map<string, number> = new Map();

    let unitList = getAllUnits(ld);

    unitList.forEach(unitId =>
    {
        deriveMinimumSpeed(unitId, [], retVal, ld.targets);
    });

    return retVal;
}

export function getInverseValue(base: number, value: number, statModifier?: LoadoutDefinitionMathExpression[],
    compareModifier?: LoadoutDefinitionMathExpression[]): number
{

    let retVal: number = base - value;

    if (statModifier !== undefined)
    {
        statModifier.forEach(sm =>
        {
            switch (sm.operator)
            {
                case LoadoutDefinitionMathOperator.Add:
                    retVal = retVal + sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Divide:
                    retVal = retVal / sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Multiple:
                    retVal = retVal * sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Subtract:
                    retVal = retVal - sm.operand;
                    break;
            }

        });
    }

    if (compareModifier !== undefined)
    {
        compareModifier.slice().reverse().forEach(sm =>
        {
            switch (sm.operator)
            {
                case LoadoutDefinitionMathOperator.Add:
                    retVal = retVal - sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Divide:
                    retVal = retVal * sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Multiple:
                    retVal = retVal / sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Subtract:
                    retVal = retVal + sm.operand;
                    break;
            }

        });
    }
    return retVal;
}

export function getValue(base: number, value: number, statModifier?: LoadoutDefinitionMathExpression[]): number
{

    let retVal: number = base + value;

    if (statModifier !== undefined)
    {
        statModifier.slice().reverse().forEach(sm =>
        {
            switch (sm.operator)
            {
                case LoadoutDefinitionMathOperator.Add:
                    retVal = retVal - sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Divide:
                    retVal = retVal * sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Multiple:
                    retVal = retVal / sm.operand;
                    break;
                case LoadoutDefinitionMathOperator.Subtract:
                    retVal = retVal + sm.operand;
                    break;
            }

        });
    }

    return retVal;
}

interface Range
{
    minValue: number | undefined;
    maxValue: number | undefined;
}

export class LoadoutDefinitionRangesResult
{
    rangeResults: Map<string, LoadoutDefinitionStatTarget[]>;
    warningMessage: string | undefined;

    constructor(rangeResults: Map<string, LoadoutDefinitionStatTarget[]>, warningMessage: string | undefined)
    {
        this.rangeResults = rangeResults;
        this.warningMessage = warningMessage;
    }
}

export class LoadoutDefinitionCalculator
{
    static processMathExpression(value: number, statModifier?: LoadoutDefinitionMathExpression[], invert: boolean = false): number
    {
        let retVal = value;
        if (statModifier !== undefined)
        {
            let smList = invert ? statModifier.slice().reverse() : statModifier;
            smList.forEach(sm =>
            {
                switch (sm.operator)
                {
                    case LoadoutDefinitionMathOperator.Add:
                        retVal = invert ? retVal - sm.operand : retVal + sm.operand;
                        break;
                    case LoadoutDefinitionMathOperator.Divide:
                        retVal = invert ? retVal * sm.operand : retVal / sm.operand;
                        break;
                    case LoadoutDefinitionMathOperator.Multiple:
                        retVal = invert ? retVal / sm.operand : retVal * sm.operand;
                        break;
                    case LoadoutDefinitionMathOperator.Subtract:
                        retVal = invert ? retVal + sm.operand : retVal - sm.operand;
                        break;
                }
            });
        }
        return retVal;
    }

    static getRightMinValue(ot: LoadoutDefinitionStatTarget, retVal: Map<string, LoadoutDefinitionStatTarget[]>)
    {
        let baseMinValue: undefined | number = undefined;
        if (ot.minValue !== undefined)
        {
            if (ot.compareUnitBaseId === undefined)
            {
                baseMinValue = 0;
            } else if (retVal.has(ot.compareUnitBaseId))
            {
                let compareStat = ot.compareStat === undefined ? ot.stat : ot.compareStat;

                let compareLdst = retVal.get(ot.compareUnitBaseId)!.find(ldst => ldst.stat === compareStat);
                if (compareLdst !== undefined)
                {
                    baseMinValue = compareLdst.minValue === undefined ? undefined : compareLdst.minValue;
                }
            }
        }
        return baseMinValue;
    }

    static getRightMaxValue(ot: LoadoutDefinitionStatTarget, retVal: Map<string, LoadoutDefinitionStatTarget[]>)
    {
        let baseMaxValue: undefined | number = undefined;

        if (ot.maxValue !== undefined)
        {
            if (ot.compareUnitBaseId === undefined)
            {
                baseMaxValue = 0;
            } else if (retVal.has(ot.compareUnitBaseId))
            {
                let compareStat = ot.compareStat === undefined ? ot.stat : ot.compareStat;

                let compareLdst = retVal.get(ot.compareUnitBaseId)!.find(ldst => ldst.stat === compareStat);
                if (compareLdst !== undefined)
                {
                    baseMaxValue = compareLdst.maxValue === undefined ? undefined : compareLdst.maxValue;
                }
            }
        }
        return baseMaxValue;
    }

    static getLeftMinValue(unitId: string, ot: LoadoutDefinitionStatTarget, ldstm: Map<string, LoadoutDefinitionStatTarget[]>): number | undefined
    {
        let retVal: undefined | number = undefined;


        if (ldstm.has(unitId))
        {
            let ldst = ldstm.get(unitId)!.find(ldst => ldst.stat === ot.stat);
            if (ldst !== undefined)
            {
                retVal = ldst.minValue === undefined ? undefined : ldst.minValue;
            }
        }
        return retVal;
    }

    static getLeftMaxValue(unitId: string, ot: LoadoutDefinitionStatTarget, ldstm: Map<string, LoadoutDefinitionStatTarget[]>): number | undefined
    {
        let retVal: undefined | number = undefined;

        if (ldstm.has(unitId))
        {
            let ldst = ldstm.get(unitId)!.find(ldst => ldst.stat === ot.stat);
            if (ldst !== undefined)
            {
                retVal = ldst.maxValue === undefined ? undefined : ldst.maxValue;
            }
        }
        return retVal;
    }

    static getStatTarget(baseId: string, statId: LoadoutDefinitionStatIds, retVal: Map<string, LoadoutDefinitionStatTarget[]>): LoadoutDefinitionStatTarget
    {
        let ldst = retVal.get(baseId)!.find(ldsti => ldsti.stat === statId);
        if (ldst === undefined)
        {
            ldst = new LoadoutDefinitionStatTarget({
                stat: statId
            });
            retVal.get(baseId)!.push(ldst);
        }
        return ldst;
    }

    static updateMinAndMax(ldst: LoadoutDefinitionStatTarget, range: Range, description: string): boolean
    {
        let retVal: boolean = false;

        if (range.minValue !== undefined && (ldst.minValue === undefined || range.minValue > ldst.minValue))
        {
            retVal = true;
            ldst.minValue = range.minValue;
        }

        if (range.maxValue !== undefined && (ldst.maxValue === undefined || range.maxValue < ldst.maxValue))
        {
            retVal = true;
            ldst.maxValue = range.maxValue;
        }
        return retVal;
    }

    static deriveHardRanges(targets: LoadoutDefinitionTarget[]): LoadoutDefinitionRangesResult
    {
        let retVal: Map<string, LoadoutDefinitionStatTarget[]> = new Map();

        let updates = true;

        let loops = 0;

        const loopMax = 10000;

        let lastUpdatedUnit: string | undefined = undefined;
        let warningMessage: string | undefined = undefined;

        while (updates && loops < loopMax)
        {
            loops = loops + 1;
            updates = false;
            for (var x = 0; x < targets.length; x++)
            {
                let target = targets[x];
                if (retVal.has(target.unitBaseId) === false) retVal.set(target.unitBaseId, []);

                if (target.getEffectiveOptimizeTargets() !== undefined)
                {
                    for (var y = 0; y < target.getEffectiveOptimizeTargets()!.length; y++)
                    {
                        let ot = target.getEffectiveOptimizeTargets()![y];

                        if (ot.compareUnitBaseId !== undefined && retVal.has(ot.compareUnitBaseId) === false) retVal.set(ot.compareUnitBaseId, []);

                        //----- UPDATE UNIT -------------
                        let rightMinValue = LoadoutDefinitionCalculator.getRightMinValue(ot, retVal);
                        let rightMaxValue = LoadoutDefinitionCalculator.getRightMaxValue(ot, retVal);

                        //--------Process right side of the equation----
                        if (rightMinValue !== undefined)
                            rightMinValue = LoadoutDefinitionCalculator.processMathExpression(rightMinValue, ot.compareModifier, false) + ot.minValue!;
                        if (rightMaxValue !== undefined)
                            rightMaxValue = LoadoutDefinitionCalculator.processMathExpression(rightMaxValue, ot.compareModifier, false) + ot.maxValue!;

                        //--------Process right left of equation----
                        let minValue = (rightMinValue === undefined) ? undefined :
                            LoadoutDefinitionCalculator.processMathExpression(rightMinValue, ot.statModifier, true);
                        let maxValue = (rightMaxValue === undefined) ? undefined :
                            LoadoutDefinitionCalculator.processMathExpression(rightMaxValue, ot.statModifier, true);

                        let ldst = LoadoutDefinitionCalculator.getStatTarget(target.unitBaseId, ot.stat, retVal);
                        var description = target.unitBaseId + " " + ot.compareUnitBaseId;
                        let isUpdate = LoadoutDefinitionCalculator.updateMinAndMax(ldst, { minValue: minValue, maxValue: maxValue }, description);
                        updates = isUpdate || updates;
                        if (isUpdate)
                        {
                            lastUpdatedUnit = target.unitBaseId;
                        }

                        //----- UPDATE COMPARE UNIT -----
                        if (ot.compareUnitBaseId !== undefined)
                        {
                            let compareMinValue = LoadoutDefinitionCalculator.getLeftMinValue(target.unitBaseId, ot, retVal);
                            let transientMinValue = compareMinValue === undefined || ot.maxValue === undefined ? undefined :
                                LoadoutDefinitionCalculator.processMathExpression(compareMinValue, ot.statModifier, false) - ot.maxValue!;
                            transientMinValue = transientMinValue === undefined ? undefined :
                                LoadoutDefinitionCalculator.processMathExpression(transientMinValue, ot.compareModifier, true);

                            let compareMaxValue = LoadoutDefinitionCalculator.getLeftMaxValue(target.unitBaseId, ot, retVal);
                            let transientMaxValue = compareMaxValue === undefined || ot.minValue === undefined ? undefined :
                                LoadoutDefinitionCalculator.processMathExpression(compareMaxValue, ot.statModifier, false) - ot.minValue!;
                            transientMaxValue = transientMaxValue === undefined ? undefined :
                                LoadoutDefinitionCalculator.processMathExpression(transientMaxValue, ot.compareModifier, true);

                            let cldst = LoadoutDefinitionCalculator.getStatTarget(ot.compareUnitBaseId!, ot.stat, retVal);
                            let unitUp = LoadoutDefinitionCalculator.updateMinAndMax(cldst, { minValue: transientMinValue, maxValue: transientMaxValue }, description);

                            updates = unitUp || updates;

                            if (unitUp)
                            {
                                lastUpdatedUnit = ot.compareUnitBaseId;
                                console.log("updating: " + target.unitBaseId + " " + ot.compareUnitBaseId);
                            }
                        }
                    };
                }

            };
        }

        if (loops >= loopMax)
        {
            warningMessage = "You have an infinite stat loop, probably related around unit (either cyclical dependency or min is greater then max): " + lastUpdatedUnit + " contact Strange#8935! for more details";
            alert(warningMessage);
        }

        Array.from(retVal.keys()).forEach(unitId =>
        {
            let ldsts = retVal.get(unitId)!.filter(ldst => ldst.minValue !== undefined || ldst.maxValue !== undefined);

            if (ldsts.length > 0)
            {
                retVal.set(unitId, ldsts);
            } else
            {
                retVal.delete(unitId);
            }
        })
        return new LoadoutDefinitionRangesResult(retVal, warningMessage);
    }

}
