import React, { Suspense } from "react";
import "./App.css";
import { Redirect, Route, RouteComponentProps, RouteProps, Router, Switch } from "react-router-dom";
import history from "./utils/history";
import LoginPage from "./pages/LoginFlow/LoginPage";
import Callback from "./pages/LoginFlow/Callback";
import AccountSelectionPage from "./pages/LoginFlow/AccountSelect";
import { observer } from "mobx-react";
import UserData from "./model/UserData";
import GoogleCallback from "./pages/LoginFlow/GoogleCallback";
import GameData from './model/GameData';
import i18next from "i18next";
import Fetch from "i18next-fetch-backend";
import { action, configure, observable, runInAction } from "mobx";
import { ReactCookieProps, withCookies } from 'react-cookie';
import BaseAPI from "./service/BaseAPI";
import LoadingSpinner from "./components/Loading/LoadingSpinner";
import { darkTheme, lightTheme } from "./ui/theme";
import { GlobalStyles } from "./ui/globalStyles";
import { ThemeProvider } from "styled-components";
import Home from "./pages/Dashboard";
import { Modal } from "antd";
import TBData from "./pages/TB/TBData/tb-data";
import ModWizard from "./pages/Mods/ModWizard/ModWizard";
import EAConnect from "./pages/LoginFlow/EAConnect";
import CommandCenter from "./pages/TB/TBCommandCenter/CommandCenter";

const NotFoundPage = React.lazy(() => import("./pages/404"));
const ModOptimizer = React.lazy(() => import("./pages/ModOptimizer"));
const GuildFarming = React.lazy(() => import("./pages/Guild/Farming/GuildFarming"));
const GuildGLProgress = React.lazy(() => import("./pages/Guild/Farming/GuildGLProgress"));
const PersonalFarming = React.lazy(() => import("./pages/Guild/Farming/PersonalFarming"));
const ModLoadouts = React.lazy(() => import("./pages/Mods/Loadouts/Loadouts"));
const UnequippedMods = React.lazy(() => import("./pages/Mods/Unequipped/UnequippedMods"));
const PlaygroundBeta = React.lazy(() => import("./pages/Mods/PlaygroundBeta/PlaygroundV2"));
const TBStatus = React.lazy(() => import("./pages/TB/TBStatus/tb-status"));
const TBPlayers = React.lazy(() => import("./pages/TB/TBPlayers/tb-players"));
const Overview = React.lazy(() => import("./pages/TW/Overview"));
const History = React.lazy(() => import("./pages/TW/History"));
const TWScoreboard = React.lazy(() => import("./pages/TW/TWScoreboard"));
const TWReporting = React.lazy(() => import("./pages/TW/TWReporting"));
const GuildMembers = React.lazy(() => import("./pages/Guild/GuildMembers"));
const ModAnalysis = React.lazy(() => import('./pages/Mods/Analysis/ModAnalysis'));
const GacCompare = React.lazy(() => import('./pages/GAC/Compare/GacCompare'));
const OverviewPage = React.lazy(() => import('./pages/ContentCreators/OverviewPage'));
const GacHistory = React.lazy(() => import('./pages/GAC/GACHistory'));
const GacView = React.lazy(() => import('./pages/GAC/GACView'));
const GacPlanning = React.lazy(() => import('./pages/GAC/GACPlanning'));
const TBPlanning = React.lazy(() => import('./pages/TB/TBPlanning/Planning'));
const TBStrategy = React.lazy(() => import('./pages/TB/TBStrategy/tb-strategy'));
const TWCommandCenter = React.lazy(() => import('./pages/TW/TWCommandCenter/TWCommandCenter'));
const TWPlanning = React.lazy(() => import('./pages/TW/TWPlanning/TWTemplatesOverview'));
const ModSwap = React.lazy(() => import('./pages/Mods/Swap/ModSwap'));
const TemplateEditor = React.lazy(() => import('./pages/Template/Editor/TemplateEditor'));
const TemplateExecute = React.lazy(() => import('./pages/Template/Execute/TemplateExecute'));
const SquadComplianceEditor = React.lazy(() => import('./pages/Template/SquadComplianceEditor/SquadComplianceEditor'));
const DataDiskManagement = React.lazy(() => import("./pages/Conquest/DataDiskManagement"));
const ConquestStatus = React.lazy(() => import("./pages/Conquest/ConquestStatus"));
const ModServices = React.lazy(() => import('./pages/Mods/Services/ModServices'));
const BronziumPage = React.lazy(() => import("./pages/Dashboard/Bronziums"));
const MyProfile = React.lazy(() => import("./pages/Dashboard/MyProfile"));
const GuildUnitsPage = React.lazy(() => import("./pages/Guild/GuildUnitsPage"));
const UnitPage = React.lazy(() => import("./pages/Guild/Units/UnitPage"));
const GuildSquadsPage = React.lazy(() => import("./pages/Squads/SquadsPage"));
const GuildEvents = React.lazy(() => import("./pages/Guild/GuildEvents"));
const PlayerEvents = React.lazy(() => import("./pages/Dashboard/PlayerEvents"));
const Violations = React.lazy(() => import("./pages/Guild/Violations"));
const GuildSearch = React.lazy(() => import("./pages/Guild/GuildSearch"));
const Compare = React.lazy(() => import("./pages/Guild/Compare/Compare"));
const CompareUnits = React.lazy(() => import("./pages/Guild/Compare/CompareUnits"));
const GuildPlayerProfilePage = React.lazy(() => import("./pages/Guild/PlayerProfile/GuildPlayerProfilePage"));
const GuildTickets = React.lazy(() => import("./pages/Guild/GuildTickets"));
const ShareSettings = React.lazy(() => import("./pages/Settings/ShareSettings"));
const GuildWatchSettings = React.lazy(() => import("./pages/Guild/Watch/GuildWatch"));
const GuildAlliances = React.lazy(() => import("./pages/Guild/Alliances/GuildAlliances"));
const Developer = React.lazy(() => import("./pages/Dashboard/Developer"));
const Salvage = React.lazy(() => import("./pages/Salvage/Salvage"));
const Datacrons = React.lazy(() => import("./pages/Datacrons/DatacronOverview"));
const ModMaintenance = React.lazy(() => import("./pages/Mods/Maintenance/ModMaintenance"));
const ModDepth = React.lazy(() => import("./pages/Mods/Depth/ModDepth"));
const StrangesGearTracker = React.lazy(() => import("./pages/Guild/Farming/StrangesGearTracker"));

interface PrivateRouteProps extends RouteProps
{
    user: UserData;
    renderPage: (props: RouteComponentProps<any>) => React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ user, renderPage, ...rest }) =>
{
    return (
        <Route
            {...rest}
            render={(routeProps) =>
                user.currentPlayer ? (
                    renderPage(routeProps)
                ) : (
                    <Redirect
                        to={{
                            pathname: "/login",
                            state: { from: routeProps.location }
                        }}
                    />
                )
            }
        />
    );
}

interface AppProps extends ReactCookieProps
{
    user: UserData;
    gameData: GameData;
}

@observer
class App extends React.Component<AppProps> {
    @observable i18Loaded: boolean = false;
    @observable cookieLoaded: boolean = false;

    constructor(props: AppProps)
    {
        super(props);

        configure({ enforceActions: "observed" });
        window.addEventListener("unhandledrejection", (event) =>
        {
            event.preventDefault();
            if (event.reason && event.reason.errorMessage)
                Modal.error({ title: i18next.t("common:ui.error_dlg_title"), content: <span>{"Error: " + event.reason.errorMessage}</span>, maskClosable: false });
            else
                Modal.error({ title: i18next.t("common:ui.error_dlg_title"), content: <span>{"Error: " + event.reason.toString()}</span>, maskClosable: false });
        });
    }

    componentDidMount()
    {
        if (this.props.user.currentPlayer != null)
        {
            // do not re-login on hotreload
            runInAction(() => this.cookieLoaded = true);
        } else if (this.props.cookies && this.props.cookies.get("hotUtilsSession"))
        {
            runInAction(() => this.props.user.sessionId = this.props.cookies!.get("hotUtilsSession"));

            if (this.props.cookies.get("hotUtilsAllyCode"))
            {
                // re-login on page refresh
                BaseAPI.reloginUser(this.props.user, this.props.gameData, this.props.cookies.get("hotUtilsAllyCode"))
                    .then(action(() => this.cookieLoaded = true))
                    .catch(action(() => this.cookieLoaded = true));
            } else
            {
                runInAction(() => this.cookieLoaded = true);
            }
        } else
        {
            runInAction(() => this.cookieLoaded = true);
        }
        i18next.use(Fetch).init({
            ns: ['common'],
            fallbackLng: 'en',
            backend: {
                loadPath: '/locales/{{lng}}/{{ns}}.json',
            },
        }).then(action(() =>
        {
            this.i18Loaded = true;
        }));
    }

    render()
    {
        if (!this.i18Loaded)
        {
            return <LoadingSpinner spinning={true} size={'large'} text={'Please wait, loading languages...'} textWhite={true} />;
        }
        if (!this.cookieLoaded)
        {
            return <LoadingSpinner spinning={true} size={'large'} text={'Please wait, logging in...'} textWhite={true} />;
        }

        return (
            <ThemeProvider theme={this.props.user.darkModeEnabled ? darkTheme : lightTheme}>
                <GlobalStyles />
                <Router history={history}>
                    <Suspense fallback={<LoadingSpinner spinning={true} size={'large'} text={'Please wait, loading...'} textWhite={true} />}>
                        <Switch>
                            <PrivateRoute
                                path="/" exact user={this.props.user}
                                renderPage={(routeProps) => <Home user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/profile" exact user={this.props.user}
                                renderPage={(routeProps) => <MyProfile user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/bronzium" exact user={this.props.user}
                                renderPage={(routeProps) => <BronziumPage user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/salvage" exact user={this.props.user}
                                renderPage={(routeProps) => <Salvage user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />


                            <PrivateRoute
                                path="/player/strangetracker" exact user={this.props.user}
                                renderPage={(routeProps) => <StrangesGearTracker user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/datacrons" exact user={this.props.user}
                                renderPage={(routeProps) => <Datacrons user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/squads/:templateId?" exact user={this.props.user}
                                renderPage={(routeProps) => <GuildSquadsPage user={this.props.user} gameData={this.props.gameData} templateId={routeProps.match.params.templateId === undefined ? undefined : Number(routeProps.match.params.templateId)} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/contentcreators/:type?/" user={this.props.user}
                                renderPage={(routeProps) => <OverviewPage user={this.props.user} type={routeProps.match.params.type} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/events" user={this.props.user}
                                renderPage={(routeProps) => <PlayerEvents user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/loadouts" user={this.props.user}
                                renderPage={(routeProps) => <ModLoadouts user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/management" user={this.props.user}
                                renderPage={(routeProps) => <UnequippedMods user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!}  {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/optimizer" user={this.props.user}
                                renderPage={(routeProps) => <ModOptimizer user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/playground/ally/:allyCode" user={this.props.user}
                                renderPage={(routeProps) => <PlaygroundBeta user={this.props.user} allyCode={routeProps.match.params.allyCode} loadoutName={routeProps.match.params.loadout} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/playground/guildloadout/:loadout" user={this.props.user}
                                renderPage={(routeProps) => <PlaygroundBeta user={this.props.user} guildLoadoutName={routeProps.match.params.loadout} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/playground/loadout/:loadout" user={this.props.user}
                                renderPage={(routeProps) => <PlaygroundBeta user={this.props.user} loadoutName={routeProps.match.params.loadout} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/playground" user={this.props.user}
                                renderPage={(routeProps) => <PlaygroundBeta user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/analysis" user={this.props.user}
                                renderPage={(routeProps) => <ModAnalysis user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/maintenance" user={this.props.user}
                                renderPage={(routeProps) => <ModMaintenance user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/depth/stats/:set/:slot/:primary" user={this.props.user}
                                renderPage={(routeProps) => <ModDepth user={this.props.user}
                                    set={routeProps.match.params.set} slot={routeProps.match.params.slot} primary={routeProps.match.params.primary}
                                    gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/depth/stats/:set/:slot" user={this.props.user}
                                renderPage={(routeProps) => <ModDepth user={this.props.user}
                                    set={routeProps.match.params.set} slot={routeProps.match.params.slot}
                                    gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/depth/stats/:set" user={this.props.user}
                                renderPage={(routeProps) => <ModDepth user={this.props.user}
                                    set={routeProps.match.params.set}
                                    gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/depth" user={this.props.user}
                                renderPage={(routeProps) => <ModDepth user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/analysis" user={this.props.user}
                                renderPage={(routeProps) => <ModAnalysis user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/analysis" user={this.props.user}
                                renderPage={(routeProps) => <ModAnalysis user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/swap" user={this.props.user}
                                renderPage={(routeProps) => <ModSwap user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/wizard" user={this.props.user}
                                renderPage={(routeProps) => <ModWizard user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/gac/compare" user={this.props.user}
                                renderPage={(routeProps) => <GacCompare user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/gac/history" user={this.props.user}
                                renderPage={(routeProps) => <GacHistory user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/gac/current" user={this.props.user}
                                renderPage={(routeProps) => <GacView user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/gac/planning" user={this.props.user}
                                renderPage={(routeProps) => <GacPlanning user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/gac/view/:instance/:match" user={this.props.user}
                                renderPage={(routeProps) => <GacView instanceId={routeProps.match.params.instance}
                                    matchId={routeProps.match.params.match}
                                    user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tb/planning/:tb?/:templateId?" user={this.props.user}
                                renderPage={(routeProps) => <TBPlanning user={this.props.user} tb={routeProps.match.params.tb} templateId={routeProps.match.params.templateId === undefined ? undefined : Number(routeProps.match.params.templateId)} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tb/command/:tb?" user={this.props.user}
                                renderPage={(routeProps) => <CommandCenter user={this.props.user} tb={routeProps.match.params.tb} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tb/status" user={this.props.user}
                                renderPage={(routeProps) => <TBStatus user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tb/players" user={this.props.user}
                                renderPage={(routeProps) => <TBPlayers user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tb/data" user={this.props.user}
                                renderPage={(routeProps) => <TBData user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tb/strategy" user={this.props.user}
                                renderPage={(routeProps) => <TBStrategy user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/current" user={this.props.user}
                                renderPage={(routeProps) => <Overview user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/view/:instance" user={this.props.user}
                                renderPage={(routeProps) => <Overview instanceId={routeProps.match.params.instance} user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/history" user={this.props.user}
                                renderPage={(routeProps) => <History user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/scoreboard/:guildDbId?" user={this.props.user}
                                renderPage={(routeProps) => <TWScoreboard user={this.props.user} guildDbId={routeProps.match.params.guildDbId} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/search" exact user={this.props.user}
                                renderPage={(routeProps) => <GuildSearch isScouting={true} user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/command" user={this.props.user}
                                renderPage={(routeProps) => <TWCommandCenter user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/planning/:templateId?" user={this.props.user}
                                renderPage={(routeProps) => <TWPlanning user={this.props.user} templateId={routeProps.match.params.templateId === undefined ? undefined : routeProps.match.params.templateId} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/tw/reporting" exact user={this.props.user}
                                renderPage={(routeProps) => <TWReporting user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/template/editor" user={this.props.user}
                                renderPage={(routeProps) => <TemplateEditor user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/template/squadcompliance" user={this.props.user}
                                renderPage={(routeProps) => <SquadComplianceEditor user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/template/execute/:templateId?/:categoryId?" user={this.props.user}
                                renderPage={(routeProps) => <TemplateExecute templateId={routeProps.match.params.templateId} categoryKey={routeProps.match.params.categoryId} user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/members/:allyCode?" user={this.props.user}
                                renderPage={(routeProps) => <GuildMembers user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} allyCode={routeProps.match.params.allyCode && Number(routeProps.match.params.allyCode)} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/tickets" exact user={this.props.user}
                                renderPage={(routeProps) => <GuildTickets user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/units" exact user={this.props.user}
                                renderPage={(routeProps) => <GuildUnitsPage user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/units/:unitId/:allyCode?" user={this.props.user}
                                renderPage={(routeProps) => <UnitPage user={this.props.user} gameData={this.props.gameData} unitId={routeProps.match.params.unitId} cookies={this.props.cookies!} allyCode={routeProps.match.params.allyCode && Number(routeProps.match.params.allyCode)} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/search" exact user={this.props.user}
                                renderPage={(routeProps) => <GuildSearch isScouting={false} user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/compare/units/:allyCode/:unitId" user={this.props.user}
                                renderPage={(routeProps) => <CompareUnits user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} allyCode={Number(routeProps.match.params.allyCode)} unitId={routeProps.match.params.unitId} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/compare/:type/:allyCode/:templateId?" exact user={this.props.user}
                                renderPage={(routeProps) => <Compare user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} type={routeProps.match.params.type} allyCode={Number(routeProps.match.params.allyCode)} templateId={routeProps.match.params.templateId === undefined ? undefined : Number(routeProps.match.params.templateId)} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/events" user={this.props.user}
                                renderPage={(routeProps) => <GuildEvents user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/violations" user={this.props.user}
                                renderPage={(routeProps) => <Violations user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/watch" user={this.props.user}
                                renderPage={(routeProps) => <GuildWatchSettings user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/alliances" user={this.props.user}
                                renderPage={(routeProps) => <GuildAlliances user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/farming" user={this.props.user}
                                renderPage={(routeProps) => <GuildFarming user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/guild/glProgress" user={this.props.user}
                                renderPage={(routeProps) => <GuildGLProgress user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/farming/:allyCode" user={this.props.user}
                                renderPage={(routeProps) => <PersonalFarming allyCode={routeProps.match.params.allyCode} user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/player/profile/:allyCode" user={this.props.user}
                                renderPage={(routeProps) => <GuildPlayerProfilePage user={this.props.user} gameData={this.props.gameData} allyCode={Number(routeProps.match.params.allyCode)} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/conquest/data_disks" user={this.props.user}
                                renderPage={(routeProps) => <DataDiskManagement user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/conquest/status" user={this.props.user}
                                renderPage={(routeProps) => <ConquestStatus user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/mods/services" user={this.props.user}
                                renderPage={(routeProps) => <ModServices user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/settings/share" user={this.props.user}
                                renderPage={(routeProps) => <ShareSettings user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <PrivateRoute
                                path="/developer" user={this.props.user}
                                renderPage={(routeProps) => <Developer user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <Route
                                path="/callback"
                                render={(routeProps) => <Callback user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />
                            <Route
                                path="/googlecallback"
                                render={(routeProps) => <GoogleCallback user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />
                            <Route
                                path="/eaconnect"
                                render={(routeProps) => <EAConnect user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                            <Route
                                path="/accountselect"
                                render={(routeProps) => <AccountSelectionPage user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />
                            <Route
                                path="/login" render={(routeProps) => <LoginPage user={this.props.user} cookies={this.props.cookies!} />} />

                            <Route
                                path="*" render={(routeProps) => <NotFoundPage user={this.props.user} gameData={this.props.gameData} cookies={this.props.cookies!} {...routeProps} />} />

                        </Switch>
                    </Suspense>
                </Router>
            </ThemeProvider>
        );
    }


}

export default withCookies(App);
