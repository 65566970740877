


import { runInAction } from 'mobx';
import UserData from './../../../model/UserData';
import BaseAPI from './../../../service/BaseAPI';
import { PlaygroundSaveSettings } from './PlaygroundFileWorkspace/PlaygroundSaveWorkspace';
import { PlaygroundUnitSettings } from './PlaygroundData';
import { Modal } from 'antd';
import { Spin } from 'antd';
import { LoadoutDefinitionTarget, LoadoutDefintionGroup, LoadoutDefintionSummary } from '../../../model/LoadoutDefinition';
import ConfigurableSetting from './../../../model/ConfigurableSetting';
import { LoadoutDefinition } from './../../../model/LoadoutDefinition';
import ModAPI from '../../../service/ModAPI';
import PlayerData from '../../../model/PlayerData';
import { APILoadout } from '../../../model/ModLoadoutData';
import { IShare } from '../../../model/IShare';
import { waitForTask } from '../../../ui/waitForTask';
import GameData from '../../../model/GameData';

export class LoadoutDefinitionDetails
{
    summary: LoadoutDefintionSummary;
    units: string[] = [];

    constructor(summary: LoadoutDefintionSummary)
    {
        this.summary = summary;
    }

    addUnit(unitId: string)
    {
        this.units.push(unitId);
    }

    hasUnit(unitId: string): boolean
    {
        return this.units.find(id => unitId === id) !== undefined;
    }
}

export class PlaygroundController
{

    static DEFAULT_PRIORITIES_KEY = "playground_unit_priorities";
    static HOUTILS_TARGETS_DEFAULT_KEY = "hotutils-unit-stat-weight-defaults";
    static PLAYER_TARGETS_DEFAULT_KEY = "player-unit-stat-weight-defaults";
    static PLAYER_SHARE_DEFAULT_KEY = "player-share-defaults";
    static G_TARGETS_DEFAULT_KEY = "unit-stat-weight-defaults"; // out of date

    static async onSetDefaultPriorities(user: UserData, priorityOrder: string[]): Promise<void>
    {

        let modal = Modal.info({
            maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } },
            title: "Please wait, saving default prioritization order", content: <Spin size="large" />
        });
        try
        {
            BaseAPI.setPlayerSetting(user, PlaygroundController.DEFAULT_PRIORITIES_KEY,
                JSON.stringify(priorityOrder), true);
        }
        catch (response)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
    }


    static async saveDefaultUserTargets(user: UserData, targets: LoadoutDefinitionTarget[]): Promise<void>
    {

        await BaseAPI.setPlayerSetting(user, PlaygroundController.PLAYER_TARGETS_DEFAULT_KEY, JSON.stringify(targets), true);

    }

    static async saveDefaultUserShare(user: UserData, share: IShare): Promise<void>
    {
        await BaseAPI.setPlayerSetting(user, PlaygroundController.PLAYER_SHARE_DEFAULT_KEY, JSON.stringify(share), true);
    }

    static async fetchDefaultUserShare(user: UserData, share: IShare): Promise<IShare | undefined>
    {
        let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user, PlaygroundController.PLAYER_SHARE_DEFAULT_KEY);

        if (settings.length > 0)
        {
            return JSON.parse(settings[0].value);
        }
        return undefined;
    }

    static async getDefaultUserTargets(user: UserData): Promise<LoadoutDefinitionTarget[]>
    {

        let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user, PlaygroundController.PLAYER_TARGETS_DEFAULT_KEY);

        if (settings.length > 0)
        {
            return JSON.parse(settings[0].value).map((j: any) => new LoadoutDefinitionTarget(j));
        }
        return [];
    }

    public static async getDefaultPriorities(user: UserData): Promise<string[] | undefined>
    {
        let retVal: string[] | undefined | null = undefined;

        let settings: ConfigurableSetting[] = await BaseAPI.getPlayerSetting(user, PlaygroundController.DEFAULT_PRIORITIES_KEY);

        if (settings.length > 0)
        {
            retVal = JSON.parse(settings[0].value);
            retVal = retVal === null ? undefined : retVal;
        }

        return retVal;
    }

    public static async onApplyMods(user: UserData, gameData: GameData, units: any[])
    {
        if (units.length > 5)
        {
            try
            {
                let task = await ModAPI.applyModsTask(user, units, () => { }, (response: any) =>
                {
                    if (task.responseCode !== 1)
                    {
                        Modal.error({ title: "Error applying loadout", content: <span>{task.errorMessage}</span>, maskClosable: false });
                    }
                });
                await waitForTask(task, "Please wait, applying loadout");
            }
            catch (err)
            {
                Modal.error({ title: "Error applying loadout", content: <span>{err.errorMessage}</span>, maskClosable: false });
            }
            try
            {
                await BaseAPI.fetchAllCurrentPlayerData(user, gameData, true);
            }
            catch (response)
            {
                console.error(response);
                let errorMessage = response.errorMessage !== undefined ? response.errorMessage : response.message !== undefined ? response.message : response;
                Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                return;
            }
        }
        else
        {
            let modal = Modal.info({
                maskClosable: false,
                okButtonProps: { disabled: true, style: { display: 'none' } },
                title: "Please wait, applying loadout",
                content: <Spin size="large" />
            });

            try
            {
                await ModAPI.applyMods(user, units);
            }
            catch (response)
            {
                console.error(response);
                let errorMessage = response.errorMessage !== undefined ? response.errorMessage : response.message !== undefined ? response.message : response;
                modal.update({ content: "Error applying loadout: " + errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
                return;
            }
            try
            {
                await BaseAPI.fetchAllCurrentPlayerData(user, gameData, true);
            }
            catch (response)
            {
                console.error(response);
                let errorMessage = response.errorMessage !== undefined ? response.errorMessage : response.message !== undefined ? response.message : response;
                Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
                return;
            }
            modal.destroy();
        }
    }


    static async onLoadAlly(user: UserData, allyCode: number): Promise<PlayerData | undefined>
    {
        let retVal: PlayerData | undefined = undefined;

        let modal = Modal.info({
            maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } },
            title: "Please wait, fetching player data", content: <Spin size="large" />
        });
        try
        {
            let newPlayerResponse = await BaseAPI.fetchAllyPlayerData(user, [allyCode]);
            let newPlayer: PlayerData = new PlayerData();
            newPlayer.allyCode = newPlayerResponse[0].summary.allyCode.toString();
            newPlayer.fromAllyJSON(newPlayerResponse[0], false);

            let existingLoadouts = await APILoadout.allyLoadouts(user, allyCode.toString(),
                APILoadout.PLAYER_SOURCE);
            newPlayer.loadoutsFromJSON(existingLoadouts, false);

            retVal = newPlayer;
        }
        catch (response)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
        return retVal;
    }

    static async exportLoadoutDefinition(user: UserData, title: string, description: string, categories: string[], targets: LoadoutDefinitionTarget[], youTubeVideoId: string | null,
        sharing: IShare): Promise<void>
    {
        let modal = Modal.info({
            maskClosable: false,
            okButtonProps: { disabled: true, style: { display: 'none' } },
            title: "Please wait, exporting definition", content: <Spin size="large" />
        })

        try
        {
            let ld = new LoadoutDefinition({
                title: title,
                description: description,
                youTubeVideoId: youTubeVideoId,
                categories: categories
            });
            ld.targets = targets;
            await ModAPI.saveLoadoutDefinition(user, ld, sharing);
        }
        catch (response)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
    }

    static async save(user: UserData, gameData: GameData, pss: PlaygroundSaveSettings, unitSettings: Map<string, PlaygroundUnitSettings>,
        selectedUnits: string[], loadoutDefinition: LoadoutDefinition | null, targets: LoadoutDefinitionTarget[] | null, pushToGame: boolean): Promise<void>
    {

        let apiDto: any = {
            name: pss.loadoutName,
            category: pss.loadoutCategory,
            description: pss.loadoutDescription,
            author: user.currentPlayer!.discordTag
        }

        let includedUnits = selectedUnits.filter(uid => unitSettings.has(uid) && unitSettings.get(uid)!.playerCharacterData.level >= 50 &&
            (pss.includeModlessUnits || unitSettings.get(uid)!.visibleConfirmedMods.length > 0));

        let unitsSave = includedUnits.map(uid =>
        {
            let us = unitSettings.get(uid)!;

            return {
                id: us.playerCharacterData.id,
                modIds: us.visibleConfirmedMods.map(ma => ma.mod.id)
            }
        });
        apiDto.units = unitsSave;

        let lockedUnitList = includedUnits.filter(u => unitSettings.get(u)!.locked);
        apiDto.lockedUnits = pss.saveLockedUnits === false || lockedUnitList.length === 0 ? null : lockedUnitList.join(",");


        let modal = Modal.info({
            maskClosable: false,
            okButtonProps: { disabled: true, style: { display: 'none' } },
            title: "Please wait, creating loadout", content: <Spin size="large" />
        })

        try
        {
            let ld = loadoutDefinition;
            if (ld !== undefined && pss.saveLoadoutDefinition)
            {
                apiDto.statDefinition = new LoadoutDefinition(ld);
                runInAction(() => apiDto.statDefinition.targets = targets);
            }

            await BaseAPI.addLoadout(user, apiDto, pss.includeModlessUnits);
            let loadoutsResponse = await BaseAPI.postToApi("mods/set/get", { sessionId: user.sessionId, name: "", test: true });
            runInAction(() => user.currentPlayer!.loadoutsFromJSON(loadoutsResponse.sets, false));

            let newLoadout = user.currentPlayer!.modLoadouts.find(ml => ml.name === pss.loadoutName);
            if (newLoadout === undefined)
            {
                throw new Error("Unexpected error, unable to find new loadout: " + pss.loadoutName);
            }
            if (pushToGame && newLoadout.testResult !== null)
            {
                if (newLoadout.testResult.canEquip)
                {
                    await PlaygroundController.pushLoadoutToGame(user, newLoadout.name, gameData);

                } else
                {
                    modal.update({ content: "Unable to push to game: " + newLoadout.testResult.error, okButtonProps: { disabled: false, style: { display: 'block' } } });
                    return;
                }
            }
        }
        catch (response)
        {
            modal.update({ content: "Error: " + response.errorMessage, okButtonProps: { disabled: false, style: { display: 'block' } } });
            return;
        }
        modal.destroy();
    }


    static async pushLoadoutToGame(user: UserData, name: string, gameData: GameData)
    {

        try
        {
            let task = await ModAPI.applyLoadoutTask(user, name, () => { }, (response: any) =>
            {
                if (task.responseCode !== 1)
                {
                    Modal.error({ title: "Error applying loadout", content: <span>{task.errorMessage}</span>, maskClosable: false });
                }
            });
            await waitForTask(task, "Please wait, applying loadout");
        }
        catch (err)
        {
            Modal.error({ title: "Error applying loadout", content: <span>{err.errorMessage}</span>, maskClosable: false });
        }
        try
        {
            await BaseAPI.fetchAllCurrentPlayerData(user, gameData, true);
        }
        catch (response)
        {
            console.error(response);
            let errorMessage = response.errorMessage !== undefined ? response.errorMessage : response.message !== undefined ? response.message : response;
            Modal.error({ title: "Error applying loadout", content: <span>{errorMessage}</span>, maskClosable: false });
            return;
        }

    }

    static async saveDefaultTargets(user: UserData, targets: LoadoutDefinitionTarget[]): Promise<void>
    {

        await BaseAPI.setSystemSetting(user, PlaygroundController.HOUTILS_TARGETS_DEFAULT_KEY, JSON.stringify(targets));

    }

    static async getDefaultTargets(user: UserData): Promise<LoadoutDefinitionTarget[]>
    {

        let settings: ConfigurableSetting[] = await BaseAPI.getSystemSetting(user, PlaygroundController.HOUTILS_TARGETS_DEFAULT_KEY);

        if (settings.length > 0)
        {
            return JSON.parse(settings[0].value).map((j: any) => new LoadoutDefinitionTarget(j));
        }
        throw new Error("Unexpected error, no default settings");
    }

    static async loadDefinitions(user: UserData)
    {
        let modal = Modal.info({
            maskClosable: false, okButtonProps: { disabled: true, style: { display: 'none' } },
            title: "Please wait, fetching loadout definitions", content: <Spin size="large" />
        });
        await ModAPI.fetchLoadoutDefinitionForSquadList(user, (loadoutName) =>
        {
            modal.update({ content: "Loading " + loadoutName, okButtonProps: { disabled: true, style: { display: 'block' } } });
        });
        modal.destroy();
    }

    static async getLoadoutDefinitionUnitMap(user: UserData): Promise<Map<string, LoadoutDefinitionDetails>>
    {
        let retVal: Map<string, LoadoutDefinitionDetails> = new Map();

        if (PlaygroundController.missingDefinitionData(user))
        {
            await PlaygroundController.loadDefinitions(user);
        }
        let loadoutDefinitionSummaries: LoadoutDefintionSummary[] = LoadoutDefintionGroup.getLoadoutDefinitions(user.currentPlayer!.loadoutDefinitionGroups);
        let loadoutDefinitionMap: Map<string, LoadoutDefinition> = new Map();
        user.currentPlayer!.loadedDefinitions.forEach(ld =>
        {
            loadoutDefinitionMap.set(ld.getKey(), ld);
        });

        loadoutDefinitionSummaries.forEach(lds =>
        {
            const key = lds.getKey();
            retVal.set(key, new LoadoutDefinitionDetails(lds));

            if (lds.squadUnits !== null)
            {
                lds.squadUnits.forEach(su =>
                {
                    let unitId = LoadoutDefintionSummary.squadIdToUnitId(su);
                    retVal.get(key)!.addUnit(unitId);
                });
            } else if (loadoutDefinitionMap.has(key))
            {
                loadoutDefinitionMap.get(key)!.targets.forEach(u =>
                {
                    retVal.get(key)!.addUnit(u.unitBaseId);
                });
            }
        });
        return retVal;
    }

    static missingDefinitionData(user: UserData): boolean
    {
        if (user.currentPlayer!.loadoutDefinitionGroups === null)
        {
            return true;
        }
        let summaries = LoadoutDefintionGroup.getLoadoutDefinitions(user.currentPlayer!.loadoutDefinitionGroups);
        for (var x = 0; x < summaries.length; x++)
        {
            const lds = summaries[x];
            if (lds.squadUnits === null && user.currentPlayer!.loadedDefinitions.find(ld => lds.isLoadoutDefinition(ld)) === undefined)
            {
                return true;
            }
        }
        return false;
    }

}