import { IAvailableModCalculationResult } from "./AvailableMods";
import { ModData } from './../../../../model/ModData';
import StatIds from "../../../../model/StatIds";

export enum ModsSortEnum
{
    Speed = 1,
    Match,
    Offense,
    OffensePercent,
    OffenseFlat,
    Health,
    HealthPercent,
    HealthFlat,
    TotalHealth,
    EffectiveHealth,
    Protection,
    ProtectionPercent,
    ProtectionFlat,
    EffectiveProtection,
    EffectiveHealthProtection,
    Potency,
    Tenacity,
    CritChance,
    Defense,
    CritAvoidance,
    CritDamage,
    Accuracy
}

export function sortPlaygroundMods(sortKey: ModsSortEnum, modMap: Map<string, ModData>, m1: IAvailableModCalculationResult, m2: IAvailableModCalculationResult): number
{
    let retVal = 0;

    let mod1: ModData = modMap.get(m1.modId)!;
    let mod2: ModData = modMap.get(m2.modId)!;

    switch (sortKey)
    {
        case ModsSortEnum.Match:
            retVal = m2.strength - m1.strength;
            break;
        case ModsSortEnum.Speed:
            retVal = m2.equippedStats.speed - m1.equippedStats.speed;
            break;
        case ModsSortEnum.Offense:
            retVal = m2.equippedStats.physicalDamage - m1.equippedStats.physicalDamage;
            break;
        case ModsSortEnum.OffensePercent:
            retVal = getStatAmount(mod2, StatIds.OffensePct) - getStatAmount(mod1, StatIds.OffensePct);
            break;
        case ModsSortEnum.OffenseFlat:
            retVal = getStatAmount(mod2, StatIds.Offense) - getStatAmount(mod1, StatIds.Offense);
            break;
        case ModsSortEnum.Health:
            retVal = m2.equippedStats.health - m1.equippedStats.health;
            break;
        case ModsSortEnum.HealthPercent:
            retVal = getStatAmount(mod2, StatIds.HealthPct) - getStatAmount(mod1, StatIds.HealthPct);
            break;
        case ModsSortEnum.HealthFlat:
            retVal = getStatAmount(mod2, StatIds.Health) - getStatAmount(mod1, StatIds.Health);
            break;
        case ModsSortEnum.EffectiveHealth:
            retVal = m2.equippedStats.effectiveHealth - m1.equippedStats.effectiveHealth;
            break;
        case ModsSortEnum.EffectiveHealthProtection:
            retVal = m2.equippedStats.effectiveTotalHealth - m1.equippedStats.effectiveTotalHealth;
            break;
        case ModsSortEnum.TotalHealth:
            retVal = m2.equippedStats.totalHealth = m1.equippedStats.totalHealth;
            break;
        // case ModsSortEnum.EffectiveProtection:
        //     retVal = m2.equippedStats.effectiveTotalHealth - m1.equippedStats.effectiveProtection;
        //     break;
        case ModsSortEnum.Protection:
            retVal = m2.equippedStats.protection - m1.equippedStats.protection;
            break;
        case ModsSortEnum.ProtectionPercent:
            retVal = getStatAmount(mod2, StatIds.ProtectionPct) - getStatAmount(mod1, StatIds.ProtectionPct);
            break;
        case ModsSortEnum.ProtectionFlat:
            retVal = getStatAmount(mod2, StatIds.Protection) - getStatAmount(mod1, StatIds.Protection);
            break;
        case ModsSortEnum.Potency:
            retVal = m2.equippedStats.potency - m1.equippedStats.potency;
            break;
        case ModsSortEnum.Tenacity:
            retVal = m2.equippedStats.tenacity - m1.equippedStats.tenacity;
            break;
        case ModsSortEnum.CritChance:
            retVal = m2.equippedStats.criticalChance - m1.equippedStats.criticalChance;
            break;
        case ModsSortEnum.Defense:
            retVal = m2.equippedStats.armor - m1.equippedStats.armor;
            break;
        case ModsSortEnum.CritAvoidance:
            retVal = m2.equippedStats.criticalAvoidance - m1.equippedStats.criticalAvoidance;
            break;
        case ModsSortEnum.CritDamage:
            retVal = m2.equippedStats.criticalDamage - m1.equippedStats.criticalDamage;
            break;
    }
    if (retVal === 0)
    {
        retVal = m2.strength - m1.strength;
    }
    return retVal;
}

function getStatAmount(mod: ModData, statId: StatIds): number
{
    if (mod.primary.statId === statId)
    {
        return mod.primary.statValueUnscaled;
    }
    let secondary = mod.secondaries.find(secondary => secondary.statId === statId);
    if (secondary !== undefined)
    {
        return secondary.statValueUnscaled;
    }
    return 0;
}
