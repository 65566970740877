import React from 'react';
import {
    Alert,
    Avatar,
    Button,
    Divider,
    Dropdown,
    Layout,
    Menu,
    Modal,
    Spin,
    Switch,
    Typography
} from 'antd';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import {observer} from 'mobx-react';
import {observable, action, runInAction} from 'mobx';
import GlobalMenu from '../components/GlobalMenu/GlobalMenu';
import UserData from '../model/UserData';
import i18next from "i18next";
import {Link} from 'react-router-dom';
import {Cookies} from 'react-cookie';
import BaseAPI from '../service/BaseAPI';
import GameData from '../model/GameData';
import styles from "./styles/BasicLayout.module.scss";
import moment from 'moment';
import {generateGoogleLink} from '../pages/LoginFlow/LoginPage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
    faJournalWhills,
    faSignOut, faSync, faUser,
    faUsers, faMoon
} from '@fortawesome/pro-duotone-svg-icons';
import {faChevronDown} from "@fortawesome/pro-solid-svg-icons";
import {faJediOrder} from "@fortawesome/free-brands-svg-icons";
import LoadingSpinner from "../components/Loading/LoadingSpinner";
import PlayerData from "../model/PlayerData";

const {Header, Sider, Content} = Layout;

interface IProps {
    children: React.ReactNode;
    cookies: Cookies;
    // any other props that come into the component
    user?: UserData;
    gameData?: GameData;
    requiredApiRights: string[];
    extraRefresh?: () => Promise<void>;
    requiresGameConnection?: boolean;
    warnOnStaleData?: boolean;
}

const TABLET_WIDTH = 1026;

@observer
export class BasicLayout extends React.Component<IProps> {
    @observable collapsed: boolean = false;
    @observable condensed: boolean = false;
    @observable reloading: boolean = false;

    @action
    toggle() {
        this.collapsed = !this.collapsed;
    };


    @action
    componentDidMount() {
        window.addEventListener("resize", () => this.updateScreenSize());
        this.updateScreenSize();
        if (this.condensed) {
            this.collapsed = true;
        }
    }

    @action
    updateScreenSize() {
        this.condensed = window.innerWidth < TABLET_WIDTH;
    }

    private checkApiRights(): boolean {
        return this.checkApiRightsList(this.props.requiredApiRights);
    }

    private checkApiRightsList(requiredApiRights: string[] | undefined) {
        if (!this.props.user || !this.props.user.apiRights)
            return false;

        return this.props.user.checkApiRights(requiredApiRights);
    }

    render() {
        const siderClassName = (this.condensed ? (this.collapsed ? styles.mobile : styles.mobileExpanded) : '');
        const menuFold = this.collapsed ?
            <MenuUnfoldOutlined className={`${styles.trigger} ${this.condensed ? styles.menuMobile : ''}`}
                                onClick={() => this.toggle()}/> :
            <MenuFoldOutlined className={`${styles.trigger} ${this.condensed ? styles.menuMobile : ''}`}
                              onClick={() => this.toggle()}/>
        let hasRights = this.checkApiRights();
        let hasConnection = this.props.user && this.props.user.currentPlayer && this.props.user.currentPlayer.hasConnection;
        let needsConnection = this.props.requiresGameConnection && !hasConnection;
        return (
            <Layout className={`${this.collapsed ? styles.collapsed : styles.expanded} ${styles.layout}`}>
                {this.condensed && menuFold}
                <Sider trigger={null} collapsible theme="light" collapsed={this.collapsed}
                       className={`${styles.aside} ${siderClassName}`}>
                    <React.Fragment>
                        <div className={styles.iconLogo}>
                            <img src={"/hotsauceheader.png"} alt={"Hot Sauce"} title={'Hot Sauce'}/>
                        </div>
                        <GlobalMenu user={this.props.user} showEventsLink={this.checkApiRightsList(['SWGOH_EVENTS'])}
                                    isCollapsed={this.collapsed} isMobile={this.condensed}/>
                    </React.Fragment>
                </Sider>


                <Layout className={`${styles.main} ${siderClassName}`}>
                    <Header className={`${styles.header} ${this.condensed ? styles.mobileHeader : ''}`}>
                        <div className={styles.headerContainer}>
                            {!this.condensed && menuFold}
                            {this.renderUserBanner()}
                        </div>
                    </Header>
                    <Content className={styles.body}>
                        <Alert message="WARNING: This is a development version of the site. DO NOT USE THIS!" type='error'/>
                        {this.renderStaleDataWarning()}
                        {hasRights ? (needsConnection ? this.renderNeedsGameConnection() : this.props.children) : this.renderNoPermissions()}
                    </Content>
                </Layout>
            </Layout>
        );
    }

    private renderStaleDataWarning(): React.ReactNode {
        if (!this.props.warnOnStaleData || !this.props.user || !this.props.user.currentPlayer || !this.props.user.hasGameConnection || this.reloading)
            return null;

        if ((!this.props.user.currentPlayer.connected || this.props.user.currentPlayer.gameDataAgeUtc === null) && !this.props.user.readOnly) {
            return <Alert message={<span>
                <FontAwesomeIcon icon={faExclamationTriangle} className={`${styles.icon} ${styles.error}`}/>
                You have not updated your game data since login, <span className={styles.link}
                                                                       onClick={() => this.reload()}>click here to update</span>
                </span>} type="error" className={styles.staleWarning}/>;
        }

        let t = moment.utc(this.props.user.currentPlayer.gameDataAgeUtc);
        if (t.diff(moment(), "minutes") < -30) {
            if (this.props.user.readOnly) {
                return <Alert message={<span>
                    <FontAwesomeIcon icon={faExclamationTriangle} className={`${styles.icon} ${styles.error}`}/>
                    The game data you are viewing has not been updated in {t.fromNow(true)}, ask {this.props.user.currentPlayer.name} to refresh then <span
                    className={styles.link} onClick={() => this.reload()}>click here to update</span>
                    </span>} type="error" className={styles.staleWarning}/>
            } else {
                return <Alert message={<span>
                    <FontAwesomeIcon icon={faExclamationTriangle} className={`${styles.icon} ${styles.error}`}/>
                    You have not updated your game data in {t.fromNow(true)}, <span className={styles.link}
                                                                                    onClick={() => this.reload()}>click here to update</span>
                    </span>} type="error" className={styles.staleWarning}/>
            }
        }

        return null;
    }

    private renderNeedsGameConnection(): React.ReactNode {
        return <div>
            <h2>This feature requires a game connection</h2>
            <Typography>
                To set up a game connection, if your SWGOH account is linked to a google account, try <a
                href={generateGoogleLink()}>
                <Button type="primary" value="large">{i18next.t("common:login.login_with_google")}</Button>
            </a><br/>
                If that doesn't work, or you don't have a linked google account, reach out to the support team on <a
                href="https://discord.me/hotutils">Discord</a>
            </Typography>
        </div>;
    }

    private renderNoPermissions(): React.ReactNode {
        let requiredPatreonLevel: string | null = null;
        
        if (this.props.requiredApiRights && this.props.requiredApiRights.includes("ADMIN") && this.props.user && !this.props.user.isAdmin)
        {
            return <div><h2>This feature requires admin permisions, and you are not an admin or officer of your guild<br/>
                Ask a guild leader or officer to log into the site and go to Guild - Overview and mark you as an Admin</h2></div>;
        }

        if (this.props.requiredApiRights && this.props.requiredApiRights.length === 1) {
            switch (this.props.requiredApiRights[0]) {
                case "GAC_COMPARES":
                    requiredPatreonLevel = "Cherry";
                    break;
                case "MODS_GRAND_IVORY":
                case "MODS_ANALYSIS":
                case "MODS_LOADOUTS":
                case "MODS_PLAYGROUND":
                case "MODS_UNEQUIPPED":
                case "GAC_HISTORY":
                    requiredPatreonLevel = "Chile";
                    break;
                case "TERRITORY_BATTLES":
                case "TERRITORY_WARS":
                    requiredPatreonLevel = "Habanero";
                    break;
            }
        }
        if (requiredPatreonLevel !== null) {
            if (this.props.user) {
                if (this.props.user.shadow && this.props.requiredApiRights && this.props.requiredApiRights.length === 1 && this.props.requiredApiRights[0] === "TERRITORY_WARS") {
                    return <div><h2>Access to TW is not allowed from shared logins</h2></div>
                } else {
                    return <div><h2>This feature requires a {requiredPatreonLevel} Patreon level. <br/>
                        Your Patreon level is currently {this.props.user.patreonLevelString}. If this is not correct,
                        please run /identify in discord in a bot channel.<br/>
                        {requiredPatreonLevel === "Habanero" && <>If you have 4 or more guild members at Chile or above,
                            contact Hotsauce to upgrade your guild to Habanero status<br/></>}
                        If your Patreon payment failed to automatically renew, please try disconnecting discord from
                        Patreon and reconnecting it, then running /identify<br/>
                        Visit <a href="https://www.patreon.com/hotutils/membership">Patreon</a> to upgrade</h2></div>
                }
            } else {
                return <div><h2>This feature requires a {requiredPatreonLevel} Patreon level.<br/>
                    Visit <a href="https://www.patreon.com/hotutils/membership">Patreon</a> to upgrade</h2></div>
            }
        } else {
            return <div><h2>This feature requires a higher Patreon level than you currently are subscribed to.<br/>
                Visit <a href="https://www.patreon.com/hotutils/membership">Patreon</a> to upgrade</h2></div>
        }
    }

    private creditsDisplayValue(currency: number): string {
        if (currency >= 1000000000) {
            return (currency / 1000000000).toFixed(1) + "B";
        } else if (currency >= 1000000) {
            return (currency / 1000000).toFixed(1) + "M";
        } else {
            return (currency / 1000).toFixed(0) + "K";
        }
    }

    private getAccountMenu(player: PlayerData) {

        return (
            <Menu className={styles.menu}>
                <Menu.Item key={1} className={styles['item-btn']}>
                    <Link to={`/player/profile`}>
                        <FontAwesomeIcon icon={faUser} title={i18next.t("common:header.account_profile")}
                                         className={styles.icon}/>
                        <span>{i18next.t("common:header.account_profile")}</span>
                    </Link>
                </Menu.Item>
                <Menu.Item key={2} className={styles['item-btn']}>
                    <Link to={`/accountselect`} onClick={() => this.onChangeAccount()}>
                        <FontAwesomeIcon icon={faUsers} title={i18next.t("common:header.account_select")}
                                         className={styles.icon}/>
                        <span>{i18next.t("common:header.account_select")}</span>
                    </Link>
                </Menu.Item>
                {player.gameDataAgeUtc && <Menu.Divider/>}
                {player.gameDataAgeUtc && <Menu.Item key={3} className={styles.item}
                                                     title={`Unequipped mods: ${player.mods.filter(mod => mod.equippedCharacter == null).length}/500`}>
                    <FontAwesomeIcon icon={faJournalWhills} className={styles.icon}/>
                    Mods: {player.mods.filter(mod => mod.equippedCharacter == null).length + "/500"}
                </Menu.Item>}
                {player.gameDataAgeUtc && <Menu.Item key={4} className={styles.item}
                                                     title={`Credits: ${this.creditsDisplayValue(player.credits)}`}>
                    <img className={styles.image} src="https://api.hotutils.com/images/currency/currency_credits.png"
                         alt={'Credits'} title={'Credits'}/>
                    Credits: {this.creditsDisplayValue(player.credits)}
                </Menu.Item>}
                {player.gameDataAgeUtc && <Menu.Item key={5} className={styles.item}
                                                     title={`Credits: ${this.creditsDisplayValue(player.shipCredits)}`}>
                    <img className={styles.image} src="https://api.hotutils.com/images/currency/currency_ship.png"
                         alt={'Ship Credits'} title={'Ship Credits'}/>
                    Ship Credits: {this.creditsDisplayValue(player.shipCredits)}
                </Menu.Item>}
                {player.gameDataAgeUtc && <Menu.Item key={6} className={styles.item}
                                                     title={`Credits: ${this.creditsDisplayValue(player.crystals)}`}>
                    <img className={styles.image} src="https://api.hotutils.com/images/currency/currency_crystals.png"
                         alt={'Crystals'} title={'Crystals'}/>
                    Crystals: {this.creditsDisplayValue(player.crystals)}
                </Menu.Item>}
                <Menu.Divider/>
                <Menu.Item key={7}>
                    <div className={styles.dark_mode_container}>
                        <div>
                            <span>{i18next.t("common:header.dark_mode")}</span>
                            <FontAwesomeIcon icon={faMoon}/>
                        </div>
                        <Switch checked={this.props.user?.darkModeEnabled}
                                onChange={action((checked: boolean) => this.toggleTheme(checked))} />
                    </div>

                </Menu.Item>
                <Menu.Item key={8} className={`${styles.danger} ${styles['item-btn']}`}>
                    <Link to="/login" onClick={() => this.onLogout()} title={i18next.t("common:header.logout")}>
                            <FontAwesomeIcon icon={faSignOut} className={styles.icon}/>
                            <span>{i18next.t("common:header.logout")}</span>
                    </Link>
                </Menu.Item>
            </Menu>
        );
    }

    private renderUserBanner(): React.ReactNode {
        if (!this.props.user || !this.props.user.currentPlayer || !this.props.gameData)
            return <LoadingSpinner size={"small"} spinning={true}/>;

        return <div className={styles.userArea}>
                {/*Age account*/}
                <div className={styles.dataAge}>
                    {this.props.user.currentPlayer.gameDataAgeUtc ? (!this.condensed ? ("Data fetched " + moment.utc(this.props.user.currentPlayer.gameDataAgeUtc).fromNow()) : ("Upd " + moment.utc(this.props.user.currentPlayer.gameDataAgeUtc).fromNow())) : "No Game Data"}
                </div>
                {/*Refresh button*/}
                {this.props.user.currentPlayer.hasConnection && <div className={styles.refresh} onClick={() => this.reload()}>
                    <FontAwesomeIcon icon={faSync} spin={this.reloading} />
                </div>}

                {this.props.user.currentPlayer.gameDataAgeUtc && <Divider className={styles.divider} type="vertical" />}

                {/*Mods*/}
                {this.props.user.currentPlayer.gameDataAgeUtc && <div className={styles.mods} title={`Unequipped mods: ${this.props.user.currentPlayer.mods.filter(mod => mod.equippedCharacter == null).length}/500`}>
                    <div className={styles.image} />
                    {this.props.user.currentPlayer.mods.filter(mod => mod.equippedCharacter == null).length + "/500"}
                </div>}
                {/*Credits*/}
                {this.props.user.currentPlayer.gameDataAgeUtc && <div className={styles.credits}>
                    <img className={styles.image} src="https://api.hotutils.com/images/currency/currency_credits.png" alt={'Credits'} title={'Credits'} />
                    {this.props.user.currentPlayer.creditsDisplayValue}
                </div>}
                <Divider className={styles.divider} type="vertical" />
                <Dropdown overlay={this.getAccountMenu(this.props.user.currentPlayer)} trigger={['click']} className={styles.dropdown} arrow={true} placement="bottomCenter">
                    <Button type={"link"}>
                        {!this.condensed && <Avatar className={styles.avatar} size="large" >
                            <FontAwesomeIcon icon={faJediOrder} size={'2x'} className={styles.icon} />
                        </Avatar>}
                        <span className={styles.title}> {this.props.user.currentPlayer.name}</span>
                        <FontAwesomeIcon icon={faChevronDown} className={styles.arrow} />
                    </Button>
                </Dropdown>
            </div>;

    }

    private onChangeAccount() {
        if (this.props.cookies) {
            return BaseAPI.clearCache();
        }
    }

    private async toggleTheme(darkModeEnabled: boolean) {
        if (this.props.user) {
            runInAction(() => this.props.user!.darkModeEnabled = darkModeEnabled);
            // with more themes, convert to switch
            await BaseAPI.setPlayerSetting(this.props.user, 'theme', darkModeEnabled ? 'darkTheme' : 'lightTheme')
        }
    }

    private onLogout(): void {
        if (this.props.user)
            this.props.user.logout();
        if (!this.props.cookies)
            return;
        if (this.props.cookies.get("hotUtilsSession"))
            this.props.cookies.remove("hotUtilsSession");
        if (this.props.cookies.get("hotUtilsAllyCode"))
            this.props.cookies.remove("hotUtilsAllyCode");
    }

    private async reload(): Promise<void> {
        runInAction(() => this.reloading = true);
        let waitDlg = Modal.info({
            maskClosable: false,
            okButtonProps: {disabled: true, style: {display: 'none'}},
            title: "Please wait, refreshing data from game server",
            content: <Spin size="large"/>
        });
        try {
            await BaseAPI.fetchAllCurrentPlayerData(this.props.user!, this.props.gameData!, false, true);

            // null out TB data in case they loaded it and moved to another component then did refresh
            runInAction(() => {
                this.props.user!.currentPlayer!.templateGroups = null;
                this.props.user!.currentPlayer!.territoryBattleData = null;
                this.props.user!.currentPlayer!.tbHistorySelections = [];
            });
            if (this.props.extraRefresh !== undefined) {
                await this.props.extraRefresh();
            }
            runInAction(() => this.reloading = false);
            waitDlg.destroy();
        } catch (err: any) {
            waitDlg.destroy();
            Modal.error({
                title: i18next.t("common:ui.error_dlg_title"),
                content: <span>{err.errorMessage}</span>,
                maskClosable: false
            });
        }
    }
}
