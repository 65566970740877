import GameData from "../../../model/GameData";
import { IGuildData } from "../../../model/GuildData";
import TerritoryBattleData, { TerritoryBattlePlayer, TerritoryBattleZoneStatus } from "../../../model/TerritoryBattleData";
import { ConflictZoneDefinition, RosterUnit, StrikeZoneDefinition, TerritoryBattleDefinition } from "../../../model/TerritoryBattleGameData";
import UnitData from "../../../model/UnitData";
import { PlatoonRequirement } from "./calculator";


export class RoundState
{
    completedZones: string[] = [];

    constructor(inputRoundState: RoundState | null)
    {
        this.completedZones = inputRoundState === null ? [] : inputRoundState.completedZones.slice();
    }
}

export interface RoundStrategyOutput
{
    outputRoundState: RoundState;
    roundStrategy: RoundStrategy;
}

export class TBStrategyData
{
    rounds: RoundStrategy[] = [];

    constructor(json: any)
    {
        if (json.rounds)
        {
            this.rounds = json.rounds.map((r: any) => new RoundStrategy(r));
        }
    }


    calculate(tbDef: TerritoryBattleDefinition, guildData: TbGuildData, tbInstanceData: TbInstanceData)
    {
        var startTime = performance.now()

        this.rounds.forEach(round => round.calculate(tbDef, guildData, tbInstanceData));

        var endTime = performance.now()

        console.log(`TBStrategyData.calculate took ${endTime - startTime} milliseconds`)

    }

    deriveRoundStrategy(roundTarget: number, inputRoundState: RoundState, tbDef: TerritoryBattleDefinition): RoundStrategyOutput
    {
        const outputRoundState: RoundState = new RoundState(inputRoundState);

        const activeZones = tbDef.getActiveZones(inputRoundState.completedZones).map(z => z.zoneDefinition.zoneId);

        const existingRs = this.rounds.find(r =>
        {
            const matchesRound = roundTarget === r.round;
            const matchesZones = r.zoneStrategies.find(zone => activeZones.includes(zone.zoneId) === false) === undefined;

            return matchesRound && matchesZones;
        });

        const roundStrategy = existingRs === undefined ? this.createRoundStrategy(roundTarget, inputRoundState.completedZones, tbDef) : existingRs;

        roundStrategy.zoneStrategies.filter(zs => zs.starTarget !== undefined && zs.starTarget > 0).forEach(zs =>
        {
            outputRoundState.completedZones.push(zs.zoneId);
        })

        return {
            outputRoundState,
            roundStrategy
        }
    }

    createRoundStrategy(round: number, completedZones: string[], tbDef: TerritoryBattleDefinition): RoundStrategy
    {
        const zones = tbDef.getActiveZones(completedZones);
        const zoneStrategies = zones.map(tbz => new ZoneStrategy({ zoneId: tbz.zoneDefinition.zoneId }));
        return new RoundStrategy({ round, zoneStrategies: zoneStrategies });
    }
}

export enum EstimationType
{
    ROUND = 1,
    ZONE = 2,
    MISSION = 3
}

export class RoundStrategy
{
    round: number;
    zoneStrategies: ZoneStrategy[] = [];
    playerStrategies: PlayerStrategy[] = [];

    estimationType: EstimationType = EstimationType.ROUND;
    roundEstimate: number | undefined;

    calcs: {
        roundGpEstimate?: number;
        zoneEstimate?: number;
        missionsEstimate?: number;

        zoneGpEstimateMap?: Map<string, number>;

        memberCount?: number;
    } = {};

    isIncomplete(): boolean
    {
        return this.zoneStrategies.find(zs => zs.isIncomplete()) !== undefined;
    }

    constructor(json: any)
    {
        this.round = json.round;
        this.roundEstimate = json.roundEstimate;

        if (json.estimationType !== undefined)
        {
            this.estimationType = json.estimationType;
        }

        if (json.zoneStrategies)
        {
            this.zoneStrategies = json.zoneStrategies.map((z: any) => new ZoneStrategy(z));
        }
        if (json.playerStrategies)
        {
            this.playerStrategies = json.playerStrategies.map((z: any) => new PlayerStrategy(z));
        }
    }
    getMissionGpEstimateByZone(zoneId: string): number
    {
        if (this.estimationType === EstimationType.ROUND)
        {
            return this.calcs.zoneGpEstimateMap?.get(zoneId) || 0;
        } else
        {
            const zs = this.zoneStrategies.find(zs => zs.zoneId === zoneId);
            return zs?.getMissionGpEstimate(this.estimationType) || 0;
        }
    }

    getMissionGpEstimate(sandbag: boolean): number | undefined
    {
        let retVal = 0;
        this.zoneStrategies.filter(zs => zs.isSandbag() === false || sandbag).forEach(zs =>
        {
            if (this.calcs.zoneGpEstimateMap && this.calcs.zoneGpEstimateMap.has(zs.zoneId))
            {
                retVal = retVal + this.calcs.zoneGpEstimateMap!.get(zs.zoneId)!;
            }
        });
        return retVal;
    }

    calculate(tbDef: TerritoryBattleDefinition, guildData: TbGuildData, tbInstanceData: TbInstanceData)
    {
        this.calcs.memberCount = tbInstanceData.usingTbData() ? tbInstanceData.tbPlayers!.size : guildData.players.length;
        this.calcs.missionsEstimate = 0;
        this.calcs.zoneEstimate = 0;
        this.calcs.roundGpEstimate = 0;

        this.calcs.zoneGpEstimateMap = new Map();

        this.zoneStrategies.forEach(zs =>
        {
            this.calcs.zoneGpEstimateMap!.set(zs.zoneId, 0);

            zs.calculate(tbDef, guildData, tbInstanceData)
            const missionEstimate = (zs.calcs.missionsEstimate || 0);
            const zoneEstimate = (zs.calcs.zoneGpEstimate || 0);
            this.calcs.missionsEstimate! += missionEstimate;
            this.calcs.zoneEstimate! += zoneEstimate;

            if (this.estimationType === EstimationType.ZONE)
            {
                this.calcs.zoneGpEstimateMap!.set(zs.zoneId, zoneEstimate);
            }
            if (this.estimationType === EstimationType.MISSION)
            {
                this.calcs.zoneGpEstimateMap!.set(zs.zoneId, missionEstimate);
            }

            tbDef.getStrikeZones(zs.zoneId).forEach(sz =>
            {
                if (this.roundEstimate !== undefined && this.calcs.memberCount !== undefined)
                {
                    const missionEstimate = sz.getTotalPossibleMissionPoints() * (this.roundEstimate / 100) * this.calcs.memberCount;
                    this.calcs.roundGpEstimate! += missionEstimate;

                    if (this.estimationType === EstimationType.ROUND)
                    {
                        this.calcs.zoneGpEstimateMap!.set(zs.zoneId, this.calcs.zoneGpEstimateMap!.get(zs.zoneId)!
                            + missionEstimate);
                    }
                }
            })

        });
        this.calcs.roundGpEstimate = Math.round((this.calcs.roundGpEstimate! / 100)) * 100;
    }


    platoonFilled(conflictZoneId: string, platoonId: string): boolean
    {
        const previousZoneStrategy = this.zoneStrategies.find(zs => zs.zoneId === conflictZoneId);

        return (previousZoneStrategy !== undefined &&
            previousZoneStrategy.targetCompletePlatoons.includes(platoonId));
    }

    zoneIsSandbagged(): boolean
    {
        return this.zoneStrategies.find(zs => zs.isSandbag()) !== undefined;
    }

    getPlayerStrategy(allyCode: number, add: boolean = false): PlayerStrategy
    {
        const ps = this.playerStrategies.find(s => s.allyCode === allyCode);

        if (ps === undefined)
        {
            const newPs = new PlayerStrategy({ allyCode: allyCode });
            this.playerStrategies.push(newPs);
            return newPs;
        } else
        {
            return ps;
        }
    }

    equals(val: RoundStrategy)
    {
        const myZones = this.zoneStrategies.map(z => z.zoneId).sort((s1, s2) => s1.localeCompare(s2)).join(',');
        const valZones = val.zoneStrategies.map(z => z.zoneId).sort((s1, s2) => s1.localeCompare(s2)).join(',');
        return this.round === val.round && this.zoneStrategies.length === val.zoneStrategies.length && myZones === valZones;
    }
}

export class MissionEstimate
{
    strikeZoneId: string;
    missionEstimate: number | undefined;

    calcs: {
        missionGpEstimate?: number;
        szd?: StrikeZoneDefinition;
    } = {};

    constructor(json: any)
    {
        this.strikeZoneId = json.strikeZoneId;
        this.missionEstimate = json.missionEstimate;
    }

    getMissionGpEstimate(estimationType: EstimationType): number | undefined
    {
        switch (estimationType)
        {
            case EstimationType.ROUND:
                return undefined;
            case EstimationType.ZONE:
                return undefined;
            case EstimationType.MISSION:
                return this.calcs.missionGpEstimate;
        }
    }

    calculate(tbDef: TerritoryBattleDefinition, guildData: TbGuildData, tbInstanceData: TbInstanceData)
    {
        const guildMemberCount = tbInstanceData.usingTbData() ? tbInstanceData.tbPlayers!.size : guildData.players.length;

        const strikeZone = tbDef.getStrikeZone(this.strikeZoneId);

        if (strikeZone)
        {
            this.calcs.szd = strikeZone;
            this.calcs.missionGpEstimate = this.missionEstimate === undefined ? undefined :
                Math.round((guildMemberCount * strikeZone.getTotalPossibleMissionPoints() * (this.missionEstimate / 100) * 100)) / 100;
        }
    }
}

export class ZoneStrategy
{
    zoneId: string;
    starTarget: number = 0;
    targetCompletePlatoons: string[] = [];
    zoneEstimate: number | undefined;

    missionEstimates: MissionEstimate[] = []

    calcs: {
        conflictZone?: ConflictZoneDefinition;

        zoneGpEstimate?: number;
        missionsEstimate?: number;
    } = {};

    static LIGHT = 1;
    static DARK = 2;
    static MIXED = 3;

    static LIGHTSIDE = 2;
    static DARKSIDE = 3;
    static MIXEDSIDE = 1;

    isActualPlatoonTargetted(id: string): boolean
    {
        return this.targetCompletePlatoons.includes(ZoneStrategy.mapPlatoonId(id));
    }

    static mapPlatoonId(id: string): string
    {
        let actualId = '';
        switch (id)
        {
            case 'tb3-platoon-1':
                actualId = 'tb3-platoon-6';
                break;
            case 'tb3-platoon-2':
                actualId = 'tb3-platoon-5';
                break;
            case 'tb3-platoon-3':
                actualId = 'tb3-platoon-4';
                break;
            case 'tb3-platoon-4':
                actualId = 'tb3-platoon-3';
                break;
            case 'tb3-platoon-5':
                actualId = 'tb3-platoon-2';
                break;
            case 'tb3-platoon-6':
                actualId = 'tb3-platoon-1';
                break;
        }
        return actualId;
    }

    constructor(json: any)
    {
        this.zoneId = json.zoneId;
        this.zoneEstimate = json.zoneEstimate;
        this.starTarget = json.starTarget !== undefined ? json.starTarget : 0;
        if (json.targetCompletePlatoons)
            this.targetCompletePlatoons = json.targetCompletePlatoons;
        if (json.missionEstimates)
            this.missionEstimates = json.missionEstimates.map((me: any) => new MissionEstimate(me))
    }

    getMissionGpEstimate(estimationType: EstimationType): number | undefined
    {
        switch (estimationType)
        {
            case EstimationType.ROUND:
                return undefined;
            case EstimationType.ZONE:
                return this.calcs.zoneGpEstimate;
            case EstimationType.MISSION:
                return this.calcs.missionsEstimate;
        }
    }

    calculate(tbDef: TerritoryBattleDefinition, guildData: TbGuildData, tbInstanceData: TbInstanceData)
    {
        const guildMemberCount = tbInstanceData.usingTbData() ? tbInstanceData.tbPlayers!.size : guildData.players.length;

        this.calcs.conflictZone = tbDef.getZoneGameDefinition(this.zoneId);

        this.calcs.missionsEstimate = 0;
        this.missionEstimates.forEach(me =>
        {
            me.calculate(tbDef, guildData, tbInstanceData)
            this.calcs.missionsEstimate! += (me.calcs.missionGpEstimate || 0);
        });

        this.calcs.zoneGpEstimate = 0;
        if (this.zoneEstimate)
        {
            tbDef.getStrikeZones(this.zoneId).forEach(sz =>
            {
                this.calcs.zoneGpEstimate! += (sz.getTotalPossibleMissionPoints() * guildMemberCount * (this.zoneEstimate! / 100));
            });
        }
        this.calcs.zoneGpEstimate = Math.round((this.calcs.zoneGpEstimate / 100)) * 100;
    }

    getMissionEstimate(strikeZoneId: string): MissionEstimate | undefined
    {
        return this.missionEstimates.find(me => me.strikeZoneId === strikeZoneId);
    }

    setMissionEstimate(strikeZoneId: string, missionEstimate: number | undefined)
    {
        this.missionEstimates = this.missionEstimates.filter(me => me.strikeZoneId !== strikeZoneId);
        this.missionEstimates.push(new MissionEstimate({ strikeZoneId, missionEstimate }));
    }

    isSandbag(): boolean
    {
        return this.starTarget !== undefined && this.starTarget === 0;
    }

    isIncomplete(): boolean
    {
        return this.starTarget === undefined;
    }

    static getAlignment(id: string): string
    {
        return ZoneStrategy.getAlignmentById(ZoneStrategy.getArea(id));
    }

    static getAlignmentById(id: number): string
    {
        switch (id)
        {
            case ZoneStrategy.LIGHT:
                return 'Light';
            case ZoneStrategy.DARK:
                return 'Dark';
            case ZoneStrategy.MIXED:
                return 'Mixed';
            default:
                return 'Unknown';
        }
    }

    static getForceAlignmentById(id: number): string
    {
        switch (id)
        {
            case ZoneStrategy.LIGHTSIDE:
                return 'Light';
            case ZoneStrategy.DARKSIDE:
                return 'Dark';
            case ZoneStrategy.MIXEDSIDE:
                return 'Mixed';
            default:
                return 'Unknown';
        }
    }


    static getFactionById(id: number): string {
        switch (id) {
            case 1:
                return 'character';
            case 2:
                return 'ship';
            default:
                return 'Unknown';
        }
    }

    static getPhase(id: string): number
    {
        const parts = id.split('_');
        if (parts.length >= 3)
        {
            const lastCharacter = parts[2].charAt(parts[2].length - 1);
            const retVal = Number(lastCharacter);
            return isNaN(retVal) ? 1000 : retVal;
        }
        return 1000;
    }

    static getRelicLevelForZone(conflictZoneId: string): number
    {
        const phase = ZoneStrategy.getPhase(conflictZoneId);

        switch (phase)
        {
            case 1:
                return 5;
            case 2:
                return 6;
            case 3:
                return 7;
            case 4:
                return 8;
            case 5:
                return 9;
            case 6:
                return 9;
        }
        return 0;
    }

    getPlatoonRelicLevel()
    {
        return ZoneStrategy.getRelicLevelForZone(this.zoneId);
    }

    static sortZones(rsz1: ZoneStrategy, rsz2: ZoneStrategy): number
    {
        const z1Area = ZoneStrategy.getAreaSortOrder(rsz1.zoneId);
        const z2Area = ZoneStrategy.getAreaSortOrder(rsz2.zoneId);

        return z1Area - z2Area;
    }


    static getArea(id: string): number
    {
        const lastCharacter = id.charAt(id.length - 1);
        const retVal = Number(lastCharacter);
        return isNaN(retVal) ? 1000 : retVal;
    }

    static getAreaSortOrder(id: string): number
    {
        return ZoneStrategy.getAlignmentSortOrder(ZoneStrategy.getArea(id));
    }

    static getAlignmentSortOrder(alignment1: number): number
    {
        switch (alignment1)
        {
            case ZoneStrategy.LIGHT:
                return 3;
            case ZoneStrategy.DARK:
                return 1;
            case ZoneStrategy.MIXED:
                return 2;
            default:
                return 4;
        }
    }

}

export class PlayerStrategy
{
    allyCode: number;
    combatNodeStrategies: CombatNodeStrategy[] = [];
    platoonedUnits: string[] = [];

    getCombatNodeStrategy(zoneId: string, create: boolean = false): CombatNodeStrategy
    {
        const cns = this.combatNodeStrategies.find(c => c.combatNodeId === zoneId);
        if (cns === undefined)
        {
            const newCns = new CombatNodeStrategy({ combatNodeId: zoneId });
            this.combatNodeStrategies.push(newCns);
            return newCns;

        }
        return cns;
    }

    constructor(json: any)
    {
        this.allyCode = json.allyCode;
        if (json.combatNodeStrategies)
        {
            this.combatNodeStrategies = json.combatNodeStrategies.map((cn: any) => new CombatNodeStrategy(cn));
        }
        if (json.platoonedUnits)
        {
            this.platoonedUnits = json.platoonedUnits;
        }
    }

    isUnitUsed(unitId: string, showPlatoons: boolean, showMissionUnit: boolean): boolean
    {
        const combatUses = this.combatNodeStrategies.find(cns => cns.isUnitUsed(unitId)) !== undefined;
        const platoonUses = this.platoonedUnits.includes(unitId);

        return (showMissionUnit === false && combatUses) || (showPlatoons === false && platoonUses);
    }

    setLeaderUnit(combatZoneId: string, unitId: string | undefined)
    {
        if (unitId !== undefined)
        {
            this.removePlatoonedUnit(unitId);
            this.removeCombatUnit(unitId);
        }
        const cs = this.getCombatNodeStrategy(combatZoneId, true);
        cs.leaderUnit = unitId;
    }

    addCombatUnit(combatZoneId: string, unitId: string)
    {
        this.removePlatoonedUnit(unitId);
        this.removeCombatUnit(unitId);
        const cs = this.getCombatNodeStrategy(combatZoneId, true);
        cs.units.push(unitId);
    }

    replaceCombatUnit(combatZoneId: string, unitId: string, index: number)
    {
        this.removePlatoonedUnit(unitId);
        this.removeCombatUnit(unitId);
        const cs = this.getCombatNodeStrategy(combatZoneId, true);
        if (cs.units.length > index)
        {
            cs.units[index] = unitId;
        } else
        {
            cs.units.push(unitId);
        }
    }

    removeCombatUnit(unitId: string)
    {
        this.combatNodeStrategies.forEach(cns =>
        {
            cns.leaderUnit = cns.leaderUnit === unitId ? undefined : cns.leaderUnit;
            cns.units = cns.units.filter(u => u !== unitId);
        });
    }

    removePlatoonedUnit(unitId: string)
    {
        this.platoonedUnits = this.platoonedUnits.filter(pu => pu !== unitId);
    }

    platoonUnit(unitId: string)
    {
        this.removeCombatUnit(unitId);
        this.removePlatoonedUnit(unitId);
        this.platoonedUnits.push(unitId);
    }
}

export class CombatNodeStrategy
{
    combatNodeId: string;
    leaderUnit: string | undefined;
    units: string[] = [];
    expectedWaves: number | undefined;

    constructor(json: any)
    {
        this.combatNodeId = json.combatNodeId;
        this.units = json.units ? json.units : [];
        this.leaderUnit = json.leaderUnit;
        this.expectedWaves = json.expectedWaves;
    }

    isUnitUsed(unitId: string): boolean
    {
        return this.leaderUnit === unitId || this.units.includes(unitId);
    }

    getStrategyDescription(gameData: GameData, tbDef: TerritoryBattleDefinition): string
    {
        const strikeZone = tbDef.strikeZoneDefinition.find(szd => szd.zoneDefinitionData.zoneId === this.combatNodeId)!;
        let zoneDescription = ZoneStrategy.getAlignment(strikeZone.zoneDefinitionData.linkedConflictId) + ": ";
        if (this.leaderUnit)
        {
            zoneDescription = zoneDescription + CombatNodeStrategy.getUnitName(this.leaderUnit, gameData) + '(Lead), ';
        }
        zoneDescription = zoneDescription + this.units.map(u => CombatNodeStrategy.getUnitName(u, gameData)).join(', ');

        if (this.expectedWaves)
        {
            zoneDescription = zoneDescription + ".  Expected waves: " + this.expectedWaves;
        }
        return zoneDescription;
    }

    static getUnitName(unitId: string, gameData: GameData): string
    {
        return gameData.units!.find(u => u.baseId === unitId)!.name;
    }

    isEmpty(): boolean
    {
        return this.leaderUnit === undefined && this.units.length === 0;
    }

    getUnitList(gameData: GameData): UnitData[]
    {
        const retVal: UnitData[] = [];

        if (this.leaderUnit)
        {
            retVal.push(UnitData.unitByBaseId(this.leaderUnit, gameData.units!));
        }
        this.units.forEach(u =>
        {
            retVal.push(UnitData.unitByBaseId(u, gameData.units!));
        });

        return retVal;
    }

    getExpectedPoints(szd: StrikeZoneDefinition): number
    {
        let retVal = 0;

        if (this.expectedWaves !== undefined && this.expectedWaves > 0 && szd.reward !== null)
        {
            let currentWave = 0;
            let remainingWaves = this.expectedWaves;
            szd.reward?.row.filter(r => r.getGpReward() > 0).forEach(r =>
            {
                const rewardForWave = r.getGpReward() - retVal;

                if (remainingWaves >= currentWave)
                {
                    if (remainingWaves >= currentWave + 1)
                    {
                        // add full wave
                        retVal = retVal + rewardForWave;
                    } else
                    {
                        // add partial wave
                        retVal = retVal + (rewardForWave * (remainingWaves - currentWave));
                    }
                }
                currentWave = currentWave + 1;
            });
        }

        return retVal;
    }
}

export class TextLookup
{
    textMap: Map<string, string> = new Map();

    getValue(key: string): string
    {
        if (this.textMap.has(key))
        {
            return this.textMap.get(key)!;
        }
        return key;
    }
}

export interface TbGuildInfo
{
    guildGalacticPower: number;
    guildName: string;
    name: string;
    dbId: number;
    id: string;
}

export interface TbGuildPlayer
{
    allyCode: number;
    galacticPower: number;
    characterGP: number;
    shipGP: number;
    discordId: number;
    discordTag: string;
    name: string;
    roster: RosterUnit[];
}


export class TbGuildData
{
    guild: TbGuildInfo;
    players: TbGuildPlayer[];

    constructor(json: IGuildData)
    {
        this.guild = {
            guildGalacticPower: json.guild.guildGalacticPower,
            guildName: json.guild.guildName,
            name: json.guild.name,
            dbId: json.guild.dbId,
            id: json.guild.id
        };

        this.players = json.players ? json.players.map(p =>
        {
            return {
                allyCode: p.allyCode,
                galacticPower: p.galacticPower,
                characterGP: p.characterGP,
                shipGP: p.shipGP,
                discordId: p.discordId,
                discordTag: p.discordTag,
                name: p.name,
                roster: p.roster ? p.roster.map(r =>
                {
                    return {
                        baseId: r.baseId,
                        gear: {
                            level: r.gear.level
                        },
                        stars: r.stars,
                        relicLevel: r.relicLevel,
                        omiCount: r.omiCount,
                        zetaCount: r.zetaCount,
                        zetaLead: r.zetaLead,
                        level: r.level,
                        ultimate: r.ultimate
                    }
                }) : []
            }
        }) : [];
    }
}

export class TbZoneRoundInstance
{
    tbzs: TerritoryBattleZoneStatus;
    constructor(tbzs: TerritoryBattleZoneStatus)
    {
        this.tbzs = tbzs;
    }
}


export class TbRoundInstance
{
    tbData: TerritoryBattleData;
    zoneRoundInstanceMap: Map<string, TbZoneRoundInstance> = new Map();

    totalGpEarned: number = 0;
    totalCombatGpEarned: number = 0;


    totalGpDeployed: number = 0;

    totalGpEarnedInAllZones: number = 0;
    totalPlatoonedGpEarned: number = 0;

    totalDeployableGp: number = 0;
    remainingDeployableGp: number = 0;

    // this will get you the % missions attempted
    missionAttempts: number = 0;
    totalPossibleMissionAttempts: number = 0;

    // this will get your the mission success rate
    totalPossibleAttemptedMissionPoints: number = 0;

    totalPossibleMissionPoints: number = 0;

    // this will help you get how successful you need to be
    totalRemainingMissionPoints: Map<string, number> = new Map();
    totalMissionPointsMap: Map<string, number> = new Map();
    totalAchievableMap: Map<string, number> = new Map(); // total amount that could have been earned w. 100% success
    scorePerZone: Map<string, number> = new Map();

    missionPointsEarnedZone: Map<string, number> = new Map();

    constructor(tbData: TerritoryBattleData, tbDef: TerritoryBattleDefinition)
    {
        this.tbData = tbData;
        tbData.conflictZoneStatus.forEach(czs =>
        {
            this.zoneRoundInstanceMap.set(czs.status.zoneId, new TbZoneRoundInstance(czs));
        });

        if (tbData.currentRound !== null)
        {
            this.totalGpEarned = tbData.getLeaderBoardTotalValue("summary_round_" + tbData.currentRound);
            this.totalGpDeployed = tbData.getLeaderBoardTotalValue("power_round_" + tbData.currentRound);
            this.totalCombatGpEarned = this.totalGpEarned - this.totalGpDeployed;

            tbData.getOpenZones().forEach(openZone =>
            {
                this.totalGpEarnedInAllZones = this.totalGpEarnedInAllZones + openZone.status.score;
                this.scorePerZone.set(openZone.status.zoneId, openZone.status.score);
            });
            this.extractTotalPlatoonGpEarned(tbData, tbDef);
        }

        this.extractDeployments(tbData);
        this.calculateMissions(tbData, tbDef, tbData.players.length);
    }

    getScoreByZone(zoneId: string): number
    {
        if (this.scorePerZone.has(zoneId))
        {
            return this.scorePerZone.get(zoneId)!;
        }
        return 0;
    }

    getRequiredDeploymentZone(zoneId: string, starCount: number, tbDef: TerritoryBattleDefinition): number
    {
        const remainingGpNeeded = this.getRemainingGp(zoneId, starCount, tbDef);
        const remainingMissionPoints = this.totalRemainingMissionPoints.get(zoneId)!;
        return remainingMissionPoints > remainingGpNeeded ? 0 : remainingGpNeeded - remainingMissionPoints;
    }

    getRemainingGp(zoneId: string, starCount: number, tbDef: TerritoryBattleDefinition)
    {
        let retVal = 0;
        if (starCount !== 0)
        {
            const zoneDef = tbDef.getZoneGameDefinition(zoneId);
            const zoneStatus = this.tbData.conflictZoneStatus.find(czs => czs.status.zoneId === zoneId);
            if (zoneStatus !== undefined)
            {
                retVal = retVal + (zoneDef.getGpNeededForStar(starCount) - zoneStatus.status.score);
            }
        }
        return retVal < 0 ? 0 : retVal;
    }

    getTotalMissionPointsZone(zoneId: string)
    {
        let retVal = 0;

        if (this.totalMissionPointsMap.has(zoneId))
        {
            retVal = this.totalMissionPointsMap.get(zoneId)!;
        }

        return retVal;
    }

    getTotalMissionPointsRemainingZone(zoneId: string)
    {
        let retVal = 0;

        if (this.totalRemainingMissionPoints.has(zoneId))
        {
            retVal = this.totalRemainingMissionPoints.get(zoneId)!;
        }

        return retVal;
    }

    getMissionPointsEarnedZone(zoneId: string): number
    {
        let retVal = 0;

        if (this.missionPointsEarnedZone.has(zoneId))
        {
            retVal = this.missionPointsEarnedZone.get(zoneId)!;
        }
        return retVal;
    }

    getTotalAchieveablePointsZone(zoneId: string)
    {
        let retVal = 0;

        if (this.totalAchievableMap.has(zoneId))
        {
            retVal = this.totalAchievableMap.get(zoneId)!;
        }

        return retVal;
    }

    private extractTotalPlatoonGpEarned(tbData: TerritoryBattleData, tbDef: TerritoryBattleDefinition)
    {
        tbData.getOpenZones().forEach(openZone =>
        {
            const reconZone = tbDef.getReconZone(openZone.status.zoneId);
            if (reconZone)
            {
                const reconZoneStatus = tbData.getReconZoneStatus(reconZone.zoneDefinition.zoneId);
                const filledPlatoons = reconZoneStatus.platoon.filter(p => p.isPlatoonFilled()).map(p => p.id);
                reconZone.platoonDefinition.filter(pd => filledPlatoons.includes(pd.id)).forEach(pd =>
                {
                    this.totalPlatoonedGpEarned = this.totalPlatoonedGpEarned + pd.reward.value;
                });
            }
        });
    }

    private extractDeployments(tbData: TerritoryBattleData)
    {
        const accountedPlayer: number[] = [];
        tbData.players.forEach(p =>
        {
            if (accountedPlayer.includes(p.allyCode) === false)
            {
                // sometimes HS data has duplicates
                accountedPlayer.push(p.allyCode);
                this.totalDeployableGp = this.totalDeployableGp + p.shipGP + p.characterGP;
            }
        });

        this.remainingDeployableGp = this.totalDeployableGp - this.totalGpDeployed;
    }

    calculateMissions(tbData: TerritoryBattleData, tbDef: TerritoryBattleDefinition, playerCount: number)
    {
        this.totalRemainingMissionPoints = new Map();
        tbData.getOpenMissionZones(tbDef).forEach(cz =>
        {
            let totalRemainingMissionPoints = 0;
            let totalPossibleMissionPointsZone = 0;
            let totalAchievable = 0;

            let strikeZoneDefinitions: StrikeZoneDefinition[] = tbDef.getStrikeZones(cz.status.zoneId);

            let pointsEarnedInZone = 0;

            strikeZoneDefinitions.forEach(szd =>
            {

                this.totalPossibleMissionAttempts = this.totalPossibleMissionAttempts + playerCount;
                let missionPointsPossible = szd.getTotalPossibleMissionPoints();
                this.totalPossibleMissionPoints = this.totalPossibleMissionPoints +
                    (missionPointsPossible * playerCount);

                totalPossibleMissionPointsZone = totalPossibleMissionPointsZone +
                    (missionPointsPossible * playerCount);

                let tbzs = tbData.getStrikeZoneStatus(szd.zoneDefinitionData.zoneId);
                pointsEarnedInZone = pointsEarnedInZone + tbzs.status.score;

                if (tbzs.playersParticipated !== null)
                {
                    this.missionAttempts = this.missionAttempts + tbzs.playersParticipated;

                    this.totalPossibleAttemptedMissionPoints = this.totalPossibleAttemptedMissionPoints +
                        (missionPointsPossible * tbzs.playersParticipated);

                    totalAchievable = totalAchievable + (missionPointsPossible * tbzs.playersParticipated);

                    let remainingPossibleParticipants = playerCount - tbzs.playersParticipated;

                    totalRemainingMissionPoints = totalRemainingMissionPoints + (missionPointsPossible * remainingPossibleParticipants);
                }
            });

            this.missionPointsEarnedZone.set(cz.status.zoneId, pointsEarnedInZone);

            this.totalRemainingMissionPoints.set(cz.status.zoneId, totalRemainingMissionPoints);
            this.totalMissionPointsMap.set(cz.status.zoneId, totalPossibleMissionPointsZone);
            this.totalAchievableMap.set(cz.status.zoneId, totalAchievable);
        });
    }

    getCompletedZones(): string[]
    {
        return this.tbData.conflictZoneStatus.filter(czs => czs.status.isComplete()).map(czs => czs.status.zoneId);
    }

    getStarredZones(tbBattleDefintion: TerritoryBattleDefinition): string[]
    {
        return this.tbData.conflictZoneStatus.filter(czs => czs.getStarCompletedCount(tbBattleDefintion) > 0).map(czs => czs.status.zoneId);
    }
}


export class TbPlayerRoundInstance
{
    playerGp: number = 0;

    territoryPoints: number = 0;
    deployedGp: number = 0;
    combatMissionAttempts: number = 0;
    specialMissionAttempts: number = 0;

    // we may not know this because combat mission waves is not stored per round
    combatMissionWavesCompleted: number | undefined;

    get deployementRemaining(): number
    {
        return this.deployedGp > this.playerGp ? 0 : (this.playerGp - this.deployedGp);
    }

    get deployed(): boolean
    {
        return (this.deployedGp / this.playerGp) > .85;
    }

    get combatMissionPoints(): number
    {
        return this.territoryPoints - this.deployedGp;
    }

}

export class TbPlayerInstance
{
    allyCode: number;
    name: string;

    totalTerritorPointsContributed: number = 0;
    platoonMissionUnitsAssigned: number = 0;
    combatMissionWavesCompleted: number = 0;
    rougeActions: number = 0;

    rounds: Map<number, TbPlayerRoundInstance> = new Map();

    constructor(player: TerritoryBattlePlayer, tbDataList: TerritoryBattleData[])
    {
        this.name = player.name;
        this.allyCode = player.allyCode;

        if (tbDataList.length > 0)
        {
            // we assume its sorted by round
            const oldestTbData = tbDataList[tbDataList.length - 1];

            if (oldestTbData.currentRound !== null)
            {
                this.extractLeaderboardData(oldestTbData);

                // now do rounds specific data
                for (var currentRound = 1; currentRound <= oldestTbData.currentRound; currentRound++)
                {
                    this.extractRoundData(currentRound, oldestTbData, tbDataList);
                }
            }
        }
    }
    getPlayerRoundGp(tbDataList: TerritoryBattleData[], round: number): number
    {
        let retVal = 0;

        tbDataList.forEach(tbData =>
        {
            const player = tbData.players.find(p => p.allyCode === this.allyCode);
            if (player !== undefined && (retVal === 0 || tbData.currentRound === round))
            {
                retVal = player.characterGP + player.shipGP;
            }
        });

        return retVal;
    }

    private extractRoundData(currentRound: number, oldestTbData: TerritoryBattleData, tbDataList: TerritoryBattleData[])
    {
        const roundInstance = new TbPlayerRoundInstance();
        this.rounds.set(currentRound, roundInstance);

        roundInstance.playerGp = this.getPlayerRoundGp(tbDataList, currentRound);

        roundInstance.territoryPoints = oldestTbData.getLeaderBoardValue("summary_round_" + currentRound, this.allyCode);
        roundInstance.deployedGp = oldestTbData.getLeaderBoardValue("power_round_" + currentRound, this.allyCode);
        roundInstance.combatMissionAttempts = oldestTbData.getLeaderBoardValue("strike_attempt_round_" + currentRound, this.allyCode);
        roundInstance.specialMissionAttempts = oldestTbData.getLeaderBoardValue("covert_attempt_round_" + currentRound, this.allyCode);


        // do derived round data because the leaderboard doesnt track but we like too
        const tbRoundData = tbDataList.find(tb => tb.currentRound === currentRound);

        const tbPreviousRound = currentRound > 1 ? tbDataList.find(tb => tb.currentRound === (currentRound - 1)) : undefined;

        if (tbRoundData)
        {
            if (currentRound === 1)
            {
                roundInstance.combatMissionWavesCompleted = tbRoundData.getLeaderBoardValue("strike_encounter", this.allyCode);
            } else if (tbPreviousRound)
            {
                roundInstance.combatMissionWavesCompleted = tbRoundData.getLeaderBoardValue("strike_encounter", this.allyCode) -
                    tbPreviousRound.getLeaderBoardValue("strike_encounter", this.allyCode);
            }
        }
    }

    private extractLeaderboardData(oldestTbData: TerritoryBattleData)
    {
        this.totalTerritorPointsContributed = oldestTbData.getLeaderBoardValue("summary", this.allyCode);
        this.platoonMissionUnitsAssigned = oldestTbData.getLeaderBoardValue("unit_donated", this.allyCode);
        this.combatMissionWavesCompleted = oldestTbData.getLeaderBoardValue("strike_encounter", this.allyCode);
        this.rougeActions = oldestTbData.getLeaderBoardValue("disobey", this.allyCode);
    }
}



export class TbMissionResultStats
{
    missionsAttempted: number = 0;
    score: number = 0;
    maxPossibleMissionPoints: number = 0;

    maxPossibleMissionPointsAll: number = 0;

    static fromZone(zone: TerritoryBattleZoneStatus, previousRound: TerritoryBattleZoneStatus | undefined,
        tbDef: TerritoryBattleDefinition, guildCount: number): TbMissionResultStats
    {
        const retVal = new TbMissionResultStats();

        const playersParticipated = zone.playersParticipated || 0;
        const sz = tbDef.getStrikeZone(zone.status.zoneId)!;

        retVal.missionsAttempted = playersParticipated;
        retVal.score = zone.status.score - (previousRound?.status.score || 0);
        retVal.maxPossibleMissionPoints = sz.getTotalPossibleMissionPoints() * playersParticipated;

        retVal.maxPossibleMissionPointsAll = sz.getTotalPossibleMissionPoints() * guildCount;

        return retVal;
    }

    addResults(results: TbMissionResultStats)
    {
        this.missionsAttempted = this.missionsAttempted + results.missionsAttempted;
        this.score = this.score + results.score;
        this.maxPossibleMissionPoints = this.maxPossibleMissionPoints + results.maxPossibleMissionPoints;
        this.maxPossibleMissionPointsAll += results.maxPossibleMissionPoints;
    }
}


export class TbMissionResults
{
    strikeZoneId: string;

    totals: TbMissionResultStats = new TbMissionResultStats();

    rounds: Map<number, TbMissionResultStats> = new Map();

    constructor(strikeZoneId: string)
    {
        this.strikeZoneId = strikeZoneId;
    }

    addRound(round: number, strikeZone: TerritoryBattleZoneStatus, previousRound: TerritoryBattleZoneStatus | undefined,
        tbDef: TerritoryBattleDefinition, guildCount: number)
    {
        const stats = TbMissionResultStats.fromZone(strikeZone, previousRound, tbDef, guildCount);
        this.rounds.set(round, stats);
        this.totals.addResults(stats);
    }
}

export class GuildTbData
{
    platoonabilityCounts: Map<string, Map<string, number[]>> = new Map();

    private _tbDef: TerritoryBattleDefinition | undefined;
    private _guildData: TbGuildData | undefined;
    private _gameData: GameData | undefined;

    public set tbDef(value: TerritoryBattleDefinition | undefined)
    {
        this._tbDef = value;
        this.calculate();
    }
    public set guildData(value: TbGuildData)
    {
        this._guildData = value;
        this.calculate();
    }
    public set gameData(value: GameData)
    {
        this._gameData = value;
        this.calculate();
    }

    calculate()
    {
        this.platoonabilityCounts = new Map();

        if (this._gameData && this._guildData && this._tbDef)
        {
            this._tbDef.conflictZoneDefinition.forEach(czd =>
            {
                const zoneId = czd.zoneDefinition.zoneId;
                const czdMap: Map<string, number[]> = new Map();
                this.platoonabilityCounts.set(zoneId, czdMap);

                const reconZone = this._tbDef!.getReconZone(zoneId);

                if (reconZone)
                {
                    reconZone.platoonDefinition.forEach(pd =>
                    {
                        pd.members.forEach(pm =>
                        {
                            const combatType = UnitData.unitByBaseId(pm.baseId, this._gameData!.units!).combatType;
                            const pr = new PlatoonRequirement(pm.baseId, GuildTbData.getPlatoonRelicLevel(zoneId));

                            if (czdMap.has(pr.keyValue()) === false)
                            {
                                czdMap.set(pr.keyValue(), this._guildData!.players.filter(p =>
                                    p.roster.find(ru => ru.baseId === pr.unitBaseId &&
                                        ((combatType === 1 && ru.relicLevel >= pr.relic) || (combatType === 2 && ru.stars === 7))) !== undefined).map(
                                            p => p.allyCode
                                        ));
                            }
                        });
                    })
                }
            });
        }
    }


    static getPlatoonRelicLevel(zoneId: string)
    {
        const phase = ZoneStrategy.getPhase(zoneId);

        switch (phase)
        {
            case 1:
                return 5;
            case 2:
                return 6;
            case 3:
                return 7;
            case 4:
                return 8;
            case 5:
                return 9;
            case 6:
                return 9;
        }
        return 0;
    }
}

export class TbZoneInstance
{
    zoneId: string;
    missionResults: Map<string, TbMissionResults> = new Map();

    roundsActive: number[] = [];

    constructor(zoneId: string, tbDataList: TerritoryBattleData[], tbDef: TerritoryBattleDefinition)
    {
        this.zoneId = zoneId;

        let maxPlayers = 0;
        tbDataList.forEach(tbData =>
        {
            const playersInZone = [...new Set(tbData.players.map(p => p.allyCode))].length;
            maxPlayers = maxPlayers > playersInZone ? maxPlayers : playersInZone;
        });
        const maxPlayersConst = maxPlayers > 50 ? 50 : maxPlayers;

        for (var x = 0; x < tbDataList.length; x++)
        {
            const tbData = tbDataList[x];
            const zoneIsOpen = tbData.getOpenZones().map(oz => oz.status.zoneId).includes(zoneId);
            if (zoneIsOpen)
            {
                this.roundsActive.push(tbData.currentRound!);

                const previousRound = tbDataList.find(tb => tb.currentRound === (tbData.currentRound! - 1));

                const strikeZones = tbDef.getStrikeZones(zoneId);
                strikeZones.forEach(sz =>
                {
                    const strikeZoneId = sz.zoneDefinitionData.zoneId;
                    const zoneStatus = tbData.getStrikeZoneStatus(strikeZoneId);

                    const previousRoundStrikeZone = previousRound === undefined ? undefined : previousRound.getStrikeZoneStatus(strikeZoneId);

                    if (this.missionResults.has(strikeZoneId) === false)
                    {
                        this.missionResults.set(strikeZoneId, new TbMissionResults(strikeZoneId));
                    }
                    this.missionResults.get(strikeZoneId)!.addRound(tbData.currentRound!, zoneStatus, previousRoundStrikeZone, tbDef, maxPlayersConst)
                });
            }
        }
    }

    getTotalsForRound(round: number): TbMissionResultStats
    {
        const retVal = new TbMissionResultStats();

        Array.from(this.missionResults.keys()).forEach(zoneId =>
        {
            const results = this.missionResults.get(zoneId)!;
            const roundStats = round === -1 ? results.totals : results.rounds.get(round);
            if (roundStats)
            {
                retVal.addResults(roundStats);
            }
        })
        return retVal;
    }
}

export class TbInstanceData
{
    tbDataMap: Map<number, TbRoundInstance> | undefined;
    tbPlayers: Map<number, TbPlayerInstance> | undefined;

    zoneMap: Map<string, TbZoneInstance> | undefined;

    activeRound: number | undefined;

    // we assume its sorted by round
    oldestTbData?: TerritoryBattleData;


    setTbData(tbDataList: TerritoryBattleData[] | undefined, tbGuildData: TbGuildData, tbDef: TerritoryBattleDefinition)
    {
        this.tbPlayers = undefined;
        this.tbDataMap = undefined;
        this.zoneMap = undefined;
        this.oldestTbData = undefined;

        if (tbDataList !== undefined && tbDataList.length > 0)
        {
            this.oldestTbData = tbDataList[tbDataList.length - 1];

            this.calculateRoundStats(tbDataList, tbDef);
            this.tbPlayers = new Map();
            this.zoneMap = new Map();

            tbDataList.forEach(tbData =>
            {
                this.calculatePlayerStats(tbData, tbDataList);
                this.calculateZoneStats(tbData, tbDataList, tbDef);
            });
        }
    }

    getParticipatedPhases(tbDef: TerritoryBattleDefinition, alignments: number[]): number[]
    {
        const retVal: number[] = [];

        if (this.zoneMap)
        {
            Array.from(this.zoneMap.keys()).filter(conflictZoneId =>
                alignments.includes(ZoneStrategy.getArea(conflictZoneId))).forEach(conflictZoneId =>
                {
                    const zoneDef = tbDef.getZoneGameDefinition(conflictZoneId);
                    const phase = zoneDef.getPhase();
                    if (phase !== undefined && retVal.includes(phase) === false)
                    {
                        retVal.push(phase);
                    }
                })
        }

        return retVal;
    }

    private calculateRoundStats(tbDataList: TerritoryBattleData[], tbDef: TerritoryBattleDefinition)
    {
        this.tbDataMap = new Map();
        tbDataList.filter(tbData => tbData.currentRound !== null).forEach(tbData =>
        {
            this.activeRound = this.activeRound === undefined || this.activeRound < tbData.currentRound! ? tbData.currentRound! : this.activeRound;
            this.tbDataMap!.set(tbData.currentRound!, new TbRoundInstance(tbData, tbDef));
        });
    }

    private calculatePlayerStats(tbData: TerritoryBattleData, tbDataList: TerritoryBattleData[])
    {
        tbData.players.forEach(player =>
        {
            if (this.tbPlayers!.has(player.allyCode) === false)
            {
                this.tbPlayers!.set(player.allyCode, new TbPlayerInstance(player, tbDataList));
            }
        });
    }

    private calculateZoneStats(tbData: TerritoryBattleData, tbDataList: TerritoryBattleData[], tbDef: TerritoryBattleDefinition)
    {
        tbData.getOpenZones().forEach(openZone =>
        {
            const zoneId = openZone.status.zoneId;
            if (this.zoneMap!.has(zoneId) === false)
            {
                this.zoneMap!.set(zoneId, new TbZoneInstance(zoneId, tbDataList, tbDef));
            }
        });
    }

    usingTbData(): boolean
    {
        return this.tbDataMap !== undefined;
    }

    getRound(round: number): TbRoundInstance | undefined
    {
        return this.usingTbData() ? this.tbDataMap?.get(round) : undefined;
    }

    setConfirmedCompletedZones(round: number, rs: RoundState, tbBattleDefintion: TerritoryBattleDefinition)
    {
        if (round === 1)
        {
            rs.completedZones = [];
        } else
        {
            const previousRoundData = this.getRound(round);
            if (previousRoundData !== undefined)
            {
                rs.completedZones = previousRoundData.getCompletedZones();
            } else if (this.activeRound !== undefined && round > this.activeRound)
            {
                const mostRecentRound = this.getRound(this.activeRound);

                const completedZones = mostRecentRound === undefined ? [] : mostRecentRound.getStarredZones(tbBattleDefintion);

                rs.completedZones = rs.completedZones.concat(completedZones);
                rs.completedZones = rs.completedZones.filter((item, pos) => rs.completedZones.indexOf(item) === pos)
            }
        }
    }
}